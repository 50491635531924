import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
// import { Link } from "react-router-dom";
import "./table.css";
import { format } from "fecha";

export default function MyTable({ tabledata, columns }) {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    if (tabledata != undefined) {
      setData(tabledata);
    }
  }, [tabledata]);
  const normalizedSearchText = searchText
    .replace(/\s+/g, "")
    .toLocaleLowerCase();
  const filteredData = data.filter(
    (item) =>
      item?.status?.toLowerCase().includes(normalizedSearchText) ||
      (item?.createdAt &&
        format(new Date(item.createdAt), "dd MMM yyyy, hh:mm a")
          .toLowerCase()
          .includes(normalizedSearchText)) ||
      (item?.deliveryDate &&
        format(new Date(item.deliveryDate), "dd MMM yyyy, hh:mm a")
          .toLowerCase()
          .includes(normalizedSearchText)) ||
      (item?.updatedAt &&
        format(new Date(item.updatedAt), "dd MMM yyyy, hh:mm a")
          .toLowerCase()
          .includes(normalizedSearchText)) ||
      (item?.valid_from &&
        format(new Date(item.valid_from), "dd MMM yyyy, hh:mm a")
          .toLowerCase()
          .includes(normalizedSearchText)) ||
      (item?.valid_till &&
        format(new Date(item.valid_till), "dd MMM yyyy, hh:mm a")
          .toLowerCase()
          .includes(normalizedSearchText)) ||
      item?.provider?.toLowerCase().includes(normalizedSearchText) ||
      item?.cardOpened
        ?.toString()
        ?.toLowerCase()
        .includes(normalizedSearchText) ||
      item?._id?.toLowerCase().includes(normalizedSearchText) ||
      item?.planName?.toLowerCase().includes(normalizedSearchText) ||
      item?.heading?.toLowerCase().includes(normalizedSearchText) ||
      item?.label?.toLowerCase().includes(normalizedSearchText) ||
      item?.title?.toLowerCase().includes(normalizedSearchText) ||
      item?.minTitle?.toLowerCase().includes(normalizedSearchText) ||
      item?.planType?.toLowerCase().includes(normalizedSearchText) ||
      item?.price?.toString()?.toLowerCase().includes(normalizedSearchText) ||
      item?.monthlyPrice
        ?.toString()
        ?.toLowerCase()
        .includes(normalizedSearchText) ||
      item?.annualPrice
        ?.toString()
        ?.toLowerCase()
        .includes(normalizedSearchText) ||
      item?.email?.toLowerCase().includes(normalizedSearchText) ||
      item?.message?.toLowerCase().includes(normalizedSearchText) ||
      item?.firstName?.toLowerCase().includes(normalizedSearchText) ||
      item?.lastName?.toLowerCase().includes(normalizedSearchText) ||
      item?.billingType?.toLowerCase().includes(normalizedSearchText) ||
      item?.recipient?.name?.toLowerCase().includes(normalizedSearchText) ||
      item?.planDetails?.planName
        .toLowerCase()
        .includes(normalizedSearchText) ||
      item?.cancelsubscription?.toLowerCase().includes(normalizedSearchText) ||
      item?.userId?.firstName?.toLowerCase().includes(normalizedSearchText) ||
      item?.userId?.email?.toLowerCase().includes(normalizedSearchText) ||
      item?.userId?.lastName?.toLowerCase().includes(normalizedSearchText) ||
      item?.user?.firstName?.toLowerCase().includes(normalizedSearchText) ||
      item?.user?.lastName?.toLowerCase().includes(normalizedSearchText) ||
      item?.user?.email?.toLowerCase().includes(normalizedSearchText) ||
      item?.user?.code?.toLowerCase().includes(normalizedSearchText) ||
      item?.bundleId?.name?.toLowerCase().includes(normalizedSearchText) ||
      item?.userId?.wallet
        ?.toString()
        .toLowerCase()
        .includes(normalizedSearchText) ||
      item?.payment?.amount
        ?.toString()
        .toLowerCase()
        .includes(normalizedSearchText) ||
      item?.paymentId?.amount
        ?.toString()
        .toLowerCase()
        .includes(normalizedSearchText) ||
      item?.paymentId
        ?.toString()
        .toLowerCase()
        .includes(normalizedSearchText) ||
      item?.name?.toLowerCase().includes(normalizedSearchText) ||
      item?.description?.toLowerCase().includes(normalizedSearchText)
  );
  const customStyles = {
    rows: {
      style: {
        wordWrap: "break-word",
        whiteSpace: "normal", // Ensures text wraps properly
      },
    },
    headCells: {
      style: {
        wordWrap: "break-word",
        whiteSpace: "normal",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        wordWrap: "break-word",
        whiteSpace: "normal",
      },
    },
  };
  return (
    <div className="datatable">
      <input
        type="text"
        placeholder="Search by name or Description"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{
          margin: "10px",
          padding: "8px",
          width: "300px",
          border: "1px solid #ccc",
        }}
        className="form-control border-transparent form-focus-none"
      />
      {data?.length > 0 ? (
        <DataTable
          // title="User Data"
          columns={columns}
          customStyles={customStyles}
          data={filteredData}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          //   subHeader
          highlightOnHover
          striped
        />
      ) : (
        ""
      )}
    </div>
  );
}
