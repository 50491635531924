import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { deletedProductApi } from "../Action/DeleteAction";
import { editProductApi } from "../Action/PutAction";
import { getProductList } from "../Action/GetAction";
import { addProductApi } from "../Action/PostAction";
import { dateFormatConverter, matrixify1 } from "../Logic/Logic";
import { useToasts } from "react-toast-notifications";
import { toastComp } from "../Sections/Toast";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Modal } from "react-responsive-modal";
import { format } from "fecha";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-overlay-loader/styles.css";
import "react-responsive-modal/styles.css";
import MyTable from "./datatable.js";

const ProductList = (props) => {
  const { addToast } = useToasts();
  const [toggleSearchBar, setToggleSearchBar] = useState(true);
  const [productList, setProductList] = useState([]);
  const [allData, setallData] = useState([]);

  const [countProduct, setCountProduct] = useState(0);
  const [paginationIndex, setPageIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(false);
  const [editId, setEditId] = useState(false);
  const [name, setName] = useState("");
  const [addons, setAddons] = useState("");
  const [price, setPrice] = useState("");
  const countTotalProduct = async (array) => {
    var count = 0;
    for (let li = 0; li < array.length; li++) {
      count = count + array[li].length;
    }
    await setCountProduct(count);
  };
  const getListData = async (isNote, message) => {
    await getProductList("product-type/producttypelist").then(
      async (response) => {
        if (response.status) {
          setLoader(false);
          onCloseModal();
          console.log(response?.data);
          setallData(response?.data);
          const arrayList = await matrixify1(response.data, 10);
          await setProductList([...productList, arrayList]);
          await countTotalProduct(arrayList);
          // await setParentCategory(response.data.categories);
          if (isNote) {
            toastComp(addToast, "success", message);
          }
        }
      }
    );
  };
  const onCloseModal = (props) => {
    setOpen(false);
    setEditData(false);
    setEditId(false);
    setName("");
    setAddons("");
    setPrice("");
  };

  const handlePageClick = async (e) => {
    const selectedPage = e.selected;
    setPageIndex(selectedPage);
  };
  const handleSerach = async (value) => {
    setLoader(true);
    await getProductList("product-type/producttypelist?name=" + value).then(
      async (response) => {
        if (response.status) {
          if (response.data.length > 0) {
            setLoader(false);
            const arrayList = await matrixify1(response.data, 5);
            await setProductList([arrayList]);
            await countTotalProduct(arrayList);
          }
        }
      }
    );
  };
  const handleEditProduct = async (id) => {
    setLoader(true);
    if (name != "") {
      setOpen(false);
      var data = {};
      data = {
        name: name,
        price: price,
        isAddOn: addons,
      };
      const params = {
        productTypeId: id,
      };
      await editProductApi(data, params).then(async (response) => {
        if (response.status) {
          await getListData(true, "Successfully edited Product!");
        } else {
          setLoader(false);
          toastComp(addToast, "error", response.message);
        }
      });
    } else {
      setLoader(false);
      toastComp(addToast, "error", "Please fill the required field!");
    }
  };
  const handleAddProdcut = async () => {
    setLoader(true);
    if (name != "" && price != "" && addons != "") {
      setOpen(false);
      var data = {};
      data = {
        name: name,
        price: price,
        isAddOn: addons,
      };
      await addProductApi(data).then(async (response) => {
        if (response.status) {
          await getListData(true, "Successfully added Product!");
        } else {
          setLoader(false);
          toastComp(addToast, "error", response.message);
        }
      });
    } else {
      setLoader(false);
      toastComp(addToast, "error", "Please fill the required field!");
    }
  };
  const editCategory = (data) => {
    setOpen(true);
    setEditData(true);
    setEditId(data?._id);
    setName(data?.name);
    setAddons(data?.isAddOn);
    setPrice(data?.price);
  };
  const deleteCategory = async (id, index) => {
    setLoader(true);
    await deletedProductApi(id).then((response) => {
      if (response.success || response.status) {
        setLoader(false);
        productList[0]?.[paginationIndex].splice(index, 1);
        toastComp(addToast, "success", "Product deleted Successfully!");
      } else {
        setLoader(false);
        toastComp(addToast, "error", "Product id incorrect!");
      }
    });
  };
  const popup = (id, index) => {
    confirmAlert({
      title: "Confirm Action",
      message: "Are you sure you want to delete this card?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCategory(id, index),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  var divisor = (countProduct / 10) >> 0;
  var remainder = countProduct % 10;
  useEffect(async () => {
    await getListData(false, "");
  }, []);
  const columns = [
    {
      name: "Product Name",
      selector: (row) => row.name,
      cell: (row) => row.name,
      sortable: true,
      width: "250px",
    },
    {
      name: "Price",
      selector: (data) => (data.price ? "USD " + data?.price : "USD " + 0),
      sortable: true,
      width: "340px",
    },
    {
      name: "Add Ons",
      selector: (row) => (row.isAddOn ? "Yes" : "No"),
      sortable: true,
      width: "220px",
    },
    {
      name: "Created At",
      selector: (row) => format(new Date(row?.createdAt), "Do MMM YYYY"),
      sortable: true,
      width: "240px",
    },
    {
      name: "More",
      cell: (data, index) => (
        <>
          <div className="nk-tb-col nk-tb-col-tools">
            <ul className="nk-tb-actions gx-1">
              <li>
                <div className="drodown">
                  <a
                    href="#"
                    className="dropdown-toggle btn btn-icon btn-trigger"
                    data-toggle="dropdown"
                  >
                    <em className="icon ni ni-more-h"></em>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <a onClick={() => editCategory(data)}>
                          <em className="icon ni ni-edit"></em>
                          <span>Edit</span>
                        </a>
                      </li>
                      <li>
                        <a onClick={() => popup(data._id, index)}>
                          <em className="icon ni ni-trash"></em>
                          <span>Delete</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Product List</h3>
                    <div className="nk-block-des text-soft">
                      <p>
                        You have total{" "}
                        {countProduct === 1
                          ? countProduct + " product"
                          : countProduct + " products"}
                        .
                      </p>
                    </div>
                  </div>
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <a
                      href="#"
                      className="btn btn-icon btn-trigger toggle-expand mr-n1"
                      data-target="pageMenu"
                    >
                      <em className="icon ni ni-menu-alt-r"></em>
                    </a>
                    <div
                      className="toggle-expand-content"
                      data-content="pageMenu"
                    >
                      <ul className="nk-block-tools g-3">
                        <li>
                          <a
                            className="btn btn-white btn-outline-light"
                            onClick={(e) => {
                              setOpen(true);
                            }}
                          >
                            <em className="icon ni ni-plus"></em>
                            <span>Add Product</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="nk-block">
                <div className="card card-bordered card-stretch">
                  <div className="card-inner-group">
                    <div className="card-inner position-relative card-tools-toggle">
                      <div
                        className={
                          toggleSearchBar
                            ? "card-search search-wrap active"
                            : "card-search search-wrap active"
                        }
                        data-search="search"
                      >
                        <div className="card-body">
                          <div className="search-content">
                            <input
                              type="text"
                              className="form-control border-transparent form-focus-none"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              onKeyDown={(e) => {
                                e.keyCode === 13 ? handleSerach(search) : "";
                              }}
                              placeholder="Search by product name..."
                              style={{ paddingLeft: "0px" }}
                            />
                            <button
                              onClick={(e) => handleSerach(search)}
                              className="search-submit btn btn-icon"
                            >
                              <em className="icon ni ni-search"></em>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner p-0">
                      <div className="nk-tb-list nk-tb-ulist">
                        <div className="nk-tb-item nk-tb-head">
                          <div
                            className="nk-tb-col nk-tb-col-check"
                            style={{ width: "0px", padding: "10px" }}
                          ></div>
                          <div className="nk-tb-col">
                            <span className="sub-text">Product Name</span>
                          </div>
                          <div className="nk-tb-col tb-col-mb">
                            <span className="sub-text">Price</span>
                          </div>
                          <div className="nk-tb-col tb-col-mb">
                            <span className="sub-text">Add Ons</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">Created At</span>
                          </div>
                          <div className="nk-tb-col nk-tb-col-tools text-right">
                            <span className="sub-text">More</span>
                          </div>
                        </div>
                        {loader ? (
                          <LoadingOverlay className="loaderDiv">
                            <Loader loading={loader} />
                          </LoadingOverlay>
                        ) : null}
                        {productList[0]?.[paginationIndex].length > 0 ? (
                          productList[0]?.[paginationIndex].map(
                            (data, index) => (
                              <div
                                className="nk-tb-item"
                                id={data._id}
                                key={index}
                              >
                                <div
                                  className="nk-tb-col nk-tb-col-check"
                                  style={{ width: "0px", padding: "10px" }}
                                ></div>
                                <div className="nk-tb-col">
                                  <div className="user-info">
                                    <span className="tb-lead">
                                      {data.name}
                                      <span className="dot dot-success d-md-none ml-1"></span>
                                    </span>
                                  </div>
                                </div>
                                <div className="nk-tb-col tb-col-mb">
                                  <span className="tb-lead">
                                    {data.price
                                      ? "USD " + data?.price
                                      : "USD " + 0}
                                  </span>
                                </div>
                                <div className="nk-tb-col tb-col-mb">
                                  <span className="tb-lead">
                                    {data.isAddOn ? "Yes" : "No"}
                                  </span>
                                </div>
                                <div className="nk-tb-col tb-col-md">
                                  <span className="tb-lead">
                                    {format(
                                      new Date(data?.createdAt),
                                      "Do MMM YYYY"
                                    )}
                                  </span>
                                </div>
                                <div className="nk-tb-col nk-tb-col-tools">
                                  <ul className="nk-tb-actions gx-1">
                                    <li>
                                      <div className="drodown">
                                        <a
                                          href="#"
                                          className="dropdown-toggle btn btn-icon btn-trigger"
                                          data-toggle="dropdown"
                                        >
                                          <em className="icon ni ni-more-h"></em>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                          <ul className="link-list-opt no-bdr">
                                            <li>
                                              <a
                                                onClick={() =>
                                                  editCategory(data)
                                                }
                                              >
                                                <em className="icon ni ni-edit"></em>
                                                <span>Edit</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  popup(data._id, index)
                                                }
                                              >
                                                <em className="icon ni ni-trash"></em>
                                                <span>Delete</span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <LoadingOverlay className="loaderDiv">
                            <Loader loading={loader} />
                          </LoadingOverlay>
                        )}
                      </div>
                    </div>
                    <div className="card-inner">
                      <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={remainder >= 1 ? divisor + 1 : divisor}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={10}
                        onPageChange={(e) => handlePageClick(e)}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              <MyTable columns={columns} tabledata={allData} />
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={onCloseModal} closeOnOverlayClick={false}>
        <div className="container-fluid" style={{ width: "100%" }}>
          <div className="row justify-content-center">
            <div className="col-md-12 col-12">
              <h3 style={{ textAlign: "left" }}>
                {editData ? "Edit Product" : "Add Product"}
              </h3>
            </div>
            <div className="col-md-12 col-12" style={{ marginTop: "15px" }}>
              <div className="row justify-content-center">
                <div className="col-lg-12 col-12">
                  <ul className="data-list is-compact">
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Product Name</div>
                        <input
                          type="text"
                          className="data-value ip-field"
                          value={name}
                          placeholder="Product name"
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          required
                        />
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Price</div>
                        <input
                          type="number"
                          className="data-value ip-field"
                          value={price}
                          placeholder="Price in CAD"
                          onChange={(e) => {
                            setPrice(e.target.value);
                          }}
                          required
                        />
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Add Ons</div>
                        <select
                          className="custom-select"
                          value={addons}
                          onChange={(e) => setAddons(e.target.value)}
                          required
                        >
                          <option value="">Select</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="my-3 text-center">
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-md btn-primary"
                        onClick={() => {
                          editData
                            ? handleEditProduct(editId)
                            : handleAddProdcut();
                        }}
                      >
                        {editData ? "Edit Product" : "Add Product"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ProductList;
