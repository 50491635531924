import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { getProductList } from "../Action/GetAction";
import { dateFormatConverter, matrixify1 } from "../Logic/Logic";
import { useToasts } from "react-toast-notifications";
import { toastComp } from "../Sections/Toast";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { confirmAlert } from "react-confirm-alert";
import { format } from "fecha";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-overlay-loader/styles.css";
import MyTable from "./datatable.js";
import styled from "styled-components";

const SupportList = (props) => {
  const { addToast } = useToasts();
  const [toggleSearchBar, setToggleSearchBar] = useState(true);
  const [productList, setProductList] = useState([]);
  const [allData, setallData] = useState([]);
  const [countProduct, setCountProduct] = useState(0);
  const [paginationIndex, setPageIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true);
  const countTotalProduct = async (array) => {
    var count = 0;
    for (let li = 0; li < array.length; li++) {
      count = count + array[li].length;
    }
    await setCountProduct(count);
  };
  const getListData = async (isNote, message) => {
    await getProductList("newsletter/contacts").then(async (response) => {
      console.log(response);
      if (response.success) {
        setLoader(false);
        setallData(response?.data);
        const arrayList = await matrixify1(response.data, 10);
        await setProductList([...productList, arrayList]);
        await countTotalProduct(arrayList);
      }
    });
  };

  const handlePageClick = async (e) => {
    const selectedPage = e.selected;
    setPageIndex(selectedPage);
  };
  const handleSerach = async (value) => {
    setSearch(value);
    setLoader(true);
    await getProductList("product-type/producttypelist?name=" + value).then(
      async (response) => {
        if (response.status) {
          if (response.data.length > 0) {
            setLoader(false);
            const arrayList = await matrixify1(response.data, 10);
            await setProductList([arrayList]);
            await countTotalProduct(arrayList);
          }
        }
      }
    );
  };

  function respondMail(userMail) {
    console.log(userMail);
    // var link = 'mailto:hello@domain.com?subject=Message from '+userMail+'&body='+userMail;
    // window.location.href = link;
  }
  function deleteMail(mailId, index) {
    console.log(mailId);
    // var link = 'mailto:hello@domain.com?subject=Message from '+userMail+'&body='+userMail;
    // window.location.href = link;
  }

  useEffect(async () => {
    await getListData(false, "");
  }, []);
  const columns = [
    {
      name: "Name",
      selector: (row) => row.firstName,
      cell: (row) => row.firstName,
      sortable: true,
      width: "120px",
    },
    {
      name: "Email Id",
      selector: (row) => row.email,
      cell: (row) => row.email,
      sortable: true,
      width: "220px",
    },
    {
      name: "Message",
      selector: (row) => row.message,
      cell: (row) => row.message,
      sortable: true,
    },
    {
      name: "Recieved Date",
      selector: (row) => format(new Date(row?.createdAt), "Do MMM YYYY"),
      sortable: true,
      width: "150px",
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.createdAt);
        const dateB = new Date(rowB.createdAt);
        return dateA - dateB;
      },
    },
    {
      name: "Mail Status",
      selector: (row) => (row.status ? row?.status : "Pending"),
      sortable: true,
      width: "120px",
    },
    {
      name: "More",
      maxWidth: "15px",
      cell: (row, index) => (
        <>
          <div className="nk-tb-col nk-tb-col-tools">
            <ul className="nk-tb-actions gx-1">
              <li>
                <div className="drodown">
                  <a
                    href="#"
                    className="dropdown-toggle btn btn-icon btn-trigger"
                    data-toggle="dropdown"
                  >
                    <em className="icon ni ni-more-h"></em>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <a onClick={() => respondMail(row.email)}>
                          <em className="icon ni ni-mail"></em>
                          <span>Respond Mail</span>
                        </a>
                      </li>
                      <li>
                        <a onClick={() => deleteMail(row._id, index)}>
                          <em className="icon ni ni-trash"></em>
                          <span>Delete</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Support</h3>
                    <div className="nk-block-des text-soft">
                      <p>
                        You have total{" "}
                        {countProduct === 1
                          ? countProduct + " support"
                          : countProduct + " supports"}
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="nk-block">
                <div className="card card-bordered card-stretch">
                  <div className="card-inner-group">
                    <div className="card-inner p-0">
                      <div className="nk-tb-list nk-tb-ulist">
                        <div className="nk-tb-item nk-tb-head">
                          <div
                            className="nk-tb-col nk-tb-col-check"
                            style={{ width: "0px", padding: "10px" }}
                          ></div>
                          <div className="nk-tb-col">
                            <span className="sub-text">Name</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">Email Id</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">Message</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">Recived Date</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">Mail Status</span>
                          </div>
                          <div className="nk-tb-col nk-tb-col-tools text-right">
                            <span className="sub-text">More</span>
                          </div>
                        </div>
                        {loader ? (
                          <LoadingOverlay className="loaderDiv">
                            <Loader loading={loader} />
                          </LoadingOverlay>
                        ) : null}
                        {productList[0]?.[paginationIndex].length > 0 ? (
                          productList[0]?.[paginationIndex].map(
                            (data, index) => (
                              <div
                                className="nk-tb-item"
                                id={data._id}
                                key={index}
                              >
                                <div
                                  className="nk-tb-col nk-tb-col-check"
                                  style={{ width: "0px", padding: "10px" }}
                                ></div>
                                <div className="nk-tb-col">
                                  <div className="user-info">
                                    <span
                                      className="tb-lead"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {data.firstName}
                                    </span>
                                  </div>
                                </div>
                                <div className="nk-tb-col tb-col-md">
                                  <span className="tb-lead">{data.email}</span>
                                </div>
                                <div className="nk-tb-col tb-col-md">
                                  <span
                                    className="tb-lead"
                                    style={{
                                      textAlign: "justify",
                                      width: "250px",
                                    }}
                                  >
                                    {data.message ? data.message : "---"}
                                  </span>
                                </div>
                                <div className="nk-tb-col tb-col-md">
                                  <span className="tb-lead">
                                    {data.createdAt
                                      ? format(
                                          new Date(data?.createdAt),
                                          "Do MMM YYYY, HH:mm A"
                                        )
                                      : "---"}
                                  </span>
                                </div>
                                <div className="nk-tb-col tb-col-md">
                                  <span className="tb-lead">
                                    {data.status ? data.status : "Pending"}
                                  </span>
                                </div>
                                <div className="nk-tb-col nk-tb-col-tools">
                                  <ul className="nk-tb-actions gx-1">
                                    <li>
                                      <div className="drodown">
                                        <a
                                          href="#"
                                          className="dropdown-toggle btn btn-icon btn-trigger"
                                          data-toggle="dropdown"
                                        >
                                          <em className="icon ni ni-more-h"></em>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                          <ul className="link-list-opt no-bdr">
                                            <li>
                                              <a
                                                onClick={() =>
                                                  respondMail(data.email)
                                                }
                                              >
                                                <em className="icon ni ni-mail"></em>
                                                <span>Respond Mail</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  deleteMail(data._id, index)
                                                }
                                              >
                                                <em className="icon ni ni-trash"></em>
                                                <span>Delete</span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <LoadingOverlay className="loaderDiv">
                            <Loader loading={loader} />
                          </LoadingOverlay>
                        )}
                      </div>
                    </div>
                    <div className="card-inner">
                      <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={productList[0]?.length}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={(e) => handlePageClick(e)}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              <MyTable
                columns={columns}
                tabledata={allData}
                //   editCategory={editCategory}
                //   popup={popup}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SupportList;
