import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  getUserOrderSingleDetails,
  getGifhy,
  getSticker,
  getUserDetails,
  getTemplateList,
  getCategoryList,
} from "../Action/GetAction";
import {
  updateUserOrderDetails,
  uploadPhoto,
  putEditCardComponent,
  updatecardsCover
} from "../Action/PutAction";
import { Modal } from "react-responsive-modal";
import ReactPaginate from "react-paginate";
import { getMethod, gifyGetApi, stickerGetApi } from "../Utils/ApiServices";
import { deleteComponent } from "../Action/DeleteAction";
import {
  Base_EditorId,
  BASE_IMAGE_URL,
  deleteMethod,
  WEB_URL,
} from "../Utils/ApiServices";
import { useToasts } from "react-toast-notifications";
import { toastComp } from "../Sections/Toast";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Editor } from "@tinymce/tinymce-react";
import produce from "@vve/immer";
import Parser from "html-react-parser";
import * as htmlToImage from "html-to-image";
import { Container, Row, Col } from "react-bootstrap";
import Url from "url-parse";
import { randomColor, randomFont } from "../Logic/Logic";
import "react-overlay-loader/styles.css";
import { getCookies } from "../Utils/Cookies";
import { addUserCardCom } from "../Action/PostAction";

const AdminEditCard = (props) => {
  const { cardIdQuery, isBack,orderDetails } = props;
  const editorRef = useRef(null);
console.log(orderDetails)
  const history = useHistory();
  const [cardId, setCardId] = useState("");
  const { addToast } = useToasts();
  const [loader, setLoader] = useState(true);
  const [canavsDetails, setChoosedTemplate] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const [returnGIPHY, setGifyData] = useState([]);
  const [returnSticker, setStickerData] = useState([]);
  const [limit, setLimit] = useState(6);
  const [gifhyValue, setGiphyValue] = useState("");
  const [searchHints, setSearchHints] = useState("");
  const [stickersValue, setStickersValue] = useState("");
  const [listener, editListner] = useState(false);
  const [currentNewItem, setCurrentNewItem] = useState(false);
  const [currentEditItem, setCurrentEditItems] = useState(false);
  const [editorValue, setEditorValue] = useState("");
  const [randFont, setRandomFonts] = useState(randomFont());
  const [randColor, setRandomColor] = useState(randomColor());
  const [isHideSection, setHideSection] = useState(false);

  var xCurrentFont = randFont?.toString();
  var xCurrentColor = randColor?.toString();
  const setContentInsideOfEditor = (setValue) => {
    if (editorRef.current != null) {
      editorRef.current.setContent(setValue);
    }
  };
  const getGifyJSON = async (data, limit) => {
    await getGifhy({ search: data, limit: limit }).then((response) => {
      setGifyData(response);
    });
  };
  const getStickerJSON = async (data, limit) => {
    await getSticker({ search: data, limit: limit }).then((response) => {
      setStickerData(response);
    });
  };
  const getUserSingleOrder = async (id, text) => {
    await getUserOrderSingleDetails(id).then((response) => {
      if (response.status) {
        setChoosedTemplate(response?.data);
        setCardId(response?.order[0]?.canvasId);
        setSearchHints(
          response?.data?.category[0]?.name
            ? response?.data?.category[0]?.name?.toLowerCase()
            : "",
          limit
        );
        setTotalPages(response?.data?.middle?.canvasContent.length + 2);
        setLoader(false);
      } else {
        history.push("/user-list");
      }
    });
  };
  const editContainer = async (data) => {
    if (document.getElementById(data?.componentId) != null) {
      document.getElementById(data?.componentId).remove();
      setCurrentEditItems(data);
    }
  };
  const handleEmptyPageClear = async (e, id) => {
    setLoader(true);
    await deleteMethod("component/removepage?canvasId=" + id).then(
      async (response) => {
        if (response.status === true) {
          setLoader(false);
          await getUserSingleOrder(cardIdQuery);
          toastComp(addToast, "success", "Successfully deleted!");
        } else {
          setLoader(false);
          toastComp(addToast, "error", response?.message);
        }
      }
    );
  };
  const handleEmptydownloadcard = async (e, id) => {
    setLoader(true);
    window.location.href = `https://www.greetpool.com/download-card?card=${id}`;
  };
  const saveCurrentElements = async (data, params) => {
    setLoader(true);
    await putEditCardComponent(data, params).then(async (response) => {
      if (response.success) {
        setCurrentEditItems(false);
        editListner(false);
        toastComp(addToast, "success", "Successfully updated!");
        setChoosedTemplate([]);
        setTimeout(async function () {
          await getUserSingleOrder(cardIdQuery);
        }, 5000);
      } else {
        setLoader(false);
        toastComp(addToast, "error", response?.message);
      }
    });
  };
  const updateCardsCover = async (data, params) => {
    setLoader(true);
    await updatecardsCover(data, params).then(async (response) => {
      if (response.success) {
     
   
        toastComp(addToast, "success", "Successfully updated!");

        setTimeout(async function () {
          await getUserSingleOrder(cardIdQuery);
        }, 5000);
      } else {
        setLoader(false);
        toastComp(addToast, "error", response?.message);
      }
    });
  };

  const addComponentInCard = async (data) => {
    setLoader(true);
    await addUserCardCom(data).then(async (response) => {
      console.log(response);
      //setLoader(false)
      setTimeout(async function () {
        await getUserSingleOrder(cardIdQuery);
      }, 5000);
    });
  };
  const deleteEditComponent = async (id) => {
    setLoader(true);
    await deleteComponent(id).then(async (response) => {
      if (response.status || response.success) {
        setCurrentEditItems(false);
        editListner(false);
        toastComp(addToast, "success", "Successfully deleted!");
        setLoader(false);
      } else {
        setLoader(false);
        toastComp(addToast, "error", response?.message);
      }
    });
  };
  const checkNewUrl = (catchUrl) => {
    const f = new Url(catchUrl).host?.split(".").slice(1, 3).join(".");
    return f;
  };
  const deleteAppendComponent = (index) => {
    setCurrentEditItems(false);
    editListner(false);
  };
  useEffect(async () => {
    if (cardIdQuery) {
      await getUserSingleOrder(cardIdQuery);
    } else {
      history.push("/user-list");
    }
    setRandomFonts(randomFont());
    setRandomColor(randomColor());
  }, []);
  const buttonStyle = {
    boxShadow: "rgb(101, 118, 255) 0px 1px 0px 0px inset",
    background:
      "linear-gradient(rgb(101, 118, 255) 5%, rgb(101, 118, 255) 100%) rgb(101, 118, 255)",
    borderRadius: "6px",
    border: "1px solid rgb(101, 118, 255)",
    display: "inline-block",
    cursor: "pointer",
    color: "rgb(255, 255, 255)",
    fontSize: "15px",
    fontWeight: "bold",
    padding: "6px 24px",
    textDecoration: "none",
    textShadow: "rgb(101, 118, 255) 0px 1px 0px",
  };

  const [open, setOpen] = useState(false);
  const [categoryData, setcategoryData] = useState([]);
  const [chooosedTemplateData, setChooosedTemplateData] = useState([]);
  const [chooosedTemplateData1, setChooosedTemplateData1] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 16;
  const [loading, setLoading] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryData1, setCategoryData1] = useState([]); // Store data for selected category
  const [initialData, setInitialData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showSelectedImage, setShowSelectedImage] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [isOpen, setisOpen] = useState(false);
  const fetchData = (page, category = selectedCategory || null, price) => {
    setLoading(true);
   console.log(selectedCategory)
    let apiUrl = `category/templatelist?page=${page}&limit=${itemsPerPage}`;

    if (category) {
      // If a category is selected, fetch data for that category
      apiUrl = `category/getSingleCategory?slug=${category}&page=${page}&limit=${itemsPerPage}`;
    }

    getMethod(apiUrl)
      .then((response) => {
        console.log("Fetched data", response);
        if (category) {
          setCategoryData1(response);
          setLoading(false);
          if (response.data.slug) {
            // Extract the slug from the response
            const categorySlug = response.data.slug;
            // Fetch related images based on the category slug
            fetchImagesByCategory(categorySlug, price);
          }
        } else {
          setInitialData(response);
      
          setChooosedTemplateData(response);
        }
      })
      .catch((error) => {
        // console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  const fetchImagesByCategory = (categorySlug) => {
    setLoading(true);
    let apiUrl;

    //   if (price === 0) {
    //     apiUrl = `category/templatelist?slug=${categorySlug}&page=${page}&limit=${itemsPerPage}&type=free`;
    //     getMethod(apiUrl)
    //       .then((response) => {
    //         // console.log('Fetched images for selected category', response);
    //         setChooosedTemplateData1(response);
    //     //    setChooosedTemplateData1(response); 
    //         setLoading(false);
    //       })
    //       .catch((error) => {
    //         setLoading(false);
    //       });
    //   } else {
        apiUrl = `category/templatelist?slug=${categorySlug}&page=${page}&limit=${itemsPerPage}`;
        getMethod(apiUrl)
          .then((response) => {
            // console.log('Fetched images for selected category', response);
            setChooosedTemplateData1(response);

            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });

    // }
  };

  // useEffect(() => {
  //     fetchData(page);
  // }, [page]);
  const [name, setName] = useState("");
  const [slugcategory, setSlugcategory] = useState("");
  const [frontAlt, setFrontalt] = useState("");
  const [description, setdescription] = useState("");
  const [templateeditid, setTemplateeditid] = useState("");
  const [selectedCategoryid, setSelectedCategoryid] = useState("");
  const handleCategorySelect = (e, selectedSlug, item) => {
    setOpen(!isOpen);
    setShowSelectedImage(false);
    // console.log(item, 'category item')
    const selectedCategory = selectedSlug;
    setSelectedCategory(selectedCategory);
    // setSelectedCategoryprivate(item.name);
    setSelectedCategoryid(item._id);
    setName(item.name);
    setSlugcategory(item.slug);
    // setFrontalt(item.slug);
    // setdescription(item.title);
    // setKeyword(item.title);

    // setSelectedCategoryarray([
    //   {
    //     name: item.name,
    //     value: item._id,
    //   },
    // ]);

    if (selectedCategory) {
      // If a category is selected, fetch data for that category
      //   fetchData(page, selectedCategory);
    } else {
      setChooosedTemplateData(initialData);
    //   setChooosedTemplateData(initialData);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1) {
      setLoading(true);
      setPage(newPage);
      setTimeout(() => {
        setLoading(false);
      }, 5000);
      setShowSelectedImage(false);
      // fetchData(newPage);
      // fetchImagesByCategory(selectedOption, newPage)
    }
  };

  const mapOccasionToCategory = (occasion) => {
    const occasionsplit = occasion.toLowerCase().replace(/\s+/g, "-");
    // console.log(occasionsplit)
    if (occasionsplit.endsWith("-card")) {
      return `${occasionsplit}s`;
    }
    if (occasionsplit === "work-anniversary-card") {
      return "work-anniversary";
    }
    if (occasionsplit === "work-anniversary") {
      return "work-anniversary";
    }
    if (occasionsplit === "congratulations") {
      return "congratulations-cards";
    }
    if (occasionsplit === "funny-farewell-card") {
      return "farewell-cards";
    }
    // console.log(occasionsplit,'split occasion')
    return `${occasionsplit}-cards`;
  };
  useEffect(() => {
    console.log('open',open,props.orderDetails.occassions)
    if (open) {
      // console.log(createdList[selectedItemIndex].occassions, 'category of createdlist')
console.log(selectedCategory)
      if (selectedCategory === undefined) {

        const occasion = orderDetails.occassions;
        console.log('occasion',occasion)

        const modifiedCategory = mapOccasionToCategory(occasion);

        setSelectedCategory(modifiedCategory);
      }

      fetchData(page, selectedCategory);
    }
  }, [open,props,selectedCategory,page]);

  const handleImageSelect = (item) => {
    // console.log(item, 'image selected')
    setSelectedImage(item);
    // console.log(selectedImage, 'seelcted iamges')
    setShowSelectedImage(true);
  };

  const handleMouseLeave = () => {
    setSelectedImage(null);
    setShowSelectedImage(false);
  };

  const handleContinue = () => {
    console.log('clicked',selectedImage)
    if ( selectedImage !== null) {
  


      if (selectedImage.category && selectedImage.category.length > 0) {
        const selectedCategoryId = selectedImage._id;
        const payload = {
          _id: orderDetails._id,
          canvasId: orderDetails.canvasId,
          occassions: selectedImage.category[0].name,
          template: selectedCategoryId,
        };

        updateCardsCover(payload)

        // console.log(payload)
        // dispatch(
        //   updatecardsCover(
        //     payload,
        //     (response) => {
        //       {
        //         if (response.status === true) {
        //           addToast("Card Edit successfully!", {
        //             appearance: "success",
        //             autoDismiss: true,
        //           });

        //           (updatedCreateData[selectedItemIndex].template._id =
        //             selectedCategoryId),
        //             (updatedCreateData[selectedItemIndex].template.name =
        //               selectedImage.name),
        //             (updatedCreateData[selectedItemIndex].template.front =
        //               selectedImage.front),
        //             (updatedCreateData[selectedItemIndex].occassions =
        //               selectedImage.category[0].name);
        //           setCreated(updatedCreateData);

        //           setStatuspaid("");
        //           setPage(1);
        //           setSelectedCategory(null);
        //           setShowSelectedImage(false);
        //         }
        //       }
        //     },
        //     (error) => {
        //       console.log(error);
        //     }
        //   )
        // );
      }

      setOpen(false);
    }
  };
  const categories = categoryData.categories;

  useEffect(() => {
    getCategoryList("category").then((response) => {
      // console.log(response, 'categorydata')
      setcategoryData(response.data);
    });

    window.scrollTo(0, 0);
  }, []);

  const onCloseModal = (props) => {
    setOpen(false);
  };

  const toggleDropdown = () => setisOpen(!isOpen);
  console.log(chooosedTemplateData1);
  return (
    <div className="nk-content">
      <div className="container-fluid pt-3 px-5 flexPadding333">
        <div className="nk-content-body">
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between g-3">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">Edit Card</h3>
              </div>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#"
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                  data-target="pageMenu"
                >
                  <em className="icon ni ni-menu-alt-r"></em>
                </a>
                <div className="toggle-expand-content" data-content="pageMenu">
                  <ul className="nk-block-tools g-3">
                    <li>
                      <a
                        className="btn btn-white btn-outline-light"
                        onClick={(e) => {
                          handleEmptyPageClear(e, cardIdQuery);
                        }}
                      >
                        <em className="icon ni ni-trash"></em>
                        <span>Clean Empty Pages</span>
                      </a>
                    </li>
                  </ul>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <a
                        style={buttonStyle}
                        onClick={(e) => {
                          handleEmptydownloadcard(e, cardIdQuery);
                        }}
                      >
                        <em class="icon ni ni-download"> </em>
                        <span>Download Card</span>
                      </a>
                    </li>
                  </ul>

                  <ul className="nk-block-tools g-3">
                    <li>
                      <a
                        className="btn btn-white btn-outline-light"
                        onClick={(e) => {setOpen(true)
                            // setSelectedItemIndex(index);
                            // setSelectedImage(item);
                            setSelectedCategory(orderDetails.occasion);
                            // setStatuspaid(item.canvasId);
                            setTemplateeditid(orderDetails?.tempalte?.template._id);
                           
                        }}
                      >
                        <em class="icon ni ni-eye"> </em>
                        <span>Edit Card Cover</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-block">
            <div className="row justify-content-center ngBlockmargin">
              <div
                className="clo-lg-12 col-6 text-center"
                style={{ display: "grid", justifyContent: "center" }}
              >
                {loader ? (
                  <LoadingOverlay className="loaderDiv">
                    <Loader loading={loader} />
                  </LoadingOverlay>
                ) : null}
                <div
                  className="row justify-content-center transformSet"
                  style={{
                    width: "450px",
                    height: "630px",
                    padding: "5px",
                    marginLeft: "0px",
                    marginRight: "0px",
                  }}
                >
                  <ul
                    className="overFlowul"
                    style={{
                      width: "100%",
                      height: "630px",
                      backgroundColor: "#fff",
                      display: "flex",
                      margin: "0px",
                      marginLeft: "0px",
                      marginRight: "0px",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                    }}
                  >
                    <li
                      className="pagesContainer"
                      style={{
                        width: "100%",
                        height: "100%",
                        display:
                          currentPage === 0 || currentPage === 1
                            ? "block"
                            : "none",
                        backgroundColor: "#ececec",
                        boxShadow: "lightgrey 0px 0.7rem 0.8rem",
                      }}
                      id={
                        "front:" +
                        canavsDetails?.middle?.canvasDetails?.templateId._id
                      }
                      data-type={"frontCover"}
                    >
                      <img
                        src={
                          BASE_IMAGE_URL +
                          canavsDetails?.middle?.canvasDetails?.templateId
                            ?.front
                        }
                        style={{ width: "100%", height: "100%" }}
                      />
                    </li>
                    {canavsDetails?.middle?.canvasContent?.map(
                      (readEleLists, mindex) => (
                        <li
                          className="pagesContainer"
                          style={{
                            width: "100%",
                            height: "100%",
                            display:
                              currentPage === mindex + 2 ? "block" : "none",
                            backgroundColor: "#ececec",
                            boxShadow: "lightgrey 0px 0.7rem 0.8rem",
                            padding: " 5px 5px 5px 10px",
                          }}
                          id={readEleLists?.pageDetails?._id}
                          data-type={readEleLists?.pageDetails?._id}
                          key={mindex}
                        >
                          {readEleLists.pageContent.map((eleLists, index) => (
                            <div
                              className="elementsPreviousAdded textPreviewSim"
                              data-type={eleLists.type}
                              id={eleLists._id}
                              key={index}
                              style={{
                                border: "solid #cecece 1px",
                                width: parseFloat(eleLists.width),
                                height: "auto",
                                position: "absolute",
                                transform: `translate(${parseFloat(
                                  eleLists.x
                                )}px,${parseFloat(
                                  eleLists.y
                                )}px) rotate(${parseFloat(eleLists.angle)}rad)`,
                                padding: "5px",
                              }}
                            >
                              {/* <img className={eleLists.type === 'text' ? 'setProfile' : 'simple'} src={eleLists.type === 'text' ? eleLists.url : checkNewUrl(eleLists?.url) === 'giphy.com' ? eleLists?.url : BASE_IMAGE_URL + eleLists.url} style={{ width: '100%', height: '100%' }} /> */}
                              {eleLists?.type === "text" ? (
                                (eleLists?.url).substr(0, 4) === "data" ? (
                                  <img
                                    alt={eleLists?.type}
                                    className={
                                      eleLists.type === "text"
                                        ? "setProfile"
                                        : "simple"
                                    }
                                    src={
                                      eleLists.type == "text"
                                        ? eleLists.url
                                        : eleLists.url.substr(0, 4) == "http"
                                        ? eleLists.url
                                        : BASE_IMAGE_URL + eleLists.url
                                    }
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                ) : (
                                  Parser(eleLists?.url)
                                )
                              ) : (
                                <img
                                  alt={eleLists?.type}
                                  className={
                                    eleLists.type === "text"
                                      ? "setProfile"
                                      : "simple"
                                  }
                                  src={
                                    eleLists.type == "text"
                                      ? eleLists.url
                                      : eleLists.url.substr(0, 4) == "http"
                                      ? eleLists.url
                                      : BASE_IMAGE_URL + eleLists.url
                                  }
                                  style={{ width: "100%", height: "100%" }}
                                />
                              )}
                              <div
                                style={{
                                  position: "absolute",
                                  width: "20px",
                                  height: "20px",
                                  top: 0,
                                  right: 0,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (listener) {
                                    toastComp(
                                      addToast,
                                      "error",
                                      "Please save the current editable component!"
                                    );
                                  } else {
                                    editListner(true);
                                    if (eleLists?.type === "text") {
                                      setEditorValue(
                                        eleLists?.content
                                          ? eleLists?.content
                                          : ""
                                      );
                                      setContentInsideOfEditor(
                                        eleLists?.content
                                          ? eleLists?.content
                                          : ""
                                      );
                                    }
                                    editContainer({
                                      type: eleLists.type,
                                      pageId: readEleLists.pageDetails._id,
                                      canvasId:
                                        canavsDetails?.middle?.canvasDetails
                                          ?._id,
                                      componentId: eleLists._id,
                                      style: {
                                        width: eleLists.width,
                                        height: eleLists.height,
                                        x: eleLists.x,
                                        y: eleLists.y,
                                        angle: eleLists.angle,
                                        url: eleLists.url,
                                        content: eleLists.content
                                          ? eleLists.content
                                          : "",
                                        signerName: eleLists?.signerName
                                          ? eleLists?.signerName
                                          : false,
                                      },
                                    });
                                  }
                                }}
                              >
                                <em
                                  className="icon ni ni-pen"
                                  style={{
                                    color: "red",
                                    widht: "20px",
                                    height: "20px",
                                    fontSize: "18px",
                                  }}
                                ></em>
                              </div>
                            </div>
                          ))}
                        </li>
                      )
                    )}
                    <li
                      className="pagesContainer"
                      style={{
                        width: "100%",
                        height: "100%",
                        display: currentPage === totalPages ? "block" : "none",
                        backgroundColor: "#ececec",
                        boxShadow: "lightgrey 0px 0.7rem 0.8rem",
                      }}
                      id={
                        "back:" +
                        canavsDetails?.middle?.canvasDetails?.templateId._id
                      }
                      data-type={"backCover"}
                    >
                      <img
                        src={
                          BASE_IMAGE_URL +
                          canavsDetails?.middle?.canvasDetails?.templateId?.back
                        }
                        style={{ width: "100%", height: "100%" }}
                      />
                    </li>
                    {!listener && currentEditItem === false ? null : listener &&
                      currentEditItem?.type === "text" ? (
                      editorValue != "" ? (
                        <div
                          className="resize-drag draggable resizeContentArea components"
                          id={currentEditItem?.componentId}
                          data-type={currentEditItem?.type}
                          width={
                            currentEditItem?.style
                              ? currentEditItem?.style.width
                              : "100px"
                          }
                          style={{
                            position: "absolute",
                            width: currentEditItem?.style
                              ? currentEditItem?.style?.width
                              : "100px",
                            height: "auto",
                            transform: `translate(${
                              currentEditItem?.style
                                ? currentEditItem?.style?.x
                                : 0
                            }px, ${
                              currentEditItem?.style
                                ? currentEditItem?.style?.y
                                : 0
                            }px) rotate(${
                              currentEditItem?.style
                                ? currentEditItem?.style?.angle
                                : 0
                            }rad)`,
                            border: "solid 2px #ACDEFF",
                            display:
                              currentPage === 1 ||
                              currentPage ===
                                parseInt(
                                  canavsDetails?.middle?.canvasContent?.length +
                                    2
                                )
                                ? "none"
                                : "block",
                          }}
                          data-x={
                            currentEditItem?.style
                              ? currentEditItem?.style?.x
                              : 0
                          }
                          data-y={
                            currentEditItem?.style
                              ? currentEditItem?.style?.y
                              : 0
                          }
                          data-angle={
                            currentEditItem?.style
                              ? currentEditItem?.style?.angle
                              : 0
                          }
                        >
                          <div className="bottomC resize-handle"></div>
                          <div className="bottomG resize-handle"></div>
                          <div className="bottomD resize-handle"></div>
                          <div className="bottomH rotation-handle">
                            <div className="rotateCircle"></div>
                          </div>
                          <div
                            className="drag-handle"
                            style={{
                              width: "90%",
                              height: "100%",
                              position: "absolute",
                              transform: "translate(0%,0%)",
                            }}
                          ></div>
                          <div className="bottomE resize-handle"></div>
                          <div className="bottomI resize-handle"></div>
                          <div className="bottomF resize-handle"></div>
                          <div className="bottomJ resize-handle"></div>
                          <div
                            classname="menuFunction"
                            style={{
                              width: "20px",
                              height: "50px",
                              position: "absolute",
                              top: 0,
                              right: "-25px",
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/checked.png"
                              }
                              style={{
                                border: "solid #727272 1px",
                                cursor: "pointer",
                                padding: 1,
                                widht: "20px",
                                height: "20px",
                              }}
                              onClick={async (e) => {
                                var target =
                                  e.target.parentElement.parentElement;
                                setLoader(true);
                                // await htmlToImage.toPng(document.querySelector('.textSendContent section'))
                                //     .then(async (dataUrl) => {
                                if (
                                  document.querySelector(
                                    ".textSendContent section"
                                  ).children[0]
                                ) {
                                  await saveCurrentElements(
                                    {
                                      x: target.getAttribute("data-x"),
                                      y: target.getAttribute("data-y"),
                                      width: target["offsetWidth"],
                                      height: target["offsetHeight"],
                                      type: currentEditItem?.type,
                                      editUrl: false,
                                      angle: target.getAttribute("data-angle"),
                                      pageId: document
                                        .getElementsByClassName(
                                          "pagesContainer"
                                        )
                                        [currentPage - 1].getAttribute("id"),
                                      canvasId: currentEditItem?.canvasId,
                                      content: document.querySelector(
                                        ".textSendContent section"
                                      ).innerHTML,
                                      url: document.querySelector(
                                        ".textSendContent section"
                                      ).innerHTML,
                                    },
                                    currentEditItem?.componentId
                                  );
                                  setEditorValue("");
                                  setContentInsideOfEditor("");
                                } else {
                                  setLoader(false);
                                  toastComp(
                                    addToast,
                                    "error",
                                    "Something Is Wrong!"
                                  );
                                }
                                // });
                              }}
                            />
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/cancel.png"
                              }
                              style={{
                                border: "solid #727272 1px",
                                cursor: "pointer",
                                padding: 1,
                                widht: "20px",
                                height: "20px",
                              }}
                              onClick={async (e) => {
                                await deleteEditComponent(
                                  currentEditItem?.componentId
                                );
                                setEditorValue("");
                                setContentInsideOfEditor("");
                              }}
                              title="Delete Component!"
                            />
                          </div>
                          {
                            <div
                              className="textSendContent"
                              disabled
                              style={{
                                width: "100%",
                                overflow: "hidden",
                                height: "auto",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                                wordWrap: "break-word",
                              }}
                              id="editableNode"
                            >
                              <section
                                style={{
                                  width: "100%",
                                  height: "fit-content",
                                  padding: "3px",
                                }}
                              >
                                {Parser(
                                  "<div style=width:auto;>" +
                                    editorValue +
                                    "</div>"
                                )}
                              </section>
                            </div>
                            // Parser(editorValue)
                            // <img className="imageBorder" src={currentEditItem?.type === 'text' ? currentEditItem?.style?.url : checkNewUrl(currentEditItem?.style?.url) === 'giphy.com' ? currentEditItem?.style?.url : BASE_IMAGE_URL + currentEditItem?.style?.url} style={{ width: '100%', height: '100%' }} />
                          }
                        </div>
                      ) : null
                    ) : (
                      <div
                        className="resize-drag draggable resizeContentArea components"
                        id={
                          currentEditItem?.componentId
                            ? currentEditItem?.componentId
                            : "gifhy"
                        }
                        data-type={currentEditItem?.type}
                        width={
                          currentEditItem?.style
                            ? currentEditItem?.style.width
                            : "100px"
                        }
                        style={{
                          position: "absolute",
                          width: currentEditItem?.style
                            ? currentEditItem?.style?.width
                            : "100px",
                          height: currentEditItem?.style
                            ? currentEditItem?.style.height
                            : "100px",
                          transform: `translate(${
                            currentEditItem?.style
                              ? currentEditItem?.style?.x
                              : 0
                          }px, ${
                            currentEditItem?.style
                              ? currentEditItem?.style?.y
                              : 0
                          }px) rotate(${
                            currentEditItem?.style
                              ? currentEditItem?.style?.angle
                              : 0
                          }rad)`,
                          border: "solid 2px #ACDEFF",
                          display:
                            currentPage === 1 ||
                            currentPage ===
                              parseInt(
                                canavsDetails?.middle?.canvasContent?.length + 2
                              )
                              ? "none"
                              : "block",
                        }}
                        data-x={
                          currentEditItem?.style ? currentEditItem?.style?.x : 0
                        }
                        data-y={
                          currentEditItem?.style ? currentEditItem?.style?.y : 0
                        }
                        data-angle={
                          currentEditItem?.style
                            ? currentEditItem?.style?.angle
                            : 0
                        }
                      >
                        <div className="bottomC resize-handle"></div>
                        <div className="bottomG resize-handle"></div>
                        <div className="bottomD resize-handle"></div>
                        <div className="bottomH rotation-handle">
                          <div className="rotateCircle"></div>
                        </div>
                        <div
                          className="drag-handle"
                          style={{
                            width: "90%",
                            height: "90%",
                            position: "absolute",
                            transform: "translate(5%,5%)",
                          }}
                        ></div>
                        <div className="bottomE resize-handle"></div>
                        <div className="bottomI resize-handle"></div>
                        <div className="bottomF resize-handle"></div>
                        <div className="bottomJ resize-handle"></div>
                        <div
                          classname="menuFunction"
                          style={{
                            width: "20px",
                            height: "50px",
                            position: "absolute",
                            top: 0,
                            right: "-25px",
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "assets/images/checked.png"
                            }
                            style={{
                              border: "solid #727272 1px",
                              cursor: "pointer",
                              padding: 1,
                              widht: "20px",
                              height: "20px",
                            }}
                            onClick={async (e) => {
                              if (currentEditItem?.componentId) {
                                var target =
                                  e.target.parentElement.parentElement;
                                await saveCurrentElements(
                                  {
                                    x: target.getAttribute("data-x"),
                                    y: target.getAttribute("data-y"),
                                    width: target["offsetWidth"],
                                    height: target["offsetHeight"],
                                    type: currentEditItem?.type,
                                    editUrl: currentEditItem?.edit
                                      ? true
                                      : false,
                                    angle: target.getAttribute("data-angle"),
                                    pageId: document
                                      .getElementsByClassName("pagesContainer")
                                      [currentPage - 1].getAttribute("id"),
                                    canvasId: currentEditItem?.canvasId,
                                    url: currentEditItem?.edit
                                      ? currentEditItem?.style?.url
                                      : currentEditItem?.style?.url,
                                  },
                                  currentEditItem?.componentId
                                );
                              } else {
                                // var target = e.target.parentElement.parentElement;
                                // if (currentEditItem?.type === 'giphy') {
                                //     await addComponentInCard({
                                //         giphy: {
                                //             pos_x: target.getAttribute('data-x').toString(),
                                //             pos_y: target.getAttribute('data-y').toString(),
                                //             width: target['offsetWidth'],
                                //             height: target['offsetHeight'],
                                //             pageId: document.getElementsByClassName('pagesContainer')[(currentPage - 1)].getAttribute('id'),
                                //             canvasId: currentEditItem?.canvasId,
                                //             pageNo: (currentPage - 2).toString(),
                                //             angle: target.getAttribute('data-angle'),
                                //             signerName: getCookies('_adm_details') ? JSON.parse(getCookies('_adm_details'))?.name : '',
                                //             url: currentEditItem?.edit ? true : false,
                                //             uniqueId: getCookies('_adm_details') ? JSON.parse(getCookies('_adm_details'))?.id : '',
                                //             url: currentEditItem?.edit ? currentEditItem?.style?.url : currentEditItem?.style?.url
                                //         }
                                //     });
                                // }
                                // if (currentEditItem?.type === 'icon') {
                                //     await addComponentInCard({
                                //         icon: {
                                //             pos_x: target.getAttribute('data-x').toString(),
                                //             pos_y: target.getAttribute('data-y').toString(),
                                //             width: target['offsetWidth'],
                                //             height: target['offsetHeight'],
                                //             pageId: document.getElementsByClassName('pagesContainer')[(currentPage - 1)].getAttribute('id'),
                                //             canvasId: currentEditItem?.canvasId,
                                //             pageNo: (currentPage - 2).toString(),
                                //             angle: target.getAttribute('data-angle'),
                                //             signerName: getCookies('_adm_details') ? JSON.parse(getCookies('_adm_details'))?.name : '',
                                //             url: currentEditItem?.edit ? true : false,
                                //             uniqueId: getCookies('_adm_details') ? JSON.parse(getCookies('_adm_details'))?.id : '',
                                //             url: currentEditItem?.edit ? currentEditItem?.style?.url : currentEditItem?.style?.url
                                //         }
                                //     });
                                // }
                                // if (currentEditItem?.type === 'photo') {
                                //     await addComponentInCard({
                                //         photo: {
                                //             pos_x: target.getAttribute('data-x').toString(),
                                //             pos_y: target.getAttribute('data-y').toString(),
                                //             width: target['offsetWidth'],
                                //             height: target['offsetHeight'],
                                //             pageId: document.getElementsByClassName('pagesContainer')[(currentPage - 1)].getAttribute('id'),
                                //             canvasId: currentEditItem?.canvasId,
                                //             pageNo: (currentPage - 2).toString(),
                                //             angle: target.getAttribute('data-angle'),
                                //             signerName: getCookies('_adm_details') ? JSON.parse(getCookies('_adm_details'))?.name : '',
                                //             url: currentEditItem?.edit ? true : false,
                                //             uniqueId: getCookies('_adm_details') ? JSON.parse(getCookies('_adm_details'))?.id : '',
                                //             url: currentEditItem?.edit ? currentEditItem?.style?.url : currentEditItem?.style?.url
                                //         }
                                //     });
                                // }
                              }
                            }}
                          />
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "assets/images/cancel.png"
                            }
                            style={{
                              border: "solid #727272 1px",
                              cursor: "pointer",
                              padding: 1,
                              widht: "20px",
                              height: "20px",
                            }}
                            onClick={async (e) => {
                              {
                                if (currentEditItem?.componentId) {
                                  await deleteEditComponent(
                                    currentEditItem?.componentId
                                  );
                                } else {
                                  deleteAppendComponent();
                                }
                              }
                            }}
                            title="Delete Component!"
                          />
                        </div>
                        {
                          <img
                            className="imageBorder"
                            src={
                              currentEditItem?.type === "text"
                                ? C
                                : checkNewUrl(currentEditItem?.style?.url) ===
                                  "giphy.com"
                                ? currentEditItem?.style?.url
                                : BASE_IMAGE_URL + currentEditItem?.style?.url
                            }
                            style={{ width: "100%", height: "100%" }}
                          />
                        }
                      </div>
                    )}
                  </ul>
                </div>
                <div
                  className="row justify-content-center"
                  style={{ margin: "15px", marginTop: "35px" }}
                >
                  <div className="col-md-4 col-4 text-center">
                    <button
                      onClick={(e) => {
                        if (currentPage > 1) {
                          setCurrentPage(currentPage - 1);
                        }
                      }}
                      style={{
                        border: "solid #81dbcd 2px",
                        width: "45px",
                        height: "45px",
                        outline: "none",
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        color: "#81dbcd",
                        fontSize: "16px",
                        fontWeight: "900",
                      }}
                    >
                      &#x27F5;
                    </button>
                  </div>
                  <div
                    className="col-md-4 col-4 text-center"
                    style={{
                      border: "solid #fff 1px",
                      borderRadius: "25px",
                      backgroundColor: "#fff",
                      boxShadow: "lightgrey 0px 0.7rem 0.8rem",
                    }}
                  >
                    <span
                      style={{
                        width: "100%",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        fontSize: "18px",
                        color: "#81dbcd",
                        fontWeight: "600",
                        lineHeight: "41px",
                      }}
                    >
                      <strong style={{ color: "#000000" }}>
                        {currentPage}
                      </strong>{" "}
                      - {totalPages}
                    </span>
                  </div>
                  <div className="col-md-4 col-4 text-center">
                    <button
                      onClick={(e) => {
                        if (currentPage < totalPages) {
                          setCurrentPage(currentPage + 1);
                        }
                      }}
                      style={{
                        border: "solid #81dbcd 2px",
                        width: "45px",
                        height: "45px",
                        outline: "none",
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        color: "#81dbcd",
                        fontSize: "16px",
                        fontWeight: "900",
                      }}
                    >
                      &#x27F6;
                    </button>
                  </div>
                </div>
              </div>
              <div className="clo-lg-12 col-6">
                <div className="row justify-content-center">
                  <div
                    className="col-md-12 col-12"
                    style={{ paddingBottom: "25px" }}
                  >
                    <div className="row justify-content-center">
                      <div className="col-md-12 col-12">
                        <label
                          className="s2-h5 text-left"
                          style={{
                            fontSize: "16px",
                            color: "#00000",
                            fontWeight: "600",
                          }}
                        >
                          Your Message
                        </label>
                      </div>
                    </div>
                    <div
                      className="horizTab"
                      style={{
                        border: "solid #81dbcd 2px",
                        backgroundColor: "#fff",
                        borderRadius: "25px",
                        margin: "0px",
                        padding: "15px",
                      }}
                    >
                      <ul className="nav nav-tabs tabContainer" role="tablist">
                        <li className="nav-item mbItemFix">
                          <a
                            className="nav-link navTabs active show"
                            id="message-tab"
                            data-toggle="tab"
                            href="#messageTab"
                            role="tab"
                            aria-controls="message-tab"
                            aria-selected="false"
                          >
                            Add Text
                          </a>
                        </li>
                        <li className="nav-item mbItemFix">
                          <a
                            className="nav-link navTabs"
                            id="gif-tab"
                            data-toggle="tab"
                            href="#gifTab"
                            role="tab"
                            aria-controls="gif-tab"
                            aria-selected="false"
                            onClick={async () => {
                              await getGifyJSON(searchHints, 6);
                              setLimit(6);
                            }}
                          >
                            Add a GIF
                          </a>
                        </li>
                        <li className="nav-item mbItemFix">
                          <a
                            className="nav-link navTabs"
                            id="sticker-tab"
                            data-toggle="tab"
                            href="#stickerTab"
                            role="tab"
                            aria-controls="sticker-tab"
                            aria-selected="false"
                            onClick={async () => {
                              await getStickerJSON(searchHints, 6);
                              setLimit(6);
                            }}
                          >
                            Add a Sticker
                          </a>
                        </li>
                        <li className="nav-item mbItemFix">
                          <a
                            className="nav-link navTabs"
                            id="image-tab"
                            data-toggle="tab"
                            href="#imageTab"
                            role="tab"
                            aria-controls="image-tab"
                            aria-selected="false"
                          >
                            Add an Images
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          className="subView tab-pane fade show active"
                          id="messageTab"
                          role="tabpanel"
                          aria-labelledby="message-tab"
                          style={{ height: "300px" }}
                        >
                          <Editor
                            tinymceScriptSrc="https://cdn.greetpool.com/selftinymce/tinymce/tinymce.min.js"
                            className="editorStyle"
                            outputFormat="text"
                            apiKey={Base_EditorId}
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            init={{
                              width: "100%",
                              height: 300,
                              skin: "naked",
                              icons: "thin",
                              menubar: false,
                              selector: "textarea",
                              deprecation_warnings: false,
                              resize: "both",
                              branding: false,
                              draggable_modal: false,
                              statusbar: false,
                              theme: "silver",

                              toolbar_mode: "floating",
                              toolbar_location: "bottom",
                              placeholder: "Start typing your message here",
                              content_css: "assets/css/editorFonts.css",
                              content_style: `body{letter-spacing:normal;font-size:16pt;line-height:1.1;font-family:${xCurrentFont};color:${xCurrentColor}} .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before{color:${xCurrentColor}} body{overflow-wrap: anywhere;word-break: break-all;padding:0px; position:relative} .mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before{left:15px} body p, body span, body h1, body h2,body h3, body h4, body h5{margin:0px;width:100%}`,
                              content_style: `body{letter-spacing:normal;font-size:16pt;line-height:1.1;font-family:${xCurrentFont};color:${xCurrentColor}} .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before{color:${xCurrentColor}} body{overflow-wrap: anywhere;word-break: break-all;padding:0px; position:relative} .mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before{left:15px} body p, body span, body h1, body h2,body h3, body h4, body h5{margin:0px;width:100%}`,
                              plugins: [
                                "advlist autolink lists link image charmap emoticons",
                                "searchreplace visualblocks code fullscreen",
                                "textcolor",
                                "insertdatetime media table paste code help wordcount",
                              ],
                              toolbar:
                                "fontselect fontsizeselect fontDecoration alignment forecolor emoticons",
                              toolbar_groups: {
                                fontDecoration: {
                                  icon: "bold",
                                  tooltip: "Font Decoration",
                                  items: "bold italic underline",
                                },
                                alignment: {
                                  icon: "align-left",
                                  tooltip: "Alignment | Line Height",
                                  items:
                                    "alignleft aligncenter alignright alignjustify lineheight",
                                },
                              },
                              textcolor_map: [
                                "#000000",
                                "black",
                                "#FF0000",
                                "red",
                                "#008000",
                                "green",
                                "#0000FF",
                                "blue",
                                "#FC0FC0",
                                "pink",
                                "#B200ED",
                                "violet",
                                "#FF0090",
                                "magenta",
                                "#2E8B57",
                                "sea",
                                "#FCA3B7",
                                "flamingo",
                                "#2B1700",
                                "chocolate",
                                "#A020F0",
                                "purple",
                                "#FC6600",
                                "orange",
                                "#FF6347",
                                "tomato",
                              ],
                              color_map: [
                                "#000000",
                                "black",
                                "#FF0000",
                                "red",
                                "#008000",
                                "green",
                                "#0000FF",
                                "blue",
                                "#FC0FC0",
                                "pink",
                                "#B200ED",
                                "violet",
                                "#FF0090",
                                "magenta",
                                "#2E8B57",
                                "sea",
                                "#FCA3B7",
                                "flamingo",
                                "#2B1700",
                                "chocolate",
                                "#A020F0",
                                "purple",
                                "#FC6600",
                                "orange",
                                "#FF6347",
                                "tomato",
                              ],
                              custom_colors: true,
                              fontsize_formats:
                                "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
                              lineheight_formats: "1.1 1.2 1.3 1.4 1.5 2",
                              font_formats:
                                "Gloria Hallelujah=Gloria Hallelujah;Homemade Apple=Homemade Apple;Knewave=Knewave;Leckerli One=Leckerli One;Righteous=Righteous;Vampiro One=Vampiro One;Sarina=Sarina;Vibur=Vibur;Nanum Pen Script=Nanum Pen Script;Patrick Hand=Patrick Hand;Calligraffitti=Calligraffitti;Cookie=Cookie;Freehand=Freehand;Handlee=Handlee;Pacifico=Pacifico;Satisfy=Satisfy;Indie Flower=Indie Flower;Gochi Hand=Gochi Hand;Permanent Marker=Permanent Marker;Arial Black=arial black,avant garde;Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago;Verdana=verdana,geneva;",
                            }}
                            onEditorChange={(evt, editor) => {
                              if (
                                listener &&
                                currentEditItem?.type === "text"
                              ) {
                                setEditorValue(editorRef.current.getContent());
                              }
                            }}
                          />
                        </div>
                        <div
                          className="subView tab-pane fade"
                          id="gifTab"
                          role="tabpanel"
                          aria-labelledby="gif-tab"
                          style={{ height: "100%" }}
                        >
                          <div
                            className="searchBar"
                            style={{
                              marginTop: "5px",
                              paddingTop: "0px",
                              paddingBottom: "5px",
                              marginBottom: "0px",
                            }}
                          >
                            <label className="labelSearch">
                              <input
                                type="search"
                                placeholder="Search Gif"
                                value={gifhyValue}
                                onChange={(e) => setGiphyValue(e.target.value)}
                                className="input_Search"
                                onKeyUp={async (e) =>
                                  await getGifyJSON(e.target.value, limit)
                                }
                              />
                            </label>
                          </div>
                          <div
                            className="gifyContainer"
                            style={{
                              border: "solid #c6c6c6 1px",
                              overflowX: "hidden",
                              overflowY: "scroll",
                              height: "250px",
                              backgroundColor: "#fff",
                            }}
                          >
                            <div
                              className="listGifhy row"
                              style={{
                                width: "100%",
                                marginLeft: "0px",
                                height: "100%",
                              }}
                            >
                              {returnGIPHY?.length > 0 ? (
                                returnGIPHY?.map((lists, index) => (
                                  <Col
                                    xs
                                    id={"typeColumnid" + index}
                                    className="col-md-4 col-4 columnContainer cursor_add"
                                    key={lists.id}
                                    style={{
                                      marginBottom: "5px",
                                      height: "150px",
                                      padding: "5px",
                                    }}
                                  >
                                    <a
                                      onClick={async () => {
                                        if (
                                          listener &&
                                          currentEditItem?.type === "giphy"
                                        ) {
                                          var returnData = {};
                                          var styleData = {};
                                          returnData = currentEditItem;
                                          styleData = await Object.assign(
                                            returnData.style,
                                            {
                                              url: lists.images.original.webp
                                                ? lists.images.original.webp
                                                : lists.images.original.url,
                                            }
                                          );
                                          returnData = await Object.assign(
                                            returnData,
                                            { edit: true, style: styleData }
                                          );
                                          setLoader(true);
                                          setTimeout(async function () {
                                            setLoader(false);
                                            await setCurrentEditItems(
                                              returnData
                                            );
                                          }, 5000);
                                        } else {
                                          toastComp(
                                            addToast,
                                            "error",
                                            "Please select the any giphy type editable component!"
                                          );
                                          // editListner(true)
                                          // if (currentPage === 1 || currentPage === 0 || currentPage >= totalPages) {
                                          //     setCurrentPage(currentPage + 1)
                                          // }
                                          // setCurrentEditItems({
                                          //     type: 'giphy',
                                          //     pageId: document?.getElementsByClassName('pagesContainer')[(currentPage === 1 || currentPage === 0 || currentPage >= totalPages ? parseInt(currentPage + 1) : currentPage)]?.getAttribute('id'),
                                          //     canvasId: query.get('card'),
                                          //     componentId: false,
                                          //     style: {
                                          //         width: '100px',
                                          //         height: '100px',
                                          //         x: '100px',
                                          //         y: '100px',
                                          //         angle: 0,
                                          //         url: lists.images.original.webp ? lists.images.original.webp : lists.images.original.url,
                                          //         content: '',
                                          //         signerName: false
                                          //     }
                                          // })
                                        }
                                      }}
                                    >
                                      <img
                                        alt={"giphy " + lists?.type}
                                        src={lists.images.preview_gif.url}
                                        className="img-setFluid"
                                        loading="eager"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />
                                    </a>
                                  </Col>
                                ))
                              ) : (
                                <div
                                  className="setReponse"
                                  style={{ transform: "translate(10px, 0px)" }}
                                >
                                  <p className="cls4 errorResponse">
                                    Nothing Found!
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="buttonBorder">
                            <button
                              type="button"
                              className="text"
                              onClick={async () => {
                                await getGifyJSON(
                                  gifhyValue ? gifhyValue : searchHints,
                                  limit + 6
                                );
                                setLimit(limit + 6);
                              }}
                            >
                              Load More
                            </button>
                          </div>
                        </div>
                        <div
                          className="subView tab-pane fade"
                          id="stickerTab"
                          role="tabpanel"
                          aria-labelledby="sticker-tab"
                          style={{ height: "100%" }}
                        >
                          <div
                            className="searchBar"
                            style={{
                              marginTop: "5px",
                              paddingTop: "0px",
                              paddingBottom: "5px",
                              marginBottom: "0px",
                            }}
                          >
                            <label className="labelSearch">
                              <input
                                type="search"
                                placeholder="Search Sticker"
                                value={stickersValue}
                                onChange={(e) =>
                                  setStickersValue(e.target.value)
                                }
                                className="input_Search"
                                onKeyUp={async (e) => {
                                  await getStickerJSON(e.target.value, 6);
                                }}
                              />
                            </label>
                          </div>
                          <div
                            className="gifyContainer"
                            style={{
                              border: "solid #c6c6c6 1px",
                              overflowX: "hidden",
                              overflowY: "scroll",
                              height: "250px",
                              backgroundColor: "#fff",
                            }}
                          >
                            <div
                              className="listGifhy row"
                              style={{
                                width: "100%",
                                marginLeft: "0px",
                                height: "100%",
                              }}
                            >
                              {returnSticker.length > 0 ? (
                                returnSticker?.map((lists, index) => (
                                  <Col
                                    xs
                                    id={"typeColumnid" + index}
                                    className="col-md-4 col-4 columnContainer cursor_add"
                                    key={lists.id}
                                    style={{
                                      marginBottom: "5px",
                                      height: "150px",
                                      padding: "5px",
                                    }}
                                  >
                                    <a
                                      onClick={async () => {
                                        if (
                                          listener &&
                                          currentEditItem?.type === "icon"
                                        ) {
                                          var returnData = {};
                                          var styleData = {};
                                          returnData = currentEditItem;
                                          styleData = await Object.assign(
                                            returnData.style,
                                            {
                                              url: lists.images.original.webp
                                                ? lists.images.original.webp
                                                : lists.images.original.url,
                                            }
                                          );
                                          returnData = await Object.assign(
                                            returnData,
                                            { edit: true, style: styleData }
                                          );
                                          setLoader(true);
                                          setTimeout(async function () {
                                            setLoader(false);
                                            await setCurrentEditItems(
                                              returnData
                                            );
                                          }, 5000);
                                        } else {
                                          // editListner(true)
                                          // if (currentPage === 1 || currentPage === 0 || currentPage >= totalPages) {
                                          //     setCurrentPage(currentPage + 1)
                                          // }
                                          // setCurrentEditItems({
                                          //     type: 'icon',
                                          //     pageId: document?.getElementsByClassName('pagesContainer')[(currentPage === 1 || currentPage === 0 || currentPage >= totalPages ? parseInt(currentPage + 1) : currentPage)]?.getAttribute('id'),
                                          //     canvasId: query.get('card'),
                                          //     componentId: false,
                                          //     style: {
                                          //         width: '100px',
                                          //         height: '100px',
                                          //         x: '100px',
                                          //         y: '100px',
                                          //         angle: 0,
                                          //         url: lists.images.original.webp ? lists.images.original.webp : lists.images.original.url,
                                          //         content: '',
                                          //         signerName: false
                                          //     }
                                          // })
                                          toastComp(
                                            addToast,
                                            "error",
                                            "Please select the any sticker type editable component!"
                                          );
                                        }
                                      }}
                                    >
                                      <img
                                        alt="giphy icon"
                                        src={lists.images.preview_gif.url}
                                        className="img-setFluid"
                                        loading="eager"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />
                                    </a>
                                  </Col>
                                ))
                              ) : (
                                <div
                                  className="setReponse"
                                  style={{ transform: "translate(10px, 0px)" }}
                                >
                                  <p className="cls4 errorResponse">
                                    Nothing Found!
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="buttonBorder">
                            <button
                              type="button"
                              className="text"
                              onClick={async () => {
                                await getStickerJSON(
                                  stickersValue ? stickersValue : searchHints,
                                  limit + 6
                                );
                                //stickerLimits, setStickerLimit
                                setLimit(limit + 6);
                              }}
                            >
                              Load More
                            </button>
                          </div>
                        </div>
                        <div
                          className="subView tab-pane fade"
                          id="imageTab"
                          role="tabpanel"
                          aria-labelledby="image-tab"
                        >
                          <div
                            className="chooseImage tabsImages"
                            style={{
                              display: "flex",
                              width: "100%",
                              height: "300px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="file"
                              onChange={async (event) => {
                                if (
                                  listener &&
                                  currentEditItem?.type === "photo"
                                ) {
                                  var file = event.target.files[0];
                                  const formData = new FormData();
                                  formData.append("image", file);
                                  // console.log(cardId)
                                  setLoader(true);
                                  await uploadPhoto(cardId, formData).then(
                                    async (response) => {
                                      if (response.success) {
                                        var returnData = {};
                                        var styleData = {};
                                        returnData = currentEditItem;
                                        styleData = await Object.assign(
                                          returnData.style,
                                          {
                                            url: response.data,
                                          }
                                        );
                                        returnData = await Object.assign(
                                          returnData,
                                          { edit: false, style: styleData }
                                        );
                                        setTimeout(async function () {
                                          setLoader(false);
                                          await setCurrentEditItems(returnData);
                                        }, 5000);
                                      } else {
                                        setLoader(false);
                                        toastComp(
                                          addToast,
                                          "error",
                                          response.message
                                        );
                                      }
                                    }
                                  );
                                } else {
                                  toastComp(
                                    addToast,
                                    "error",
                                    "Please select the any custom photo type editable component!"
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                open={open}
                onClose={(e) => onCloseModal()}
                closeOnOverlayClick={false}
              >
                <div
                  className="container-fluid"
                  style={{ width: "100%", height: "100%" }}
                >
                  <div className="row justify-content-center">
                    <div className="col-md-12 col-12">
                      <h3 style={{ textAlign: "left" }}>Edit Card Cover</h3>
                    </div>
                    <div
                      className="col-md-12 col-12"
                      style={{ marginTop: "15px", border: "solid #cecece 1px" }}
                    >
                      <div className="row justify-content-center">
                        <div className="col-lg-12 col-12">
                          <div className="row">
                            <div
                              className="col-md-4 col-12"
                              id="modaleditoccasion"
                              style={{ float: "left" }}
                            >
                              <div className="form-group">
                                <div className="form-control detail-control">
                                  <div className="dropdown1">
                                    <div
                                      className="dropdown-header1"
                                      onClick={toggleDropdown}
                                      style={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      {selectedCategory
                                        ? selectedCategory
                                        : "select occasion"}
                                      <i
                                        className={`fa fa-chevron-right icon ${
                                          isOpen && "open"
                                        }`}
                                      ></i>
                                    </div>
                                    <div
                                      className={`dropdown-body1 ${
                                        isOpen && "open"
                                      }`}
                                    >
                                      {categories?.map((item) => (
                                        <div
                                          className="dropdown-item1"
                                          onClick={(e) =>
                                            handleCategorySelect(
                                              e,
                                              item.slug,
                                              item
                                            )
                                          }
                                          key={item.slug}
                                        >
                                          {/* <span className={`dropdown-item-dot ${item.id == selectedItem && 'selected'}`}>• </span> */}
                                          {item.name}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {selectedCategory ? (
                            <div className="col-md-12 col-12">
                              <div
                                className="row remRpadding"
                                style={{
                                  width: "100%",
                                  paddingRight: "25px",
                                  paddingLeft: "0px",
                                }}
                              >
                                {loading ? (
                                  <div
                                    className="col-md-12"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div className="loader-text">
                                      <div
                                        className="spinner-border text-success2"
                                        role="status"
                                        style={{
                                          color: "#81dbcd",
                                        }}
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ) : chooosedTemplateData1?.data?.length > 0 ? (
                                  chooosedTemplateData1?.data.map(
                                    (item, index) =>
                                      item != null ? (
                                        <div
                                          className="col-md-3 col-6"
                                          style={{
                                            position: "relative",
                                            transform:
                                              showSelectedImage &&
                                              selectedImage &&
                                              selectedImage._id === item._id
                                                ? "scale(1)"
                                                : "scale(0.95)",
                                            transition: "all.3s ease-in-out",

                                            marginBottom: "10px",
                                          }}
                                          key={index}
                                        >
                                          <div
                                            className={
                                              item?.price === 0
                                                ? "container bannerPrice"
                                                : "container"
                                            }
                                            data-ribbon={
                                              item?.price === 0 ? "FREE" : ""
                                            }
                                            style={{
                                              padding: "0px",
                                            }}
                                          >
                                            <img
                                              className="img-fluid image choosedImage"
                                              alt={
                                                item?.frontAlt
                                                  ? item?.frontAlt
                                                  : item.name
                                              }
                                              title={
                                                item?.frontAlt
                                                  ? item?.frontAlt
                                                  : item.name
                                              }
                                              src={BASE_IMAGE_URL + item.front}
                                              id={"gpgsici" + index}
                                              onMouseEnter={() =>
                                                handleImageSelect(item)
                                              }
                                              onMouseLeave={handleMouseLeave}
                                              style={{
                                                cursor: "pointer",
                                                backgroundColor: "transparent",
                                                width: "100%",
                                                boxShadow:
                                                  "lightgrey 0px 0.7rem 0.8rem",
                                                boxShadow:
                                                  showSelectedImage &&
                                                  selectedImage &&
                                                  selectedImage._id === item._id
                                                    ? "0 0 5px 2px #81dbcd"
                                                    : "0 0 5px 2px lightgrey ",
                                              }}
                                            />
                                            {showSelectedImage &&
                                              selectedImage._id ===
                                                item._id && (
                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform:
                                                      "translate(-50%, -50%)",
                                                  }}
                                                  onMouseEnter={() =>
                                                    handleImageSelect(item)
                                                  }
                                                >
                                                  <button
                                                    className="card-btn button--moema"
                                                    style={{
                                                      width: "100px",
                                                      borderRadius: "16px",
                                                    }}
                                                    onClick={(e) => {
                                                      handleContinue();
                                                    }}
                                                  >
                                                    continue
                                                  </button>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      ) : null
                                  )
                                ) : (
                                  <div
                                    className="col-12"
                                    style={{
                                      width: "100%",
                                      height: "500px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h2>Data Not Found!</h2>
                                  </div>
                                )}

                                <div className="col-12">
                                {/* <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={page}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={1}
                          onPageChange={handlePageChange}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        /> */}
                                                    <div className="row justify-content-center">
                                                      <button
                                                        onClick={() =>
                                                          handlePageChange(
                                                            page - 1
                                                          )
                                                        }
                                                        className={`customPagination ${
                                                          page > 1
                                                            ? ""
                                                            : "disabledButton"
                                                        }`}
                                                        style={{
                                                          marginRight: "10px",
                                                          width:'auto'
                                                        }}
                                                      >
                                                       Prev
                                                      </button>

                                                      {page > 1 &&
                                                      page < 2 &&
                                                      Math.ceil(
                                                        chooosedTemplateData1.totalCountofTemplates /
                                                          itemsPerPage
                                                      ) > 2 ? (
                                                        <button
                                                          onClick={() =>
                                                            handlePageChange(1)
                                                          }
                                                          className={`customPagination ${
                                                            1 === page
                                                              ? "activePage"
                                                              : ""
                                                          }`}
                                                        >
                                                          {1}
                                                        </button>
                                                      ) : null}

                                                      {Array.from(
                                                        {
                                                          length: Math.min(
                                                            2,
                                                            Math.ceil(
                                                              chooosedTemplateData1.totalCountofTemplates /
                                                                itemsPerPage
                                                            ) -
                                                              Math.max(
                                                                4,
                                                                page - 1
                                                              )
                                                          ),
                                                        },
                                                        (_, index) => (
                                                          <button
                                                            key={page + index}
                                                            onClick={() =>
                                                              handlePageChange(
                                                                page + index
                                                              )
                                                            }
                                                            className={`customPagination ${
                                                              page + index ===
                                                              page
                                                                ? "activePage"
                                                                : ""
                                                            }`}
                                                            style={{ width:'auto'}}
                                                          >
                                                            {page + index}
                                                          </button>
                                                        )
                                                      )}

                                                      {/* {page <
                                                        Math.ceil(
                                                          chooosedTemplateData1.totalCountofTemplates /
                                                            itemsPerPage
                                                        ) -
                                                          2 && (
                                                        <span>
                                                          {" "}
                                                          <div className="customPagination" style={{display:'inline-block'}}>
                                                            ....
                                                          </div>
                                                        </span>
                                                      )} */}

                                                      <button
                                                        onClick={() =>
                                                          handlePageChange(
                                                            page + 1
                                                          )
                                                        }
                                                        className={`customPagination ${
                                                          page <
                                                          Math.ceil(
                                                            chooosedTemplateData1.totalCountofTemplates /
                                                              itemsPerPage
                                                          )
                                                            ? ""
                                                            : "disabledButton"
                                                        }`}
                                                        style={{
                                                          marginLeft: "10px",
                                                           width:'auto'
                                                        }}
                                                      >
                                                       Next
                                                      </button>
                                                    </div>
                                                  </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <h1>please select category</h1>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-12 col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminEditCard;
