import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  getUserOrderSingleDetails,
  getUserDetails,
  getTemplateList,
  getCategoryList,
} from "../Action/GetAction";
import { updateUserOrderDetails } from "../Action/PutAction";
import { BASE_IMAGE_URL, postMethod, WEB_URL } from "../Utils/ApiServices";
import { useToasts } from "react-toast-notifications";
import { toastComp } from "../Sections/Toast";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import timezones from "compact-timezone-list";
import "react-overlay-loader/styles.css";
import { confirmAlert } from 'react-confirm-alert';

import AdminEditCard from "./AdminEditCard";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const ViewOrderDetails = () => {
  let query = useQuery();
  const history = useHistory();
  const { addToast } = useToasts();
  const pickMonthArray = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const today = new Date();
  const minDate =
    today.getFullYear() +
    "-" +
    pickMonthArray[today.getMonth() + 1] +
    "-" +
    (today.getDate() < 10 ? pickMonthArray[today.getDate()] : today.getDate());
  const twoWeeks = 1000 * 60 * 60 * 24 * 14;
  const newTwoWeeks = new Date(new Date().getTime() + twoWeeks);
  const maxDate =
    newTwoWeeks.getFullYear() +
    "-" +
    pickMonthArray[newTwoWeeks.getMonth() + 1] +
    "-" +
    (newTwoWeeks.getDate() < 10
      ? pickMonthArray[newTwoWeeks.getDate()]
      : newTwoWeeks.getDate());
  const [laoder1, setLoader1] = useState(true);
  const [getDetailsCreater, setCreaterDetails] = useState("");
  const [templateslist, setTemplateList] = useState();
  const [template, setTemplate] = useState("");
  const [orderDetails, setOrderDetails] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [sendersName, setSendersName] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [deliveryTimeZone, setDeliveryTimeZone] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [reminder, setReminder] = useState(1);
  const [notficationStatus, setNotificationStatus] = useState(false);
  const [editcard, setEditcard] = useState(false);
  const [multiplename, setMultiplename] = useState('');
  const [multipleemail, setMultipleEmail] = useState('');
  const [multipleid, setMultipleid] = useState('');
  const [deleteid, setDeleteid] = useState('');
  const [canvasid, setCanvasid] = useState('');
  const dateFormatConverter = (arg) => {
    let date = new Date(arg);
    return (
      date.getUTCFullYear() +
      "-" +
      pickMonthArray[date.getUTCMonth() + 1] +
      "-" +
      (date.getUTCDate() < 10
        ? pickMonthArray[date.getUTCDate()]
        : date.getUTCDate())
    );
  };
  const [editorderId, setEditorderId] = useState();
  const getUserSingleOrder = async (id) => {
    await getUserOrderSingleDetails(id).then((response) => {
      if (response.status) {
        if (response.order && response.order[0]) {
          setLoader1(false);
          setOrderDetails(response.order[0]);
          setEditorderId(response?.order[0]._id)
          setCanvasid(response?.order[0].canvasId)

          setCreaterDetails(
            response?.order[0]?.user ? response?.order[0]?.user : ""
          );
          setTemplate(
            response.order[0]?.template?._id
              ? response.order[0]?.template?._id
              : ""
          );
          setRecipientName(
            response.order[0]?.recipient?.name
              ? response.order[0]?.recipient?.name
              : ""
          );
          setRecipientEmail(
            response.order[0]?.recipient?.email
              ? response.order[0]?.recipient?.email
              : ""
          );
          setSendersName(
            response.order[0]?.sendersName ? response.order[0]?.sendersName : ""
          );
          setDeliveryDate(
            response.order[0]?.deliveryDate
              ? dateFormatConverter(response.order[0]?.deliveryDate)
              : ""
          );
          setDeliveryTime(
            response.order[0]?.deliveryTime
              ? response.order[0]?.deliveryTime
              : ""
          );
          setDeliveryTimeZone(
            response.order[0]?.timeZone
              ? response.order[0]?.timeZone == "Asia/Calcutta"
                ? "Asia/Kolkata"
                : response.order[0]?.timeZone
              : ""
          );
          setDeliveryStatus(
            response.order[0]?.status ? response.order[0]?.status : ""
          );
          setReminder(
            response.order[0]?.setReminder ? response.order[0]?.setReminder : 0
          );
          setNotificationStatus(
            response?.order[0]?.sendMeNotification
              ? response?.order[0]?.sendMeNotification
              : false
          );
        }
        //console.log(response)
      } else {
        history.push("/user-list");
      }
    });
  };
  const getInitialOffset = (tz) => {
    var returnOff = "00:00";
    for (let li = 0; li < timezones.length; li++) {
      if (tz == timezones[li].tzCode) {
        returnOff = timezones[li].offset;
      }
    }
    return returnOff;
  };
  const onUpdateOrderDetails = async () => {
    if (template != "") {
      var data = {
        template: template,
        deliveryDate: deliveryDate,
        timezoneOffset: getInitialOffset(deliveryTimeZone),
        timeZone: deliveryTimeZone,
        sendMeNotification: notficationStatus,
        deliveryTime: deliveryTime,
        sendersName: sendersName,
        recipient: {
          name: recipientName,
          email: recipientEmail,
        },
        status: deliveryStatus,
        reminderSetStatus: reminder == 0 ? false : true,
        setReminder: reminder,
      };
      if (deliveryStatus === "complete") {
        data = Object.assign(data, { collaboratorStatus: "expired" });
      } else {
        data = Object.assign(data, { collaboratorStatus: "signed" });
      }
      const params = {
        orderId: orderDetails._id,
      };
      await updateUserOrderDetails(data, params).then((response) => {
        if (response.status) {
          toastComp(addToast, "success", "Order details updated successfully!");
        } else {
          toastComp(addToast, "error", response.message);
        }
      });
    } else {
      toastComp(addToast, "error", "Please select the order template!");
    }
  };

  const handleAddRec = async (e, id) => {
    e.preventDefault();

    const { emailrec, namerec } = e.target.elements;
    if (emailrec.value && namerec.value) {
      const data = { email: emailrec.value, name: namerec.value };
      await postMethod("admin/add-multiple-coll/" + id, data).then(
        async (response) => {
          if (response?.success) {
            toastComp(addToast, "success", response.message);
            document.getElementById("resetIP").reset();
            await getUserSingleOrder(id);
          } else {
            toastComp(addToast, "error", response.message);
          }
        }
      );
    }
  };
  const hnadleredit = async (id, name, email) => {

    setMultipleid(id);
    setMultiplename(name);
    setMultipleEmail(email);

    setEditcard(true)
  };
  const hnadleSubmitedit = async (e) => {
    e.preventDefault();
    console.log(multiplename, multipleemail)
    const reqBody = {
      _id: editorderId ? editorderId : '',
      id: multipleid,
      "newname": multiplename,
      "newemail": multipleemail
    };

    await postMethod("/admin/updateuser", reqBody).then(
      async (response) => {
        if (response?.status === true) {
          setMultiplename('');
          setMultipleEmail('');
          setEditcard(false)
          toastComp(addToast, "success", response.message);
          await getUserSingleOrder(canvasid);

        } else {
          toastComp(addToast, "error", response.message);
        }
      })
  };
  const popup = (id, index) => {
    confirmAlert({
      title: 'Confirm Action',
      message: 'Are you sure you want to delete this order?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => hnadlerdelete(id, index)
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }
  const hnadlerdelete = async (id, name, email) => {
    setDeleteid(id)
    const reqBody = {
      _id: editorderId ? editorderId : '',
      recipientId: deleteid,

    };
    console.log('reqbody', reqBody)
    if (deleteid != '') {
      await postMethod("/admin/deleteUser", reqBody).then(
        async (response) => {
          if (response?.status == true) {

            // setLoader1(false);

            toastComp(addToast, "success", response.message);
            await getUserSingleOrder(canvasid);

          } else {
            toastComp(addToast, "error", response.message);
          }
        })
    }

  };

  useEffect(async () => {
    if (query.get("order")) {
      await getUserSingleOrder(query.get("order"));
      await getTemplateList("template/templateslist").then(async (response) => {
        if (response.success) {
          setTemplateList(response?.template);
        }
      });
    } else {
      history.push("/user-list");
    }
  }, []);



  return (
    <div className="nk-content">
      <div className="container-fluid pt-3 px-5">
        <div className="nk-content-body">
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between g-3">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  View Order Details
                </h3>
              </div>
            </div>
          </div>
          {query.get("order") ? (
            <AdminEditCard
              cardIdQuery={query.get("order") ? query.get("order") : false}
              orderDetails={orderDetails}
            />
          ) : null}

          <div className="nk-block">
            <div className="row gy-10">
              <div className="col-lg-6 col-12">
                <div className="card card-bordered">
                  {laoder1 ? (
                    <LoadingOverlay className="loaderDiv">
                      <Loader loading={laoder1} />
                    </LoadingOverlay>
                  ) : null}
                  <ul className="data-list is-compact">
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Card Created By </div>
                        {getDetailsCreater?.firstName ? (
                          <Link
                            className="data-value ip-field disabled"
                            to={"/user-details?id=" + getDetailsCreater?._id}
                            title="Click To View Profile Details"
                          >
                            {getDetailsCreater?.firstName +
                              " " +
                              getDetailsCreater?.lastName}
                          </Link>
                        ) : (
                          <a className="data-value ip-field disabled">
                            Guest User
                          </a>
                        )}
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Template</div>
                        <select
                          className="data-value ip-field disabled"
                          value={template}
                          name="templateChoose"
                          onChange={(e) => setTemplate(e.target.value)}
                        >
                          <option value="">Select</option>
                          {templateslist?.map((list, index) => (
                            <option value={list._id} key={index}>
                              {list.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Recipient Name</div>
                        <input
                          type="text"
                          className="data-value ip-field disabled"
                          name="recipientName"
                          value={recipientName}
                          onChange={(e) => setRecipientName(e.target.value)}
                        />
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Recipient Email</div>
                        <input
                          type="text"
                          className="data-value ip-field disabled"
                          name="recipientEmail"
                          value={recipientEmail}
                          // style={{ textTransform: "lowercase" }}
                          onChange={(e) => setRecipientEmail(e.target.value)}
                        />
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Sender Name</div>
                        <input
                          type="text"
                          className="data-value ip-field disabled"
                          name="sendersName"
                          value={sendersName}
                          onChange={(e) => setSendersName(e.target.value)}
                        />
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Delivery Date</div>
                        <input
                          type="date"
                          className="data-value ip-field disabled"
                          name="deliveryDate"
                          value={deliveryDate}
                          min={minDate}
                          max={maxDate}
                          onChange={(e) => setDeliveryDate(e.target.value)}
                        />
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Delivery Time</div>
                        <input
                          type="time"
                          className="data-value ip-field disabled"
                          name="deliveryTime"
                          value={deliveryTime}
                          onChange={(e) => setDeliveryTime(e.target.value)}
                        />
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Delivery TimeZone</div>
                        <select
                          className="data-value ip-field disabled"
                          name="deliveryTimeZone"
                          value={deliveryTimeZone}
                          onChange={(e) => setDeliveryTimeZone(e.target.value)}
                        >
                          <option value={""}>Select</option>
                          {timezones?.map((list, index) => (
                            <option value={list.tzCode} key={index}>
                              {list.tzCode}
                            </option>
                          ))}
                        </select>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Card Status</div>
                        <select
                          className="data-value ip-field"
                          name="deliveryTimeZone"
                          value={deliveryStatus}
                          onChange={(e) => setDeliveryStatus(e.target.value)}
                        >
                          <option value={""}>Select</option>
                          <option value={"start"}>Template Selected</option>
                          <option value={"recipientassigned"}>
                            Payment Pending
                          </option>
                          <option value={"pending"}>Schedule Pending</option>
                          <option value={"paid"}>Paid</option>
                          <option value={"scheduled"}>Card Scheduled</option>
                          <option value={"complete"}>Completed</option>
                          <option value={"reopen"}>Reopen</option>
                        </select>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Reminder</div>
                        <select
                          className="data-value ip-field disabled"
                          name="selectreminder"
                          value={reminder}
                          onChange={(event) => setReminder(event.target.value)}
                        >
                          <option value={""}>Select</option>
                          {[
                            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                            15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                          ].map((item, index) => (
                            <option value={item} key={index}>
                              {item === 0
                                ? "No reminder"
                                : item === 1
                                  ? item + " hour"
                                  : item + " hours"}
                            </option>
                          ))}
                        </select>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">
                          Send Notification Status
                        </div>
                        <select
                          className="data-value ip-field disabled"
                          name="notificationStatus"
                          value={notficationStatus}
                          onChange={(e) =>
                            setNotificationStatus(e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Collaborator Url</div>
                        <label
                          className="data-value ip-field disabled"
                          name="cardAddOn"
                          style={{ textTransform: "lowercase" }}
                        >
                          <a
                            href={
                              orderDetails?.collaboratorLink
                                ? WEB_URL + orderDetails?.collaboratorLink
                                : ""
                            }
                          >
                            {orderDetails?.collaboratorLink
                              ? WEB_URL + orderDetails?.collaboratorLink
                              : "Not generate"}
                          </a>
                        </label>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Recipient Url</div>
                        <label
                          className="data-value ip-field disabled"
                          name="cardAddOn"
                          style={{ textTransform: "lowercase" }}
                        >
                          <a
                            href={
                              orderDetails?.recipientLink
                                ? WEB_URL + orderDetails?.recipientLink
                                : ""
                            }
                          >
                            {orderDetails?.recipientLink
                              ? WEB_URL + orderDetails?.recipientLink
                              : "Not Generate"}
                          </a>
                        </label>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">
                          Total Number of Collaborator
                        </div>
                        <label
                          className="data-value ip-field disabled"
                          name="cardAddOn"
                          style={{ textTransform: "lowercase" }}
                        >
                          {orderDetails?.collaborator
                            ? orderDetails?.collaborator.length
                            : ""}
                        </label>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Card Open By Recipient</div>
                        <label
                          className="data-value ip-field disabled"
                          name="opened"
                        >
                          {orderDetails?.cardOpened ? "Yes" : "No"}
                        </label>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Card Posted</div>
                        <label
                          className="data-value ip-field disabled"
                          name="posted"
                        >
                          {orderDetails?.status === "complete" ? "Yes" : "No"}
                        </label>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Payment Method</div>
                        <label
                          className="data-value ip-field disabled"
                          name="posted"
                          style={{ textTransform: "capitalize" }}
                        >
                          {orderDetails?.payment
                            ? orderDetails?.payment?.paymentMethod +
                            (orderDetails?.couponId?._id ? ", " : "")
                            : orderDetails?.productType?.price === 0
                              ? "Free Card"
                              : [
                                "start",
                                "recipientassigned",
                                "pending",
                              ].includes(orderDetails?.status)
                                ? "Not Applied"
                                : ""}
                          {orderDetails?.couponId?._id
                            ? "Coupon Applied"
                            : null}
                        </label>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Amount</div>
                        <label
                          className="data-value ip-field disabled"
                          name="posted"
                          style={{ textTransform: "capitalize" }}
                        >
                          {orderDetails?.payment
                            ? orderDetails?.payment?.paymentMethod === "wallet"
                              ? ""
                              : "CAD "
                            : ""}
                          {orderDetails?.payment?.paymentMethod === "wallet"
                            ? orderDetails?.payment?.amount +
                            " debited through wallet"
                            : orderDetails?.payment?.amount
                              ? orderDetails?.payment?.amount
                              : orderDetails?.productType?.price > 0
                                ? orderDetails?.couponId?._id
                                  ? orderDetails?.couponId?.discount_type ===
                                    "percent"
                                    ? "CAD " +
                                    (orderDetails?.productType?.price -
                                      orderDetails?.productType?.price *
                                      (orderDetails?.couponId?.discount / 100))
                                    : orderDetails?.couponId?.discount_type ===
                                      "fixed"
                                      ? "CAD " +
                                      (orderDetails?.productType?.price -
                                        orderDetails?.couponId?.discount)
                                      : "Pending Amount CAD " +
                                      orderDetails?.productType?.price
                                  : "Pending Amount CAD " +
                                  orderDetails?.productType?.price
                                : orderDetails?.productType?.price === 0
                                  ? "CAD " + orderDetails?.productType?.price
                                  : "Pending"}
                        </label>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col justify-content-end">
                        <button
                          className="btn btn-md btn-primary"
                          // title={deliveryStatus == 'complete' ? 'Card is deliverd so it not be update!' : 'Update order details!'}
                          onClick={() => {
                            onUpdateOrderDetails();
                          }}
                        >
                          Edit Order Details
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                {laoder1 ? (
                  <LoadingOverlay className="loaderDiv">
                    <Loader loading={laoder1} />
                  </LoadingOverlay>
                ) : null}
                <div
                  className="row"
                  style={{ padding: "15px 0px", width: "100%", margin: "0px" }}
                >
                  <div
                    className="col-lg-12 col-12"
                    style={{ margin: "0px 0px", padding: "0px" }}
                  >
                    <h2 style={{ fontSize: "14px", padding: "10px 0px" }}>
                      Add Multiple Recipient
                    </h2>
                    <div
                      className="card card-bordered"
                      style={{ padding: "15px" }}
                    >
                      <form
                        id="resetIP"
                        onSubmit={(e) => handleAddRec(e, query.get("order"))}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <input
                            className="data-value ip-field"
                            style={{
                              width: "50%",
                              border: "solid #ececec 1px",
                              lineHeight: "normal",
                              padding: "5px 15px",
                              margin: "5px 5px",
                            }}
                            type="email"
                            name="emailrec"
                            required
                            id="emailrec"
                            placeholder="Enter Email"
                          />
                          <input
                            className="data-value ip-field"
                            style={{
                              width: "50%",
                              border: "solid #ececec 1px",
                              lineHeight: "normal",
                              padding: "5px 15px",
                              margin: "5px 5px",
                            }}
                            type="text"
                            name="namerec"
                            required
                            id="namerec"
                            placeholder="Enter Name"
                          />
                        </div>
                        <input
                          type="submit"
                          style={{
                            width: "100%",
                            margin: "5px 5px",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                          className="btn btn-md btn-primary"
                          value="ADD"
                        />
                      </form>
                      {editcard &&
                        <form
                          id="resetIP"
                          onSubmit={(e) => hnadleSubmitedit(e)}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <input
                              className="data-value ip-field"
                              style={{
                                width: "50%",
                                border: "solid #ececec 1px",
                                lineHeight: "normal",
                                padding: "5px 15px",
                                margin: "5px 5px",
                              }}
                              type="email"
                              name="multipleemail"
                              id="emailrec"
                              placeholder="Enter Email"
                              value={multipleemail}
                              onChange={(e) => setMultipleEmail(e.target.value)}
                            />
                            <input
                              className="data-value ip-field"
                              style={{
                                width: "50%",
                                border: "solid #ececec 1px",
                                lineHeight: "normal",
                                padding: "5px 15px",
                                margin: "5px 5px",
                              }}
                              type="text"
                              name="multiplename"
                              id="namerec"
                              placeholder="Enter Name"
                              value={multiplename}
                              onChange={(e) => setMultiplename(e.target.value)}
                            />
                          </div>
                          <input
                            type="submit"
                            style={{
                              width: "100%",
                              margin: "5px 5px",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                            className="btn btn-md btn-primary"
                            value="Edit"
                          />
                        </form>

                      }
                    </div>
                    <div className="card card-bordered">
                      <div className="nk-tb-list nk-tb-ulist">
                        <div className="nk-tb-item nk-tb-head">
                          <div className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text text-center">S.No</span>
                          </div>
                          <div className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text text-center">Email</span>
                          </div>
                          <div className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text text-center">Name</span>
                          </div>


                        </div>
                        {orderDetails?.multipleRecipient?.map(
                          (list, colIndex) => (
                            <div className="nk-tb-item" key={colIndex}>
                              <div className="nk-tb-col">
                                <div className="user-info">
                                  <span className="tb-lead">
                                    {colIndex + 1}
                                  </span>
                                </div>
                              </div>
                              <div className="nk-tb-col">
                                <div className="user-info">
                                  <span className="tb-lead">
                                    {list?.email ? list?.email : "---"}
                                  </span>
                                </div>
                              </div>
                              <div className="nk-tb-col">
                                <div className="user-info">
                                  <span className="tb-lead">
                                    {list?.name ? list?.name : "---"}
                                  </span>
                                </div>
                              </div>
                              <div className="nk-tb-col nk-tb-col-tools">
                                <ul className="nk-tb-actions gx-1">
                                  <li>
                                    <div className="drodown">
                                      <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <ul className="link-list-opt no-bdr">
                                        
                                          <li><a onClick={() => hnadleredit(list._id, list.name, list.email)}><em className="icon ni ni-eye"></em><span>Edit </span></a></li>
                                          <li><a onClick={(e) => { popup(list._id, list.colIndex) }}><em class="icon ni ni-trash"></em><span> Delete </span></a></li>
                                        </ul>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="row"
                  style={{ padding: "15px 0px", width: "100%", margin: "0px" }}
                >
                  <div
                    className="col-lg-12 col-12"
                    style={{ margin: "0px 0px", padding: "0px" }}
                  >
                    <h2 style={{ fontSize: "14px", padding: "10px 0px" }}>
                      Collaborator Details1
                    </h2>
                    <div className="card card-bordered">
                      <div className="nk-tb-list nk-tb-ulist">
                        <div className="nk-tb-item nk-tb-head">
                          <div className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text text-center">S.No</span>
                          </div>
                          <div className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text text-center">
                              Collaborator Email
                            </span>
                          </div>
                          <div className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text text-center">Name</span>
                          </div>
                          <div className="nk-tb-col tb-col-md text-center">
                            <span className="sub-text text-center">Opt In</span>
                          </div>
                        </div>
                        {orderDetails?.collaborator?.map((list, colIndex) => (
                          <div className="nk-tb-item" key={colIndex}>
                            <div className="nk-tb-col">
                              <div className="user-info">
                                <span className="tb-lead">{colIndex + 1}</span>
                              </div>
                            </div>
                            <div className="nk-tb-col">
                              <div className="user-info">
                                <span className="tb-lead">
                                  {list?.email ? list?.email : "Guest Account"}
                                </span>
                              </div>
                            </div>
                            <div className="nk-tb-col">
                              <div className="user-info">
                                <span className="tb-lead">
                                  {list?.name
                                    ? list?.name
                                    : orderDetails?.showMeFinalCard
                                      ?.map((items) => ({
                                        email: items?.email,
                                        name: items?.name,
                                      }))
                                      .filter((items) => items)
                                      .filter((n) => n.email === list?.email)
                                      ?.length === 1
                                      ? orderDetails?.showMeFinalCard
                                        ?.map((items) => ({
                                          email: items?.email,
                                          name: items?.name,
                                        }))
                                        .filter((items) => items)
                                        .filter(
                                          (n) => n.email === list?.email
                                        )[0]?.name
                                        ? orderDetails?.showMeFinalCard
                                          ?.map((items) => ({
                                            email: items?.email,
                                            name: items?.name,
                                          }))
                                          .filter((items) => items)
                                          .filter(
                                            (n) => n.email === list?.email
                                          )[0]?.name
                                        : "---"
                                      : "---"}
                                </span>
                              </div>
                            </div>
                            <div className="nk-tb-col">
                              <div className="user-info">
                                <span className="tb-lead">
                                  {list?.email != undefined || list?.email != ""
                                    ? orderDetails?.showMeFinalCard
                                      ?.map((items) => items?.email)
                                      .filter((items) => items)
                                      ?.includes(list?.email)
                                      ? "Yes"
                                      : "No"
                                    : "No"}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  );
};
export default ViewOrderDetails;
