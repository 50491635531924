import React, { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import { deleteCoupon } from "../Action/DeleteAction";
import { editCoupon } from '../Action/PutAction';
import { getCouponDetails, getUserlist, getTemplateList, getCategoryList, getProductList, getPricingPlanDetails } from "../Action/GetAction";
import { addCouponDetails } from '../Action/PostAction';
import { dateFormatConverter, matrixify1, explitMultSelect, normalMulti, mergeParentChildrenCategory, getOnlyValuefetch, fetcheditMultiData } from "../Logic/Logic";
import { useToasts } from 'react-toast-notifications';
import { toastComp } from "../Sections/Toast";
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { Modal } from "react-responsive-modal";
import { MultiSelect } from "react-multi-select-component";
import { confirmAlert } from 'react-confirm-alert';
import { format } from 'fecha';
import MultiSelect3 from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-overlay-loader/styles.css';
import "react-responsive-modal/styles.css";
const CouponList = (props) => {
    const { addToast } = useToasts();
    const pickMonthArray = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const today = new Date();
    const minDate = today.getFullYear() + '-' + pickMonthArray[today.getMonth() + 1] + '-' + (today.getDate() < 10 ? pickMonthArray[today.getDate()] : today.getDate());
    const [toggleSearchBar, setToggleSearchBar] = useState(true);
    const [productList, setProductList] = useState([]);
    const [countProduct, setCountProduct] = useState(0);
    const [paginationIndex, setPageIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [loader, setLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState(false);
    const [editId, setEditId] = useState(false);

    const [couponName, setCouponName] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [couponType, setCouponType] = useState('');
    const [walletPoint, setwalletPoint] = useState(0);
    const [useLimitPerCoustomer, setLimitPerCoustomer] = useState(0);
    const [status, setStatus] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [userSelectValue, setSelectedUser] = useState([]);


    const [discountType, setDiscountType] = useState('');
    const [discount, setDiscount] = useState(0);
    
   
    const [minSpend, setMinSpend] = useState(0);
    const [maxSpend, setMaxSpend] = useState(0);
    const [selectedTemplate, setSelectedTemplate] = useState([]);
    const [listCategoryOption, setListCategoryOption] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [listOptionProduct, selectProductOption] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [listUserDetails, setListUser] = useState([]);
    const [listTemplatesOption, setlistTemplates] = useState([]);
    const [selectedBundle, setSelectedBundle] = useState([]);
    const [listOptionBundle, selectedBundleOption] = useState([]);
    const [originalProductList, setOriginalProductList] = useState([]);
    const dateFormatConverter2 = (arg) => {
        let date = new Date(arg);
        return date.getUTCFullYear() + '-' + pickMonthArray[date.getUTCMonth() + 1] + '-' + (date.getUTCDate() < 10 ? pickMonthArray[date.getUTCDate()] : date.getUTCDate());
    }
    const getListData = async (isNote, message) => {
        await getCouponDetails('coupon/getcoupons').then(async response => {
            if (response.success) {
                setLoader(false);
                if (response.coupon.length > 0) {
                    onCloseModal();
                    //console.log(response.coupon)
                    //const arrayList = await matrixify1(response.coupon, 10);
                    await setProductList(response.coupon);
                    await setOriginalProductList(response.coupon)
                    await setCountProduct(response.total);
                    // await setParentCategory(response.data.categories);
                    if (isNote) {
                        toastComp(addToast, 'success', message);
                    }
                }
            }
        })
    }
    const onCloseModal = (props) => {
        setOpen(false);
        setEditData(false);
        setEditId(false);
        setCouponName('')
        setCouponCode('')
        setDiscountType('')
        setDiscount(0)
        // setMaxDiscount('')
        setStatus('')
        setStartDate('')
        setEndDate('')
        // setUseLimitPerCoupon('')
        setLimitPerCoustomer(0)
        setMinSpend(0)
        setMaxSpend(0)
        setSelectedBundle([])
        setCouponType('')
        setSelectedUser([])
        setSelectedTemplate([])
        setSelectedCategory([])
        setSelectedProduct([])
        setwalletPoint(0)
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPageIndex(selectedPage);
    };
    const handleSerach = async (value) => {
        setSearch(value);
        setLoader(true);
        await getCouponDetails('coupon/getcoupons?name=' + value).then(async response => {
            console.log(response)
            if (response.success) {
                if (response.coupon.length > 0) {
                    setLoader(false);
                    const arrayList = response.coupon;
                    await setProductList(arrayList);
                    await countTotalProduct(arrayList.length);
                } else {
                    setLoader(false);
                }
            }
        })
    }


    const validateWalletType = ()=>{

        if(couponName !== "" || couponCode !== "" || walletPoint !== 0 || useLimitPerCoustomer !== 0 || status !== "" || startDate !== "" || endDate !== ""){
            if(couponName !== ""){
                setLoader(false);
                document.getElementById('couponName').classList.remove("err")
            }
            if(couponCode !== ""){
                setLoader(false);
                document.getElementById('couponCode').classList.remove("err")
            }
            if(walletPoint !== 0){
                setLoader(false);
                document.getElementById('walletPoint').classList.remove("err")
            }
            if(useLimitPerCoustomer !== 0){
                setLoader(false);
                document.getElementById('useLimitPerCoustomer').classList.remove("err")
            }
            if(status !== ""){
                setLoader(false);
                document.getElementById('status').classList.remove("err")
            }
            if(startDate !== ""){
                setLoader(false);
                document.getElementById('startDate').classList.remove("err")
            }
            if(endDate !== ""){
                setLoader(false);
                document.getElementById('endDate').classList.remove("err")
            }
        } 

        if(couponName === "" || couponCode === "" || walletPoint === 0 || useLimitPerCoustomer === 0 || status === "" || startDate === "" || endDate === ""){
            if(couponName === ""){
                setLoader(false);
                document.getElementById('couponName').classList.add("err")
            }
            if(couponCode === ""){
                setLoader(false);
                document.getElementById('couponCode').classList.add("err")
            }
            if(walletPoint === 0){
                setLoader(false);
                document.getElementById('walletPoint').classList.add("err")
            }
            if(useLimitPerCoustomer === 0){
                setLoader(false);
                document.getElementById('useLimitPerCoustomer').classList.add("err")
            }
            if(status === ""){
                setLoader(false);
                document.getElementById('status').classList.add("err")
            }
            if(startDate === ""){
                setLoader(false);
                document.getElementById('startDate').classList.add("err")
            }
            if(endDate === ""){
                setLoader(false);
                document.getElementById('endDate').classList.add("err")
            }
            toastComp(addToast, 'error', 'Please fill  All required field !');
        } else {
            return true
        }
        
    }

    const validateDiscountType = ()=>{
        

        if(couponName !== "" || couponCode !== "" || useLimitPerCoustomer !== 0 || status !== "" || startDate !== "" || endDate !== "" || discountType !== '' || discount !== 0){
            if(couponName !== ""){
                setLoader(false);
                document.getElementById('couponName').classList.remove("err")
            }
            if(couponCode !== ""){
                setLoader(false);
                document.getElementById('couponCode').classList.remove("err")
            }
            if(useLimitPerCoustomer !== 0){
                setLoader(false);
                document.getElementById('useLimitPerCoustomer').classList.remove("err")
            }
            if(status !== ""){
                setLoader(false);
                document.getElementById('status').classList.remove("err")
            }
            if(startDate !== ""){
                setLoader(false);
                document.getElementById('startDate').classList.remove("err")
            }
            if(endDate !== ""){
                setLoader(false);
                document.getElementById('endDate').classList.remove("err")
            }
            if(discountType !== ''){
                setLoader(false);
                document.getElementById('discountType').classList.remove("err")
            }
            if(discount !== 0){
                setLoader(false);
                document.getElementById('discount').classList.remove("err")
            }
        }

        if(couponName === "" || couponCode === "" || useLimitPerCoustomer === 0 || status === "" || startDate === "" || endDate === "" || discountType === '' || discount === 0){
            if(couponName === ""){
                setLoader(false);
                document.getElementById('couponName').classList.add("err")
            }
            if(couponCode === ""){
                setLoader(false);
                document.getElementById('couponCode').classList.add("err")
            }
            if(useLimitPerCoustomer === 0){
                setLoader(false);
                document.getElementById('useLimitPerCoustomer').classList.add("err")
            }
            if(status === ""){
                setLoader(false);
                document.getElementById('status').classList.add("err")
            }
            if(startDate === ""){
                setLoader(false);
                document.getElementById('startDate').classList.add("err")
            }
            if(endDate === ""){
                setLoader(false);
                document.getElementById('endDate').classList.add("err")
            }
            if(discountType === ''){
                setLoader(false);
                document.getElementById('discountType').classList.add("err")
            }
            if(discount === 0){
                setLoader(false);
                document.getElementById('discount').classList.add("err")
            }
            toastComp(addToast, 'error', 'Please fill  All required field !');
        } else {
            return true
        }

    }



    const handleEditProduct = async (id) => {
        setLoader(true);
        if (couponType != '') {
             var data = {}
             document.getElementById('couponType').classList.remove("err")
             if(couponType === 'wallet'){

               let valid = await validateWalletType();
                if(!valid) { 
                    return
                }
               
                 data = Object.assign({
                    "name": couponName,
                    "code": (couponCode).toUpperCase(),
                    "couponType": couponType,
                    "walletPoint": walletPoint,
                    "usageLimitPerCustomer": useLimitPerCoustomer,
                    "status": status,
                    "valid_from": startDate,
                    "valid_till": endDate,
                    "users": userSelectValue?.length?(userSelectValue?.map((n)=>n !== ''?n:null)).filter((n)=>n):[],
                 })
             }else if(couponType === 'discount')
             {
               
                let valid = await validateDiscountType();
                if(!valid) { 
                    return
                }

                 data = Object.assign({
                    "name": couponName,
                    "code": (couponCode).toUpperCase(),
                    "couponType": couponType,
                    "usageLimitPerCustomer": useLimitPerCoustomer,
                    "status": status,
                    "valid_from": startDate,
                    "valid_till": endDate,
                    "users": userSelectValue?.length?(userSelectValue?.map((n)=>n !== ''?n:null)).filter((n)=>n):[],
                    "discount_type": discountType,
                    "minimumSpend": minSpend,
                    "maximumSpend": maxSpend,
                    "discount": discount,
                    "categories": selectedCategory?.length?(selectedCategory?.map((n)=>n !== ''?n:null)).filter((n)=>n):[],
                    "templates": selectedTemplate?.length?(selectedTemplate?.map((n)=>n !== ''?n:null)).filter((n)=>n):[],
                    "products": selectedProduct?.length?(selectedProduct?.map((n)=>n !== ''?n:null)).filter((n)=>n):[],
                    "bundles": selectedBundle?.length?(selectedBundle?.map((n)=>n !== ''?n:null)).filter((n)=>n):[],
                 })
             }
             const params = {
                "couponId": id
             }
             await editCoupon(data, params).then(async response => {
                if (response.success) {
                    await getListData(true, 'Successfully coupon updated!');
                } else {
                    setLoader(false);
                    toastComp(addToast, 'error', response.message);
                }
             })
        } else {
            setLoader(false);
            document.getElementById('couponType').classList.add("err")
            toastComp(addToast, 'error', 'Please Select Coupon Type');
        }
    }


    const handleAddProdcut = async () => {
        setLoader(true);
        if (couponType != '') {


             var data = {}
             document.getElementById('couponType').classList.remove("err")
             if(couponType === 'wallet'){

                let valid = await validateWalletType();
                if(!valid) { 
                    return;
                }
                
                 data = Object.assign({
                    "name": couponName,
                    "code": (couponCode).toUpperCase(),
                    "couponType": couponType,
                    "walletPoint": walletPoint,
                    "usageLimitPerCustomer": useLimitPerCoustomer,
                    "status": status,
                    "valid_from": startDate,
                    "valid_till": endDate,
                    "users": userSelectValue?.length?(userSelectValue?.map((n)=>n !== ''?n:null)).filter((n)=>n):[],
                 })
             }else if(couponType === 'discount')
             {

                let valid = await validateDiscountType();
                if(!valid) { 
                    return
                }

                 data = Object.assign({
                    "name": couponName,
                    "code": (couponCode).toUpperCase(),
                    "couponType": couponType,
                    "usageLimitPerCustomer": useLimitPerCoustomer,
                    "status": status,
                    "valid_from": startDate,
                    "valid_till": endDate,
                    "users": userSelectValue?.length?(userSelectValue?.map((n)=>n !== ''?n:null)).filter((n)=>n):[],
                    "discount_type": discountType,
                    "minimumSpend": minSpend,
                    "maximumSpend": maxSpend,
                    "discount": discount,
                    "categories": selectedCategory?.length?(selectedCategory?.map((n)=>n !== ''?n:null)).filter((n)=>n):[],
                    "templates": selectedTemplate?.length?(selectedTemplate?.map((n)=>n !== ''?n:null)).filter((n)=>n):[],
                    "products": selectedProduct?.length?(selectedProduct?.map((n)=>n !== ''?n:null)).filter((n)=>n):[],
                    "bundles": selectedBundle?.length?(selectedBundle?.map((n)=>n !== ''?n:null)).filter((n)=>n):[],
                 })
             }
            await addCouponDetails(data).then(async response => {
                if (response.success) {
                    await getListData(true, 'Successfully coupon create!')
                } else {
                    setLoader(false);
                    toastComp(addToast, 'error', response.message);
                }
            })
        } else {
            setLoader(false);
            document.getElementById('couponType').classList.add("err")
            toastComp(addToast, 'error', 'Please Select Coupon Type');
        }
    }
    const editCategory = async (data) => {
        // console.log("edited data==>",data)
        setOpen(true);
        setEditData(true);
        setEditId(data?._id);
        await setCouponName(data?.name ? data?.name : '')
        await setCouponCode(data?.code ? data?.code : '')
        await setDiscountType(data?.discount_type ? data?.discount_type : '')
        await setDiscount(data?.discount ? data?.discount : 0)
        // await setMaxDiscount(data?.max_discount?data?.max_discount:'')
        await setStatus(data?.status ? data?.status : '')
        await setStartDate(data?.valid_from ? dateFormatConverter2(data?.valid_from) : '')
        await setEndDate(data?.valid_till ? dateFormatConverter2(data?.valid_till) : '')
        // await setUseLimitPerCoupon(data?.usageLimitPerCoupon?data?.usageLimitPerCoupon:'')
        await setLimitPerCoustomer(data?.usageLimitPerCustomer ? data?.usageLimitPerCustomer : 0)
        await setMinSpend(data?.minimumSpend ? data?.minimumSpend : 0)
        await setMaxSpend(data?.maximumSpend ? data?.maximumSpend : 0)
        await setSelectedBundle(data?.bundles ? data?.bundles : [])
        await setSelectedUser(data?.users ? data?.users : [])
        await setCouponType(data?.couponType ? data?.couponType : '')
        await setSelectedTemplate(data?.templates ? data?.templates : [])
        await setSelectedCategory(data?.categories ? data?.categories : [])
        await setSelectedProduct(data?.products ? data?.products : []);
        await setwalletPoint(data?.walletPoint ? data?.walletPoint : 0)
    }
    const deleteCategory = async (id, index) => {
        setLoader(true);
        await deleteCoupon(id).then(async response => {
            if (response.success || response.status) {
                await getListData(false, '');
                // productList[0]?.[paginationIndex].splice(index, 1);
                toastComp(addToast, 'success', 'Coupon deleted Successfully!');
            } else {
                setLoader(false);
                toastComp(addToast, 'error', 'Coupon id incorrect!');
            }
        })
    };
    const popup = (id, index) => {
        confirmAlert({
            title: 'Confirm Action',
            message: 'Are you sure you want to delete this card?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteCategory(id, index)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    useEffect(async () => {
        await getListData(false, '');
        await getUserlist(1, 2, false).then(async response => {
            if (response.success) {
                await getUserlist(1, parseInt(response.totalUser), false).then(async response => {
                    if (response.success) {
                        await setListUser(await explitMultSelect(response.data));
                    }
                })
            }
        });
        await getTemplateList('template/templateslist').then(async response => {
            if (response.success) {
                await setlistTemplates(await normalMulti(response.template));
            }
        })
        await getCategoryList('category').then(async response => {
            if (response.success) {
                await setListCategoryOption(await normalMulti(await mergeParentChildrenCategory(response.data.categories)))
            }
        });
        await getProductList('product-type/producttypelist').then(async response => {
            if (response.status) {
                await selectProductOption(await normalMulti(response.data))
            }
        });
        await getPricingPlanDetails('pricing/bundlelist').then(async response => {
            if (response.status) {
                await selectedBundleOption(await normalMulti(response.data));
            }
        })
    }, [])


    const handleFilterChange = (filter) => {
        const currentDate = new Date(); // Current date
        console.log("Current Date:", currentDate);
    
        const filteredProducts = originalProductList.filter((data) => {
            const validTillDate = new Date(data.valid_till);
    
            // Check for invalid date parsing
            if (isNaN(validTillDate)) {
                console.error(`Invalid date format for ${data.valid_till}`);
                return false; // Skip invalid date entries
            }
    
            console.log("Valid Till Date:", validTillDate);
    
            // Compare dates directly
            if (filter === 'valid') {
                return validTillDate >= currentDate; 
            }
            
            if (filter === 'notValid') {
                return validTillDate < currentDate; 
            }
    
            return true; // For 'all', return all items
        });
    
        console.log("Filtered Products:", filteredProducts);
        setProductList(filteredProducts);
    };
    
    
    
    
    
    
    // Example usage in your component
    // Assume 'selectedFilter' is the state that tracks the current filter selection
    const handleSelectChange = (e) => {
        const selectedFilter = e.target.value;
        handleFilterChange(selectedFilter);
    };
    
    // UI element for selecting filter

    

    return (
        <>
            <div className="nk-content ">
                <div className="container-fluid">
                    <div className="nk-content-inner">
                        <div className="nk-content-body">
                            <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-between">
                                    <div className="nk-block-head-content">
                                        <h3 className="nk-block-title page-title">Coupon List</h3>
                                        <div className="nk-block-des text-soft">
                                            <p>You have total {countProduct === 1 ? countProduct + ' coupon' : countProduct + ' coupons'}.</p>
                                        </div>
                                    </div>
                                    <div className="toggle-wrap nk-block-tools-toggle">
                                        <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                        <div className="toggle-expand-content" data-content="pageMenu">
                                            <ul className="nk-block-tools g-3">
                                                <li><a className="btn btn-white btn-outline-light" onClick={(e) => { setOpen(true) }}><em className="icon ni ni-plus"></em><span>Generate Coupon</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="nk-block-head-content">
                    <label htmlFor="">
                      Sort By
                     
                    </label>
                    <select onChange={handleSelectChange}>
        <option value="all">All</option>
        <option value="valid">Valid</option>
        <option value="notValid">Not Valid</option>
    </select>
                  </div>
                                </div>
                            </div>
                            <div className="nk-block">
                                <div className="card card-bordered card-stretch">
                                    <div className="card-inner-group">
                                        <div className="card-inner position-relative card-tools-toggle">
                                            <div className={toggleSearchBar ? 'card-search search-wrap active' : 'card-search search-wrap active'} data-search="search">
                                                <div className="card-body">
                                                    <div className="search-content">
                                                        <input type="text" className="form-control border-transparent form-focus-none" value={search} onChange={(e) => handleSerach(e.target.value)} placeholder="Search by coupon code..." style={{ paddingLeft: '0px', textTransform: 'uppercase' }} />
                                                        <button className="search-submit btn btn-icon"><em className="icon ni ni-search"></em></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-inner p-0">
                                            <div className="nk-tb-list nk-tb-ulist">
                                                <div className="nk-tb-item nk-tb-head">
                                                    <div className="nk-tb-col nk-tb-col-check" style={{ width: '0px', padding: '10px' }}>
                                                    </div>
                                                    <div className="nk-tb-col"><span className="sub-text">Coupon Code</span></div>
                                                    <div className="nk-tb-col"><span className="sub-text">Coupon Type</span></div>
                                                    <div className="nk-tb-col tb-col-mb"><span className="sub-text">Status</span></div>
                                                    <div className="nk-tb-col tb-col-mb"><span className="sub-text">Discount Type</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Discount</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Min Spend</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Lim Per User</span></div>
                                                    {/* <div className="nk-tb-col tb-col-md"><span className="sub-text">Lim Per Coupon</span></div> */}
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">Start Date</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span className="sub-text">End Date</span></div>
                                                    <div className="nk-tb-col nk-tb-col-tools text-right"><span className="sub-text">More</span></div>
                                                </div>
                                                {
                                                    loader ?
                                                        <LoadingOverlay className='loaderDiv'>
                                                            <Loader loading={loader} />
                                                        </LoadingOverlay>
                                                        : null
                                                }
                                                {
                                                    productList.length > 0 ?
                                                        productList.map((data, index) =>
                                                            <div className="nk-tb-item" id={data?._id} key={index}>
                                                                <div className="nk-tb-col nk-tb-col-check" style={{ width: '0px', padding: '10px' }}>
                                                                </div>
                                                                <div className="nk-tb-col">
                                                                    <div className="user-info">
                                                                        <span className="tb-lead">{data?.code}<span className="dot dot-success d-md-none ml-1"></span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-mb">
                                                                    <span className="tb-lead">{data?.couponType === 'discount' ? 'Discount Type' : data.couponType == 'wallet' ? 'Wallet Type(Credit Points ' + data?.walletPoint + ')' : '---'}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-mb">
                                                                    <span className="tb-lead">{data?.status == '' ? 'Deactive' : 'Active'}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-mb">
                                                                    <span className="tb-lead">{data?.discount_type?data.discount_type:'---'}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.discount?data.discount:'0'}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.minimumSpend?data?.minimumSpend:0}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{data?.usageLimitPerCustomer}</span>
                                                                </div>

                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{format(new Date((data?.valid_from)), 'Do MMM YYYY')}</span>
                                                                </div>
                                                                <div className="nk-tb-col tb-col-md">
                                                                    <span className="tb-lead">{format(new Date((data?.valid_till)), 'Do MMM YYYY')}</span>
                                                                </div>
                                                                <div className="nk-tb-col nk-tb-col-tools">
                                                                    <ul className="nk-tb-actions gx-1">
                                                                        <li>
                                                                            <div className="drodown">
                                                                                <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                                    <ul className="link-list-opt no-bdr">
                                                                                        <li><a onClick={() => editCategory(data)}><em className="icon ni ni-edit"></em><span>Edit</span></a></li>
                                                                                        <li><a onClick={() => popup(data._id, index)}><em className="icon ni ni-trash"></em><span>Delete</span></a></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        <div style={{ width: '100%', position: 'absolute', left: '0px', backgroundColor: '#fff' }}>
                                                            <h1 style={{ width: '100%', textAlign: 'center', fontSize: '16px', lineHeight: '61px', color: '#eb5757', fontWeight: '600' }}>*Not Found</h1>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} closeOnOverlayClick={false}>
                <div className='container-fluid' style={{ width: '100%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-md-12 col-12'>
                            <h3 style={{ textAlign: 'left' }}>{editData ? 'Edit Coupon' : 'Generate Coupon'}</h3>
                        </div>
                        <div className='col-md-12 col-12' style={{ marginTop: "15px" }}>
                            <div className='row justify-content-center'>
                                <div className="col-lg-12 col-12">
                                    <ul className="data-list is-compact" style={{ display: 'flex' }}>
                                        <li className="data-item" style={{ width: '50%', border: 'none' }} >
                                            <div className="data-col" id="couponName">
                                                <div className="data-label">Coupon Name*</div>
                                                <input
                                                    type="text"
                                                    className="data-value ip-field x12"
                                                    value={couponName}
                                                    onChange={(e) => setCouponName(e.target.value)}
                                                    name='couponName'
                                                    placeholder="Coupon name"
                                                    required />
                                            </div>
                                        </li>
                                        <li className="data-item" style={{ width: '50%' }}>
                                            <div className="data-col" id="couponCode">
                                                <div className="data-label">Coupon Code*</div>
                                                <input
                                                    type="text"
                                                    className="data-value ip-field x12"
                                                    value={couponCode}
                                                    onChange={(e) => setCouponCode(e.target.value)}
                                                    name='couponCode'
                                                    style={{ textTransform: 'uppercase' }}
                                                    placeholder="Coupon code"
                                                    required />
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="data-list is-compact" style={{ display: 'flex' }}>
                                        <li className="data-item" style={{ width: '50%', border: 'none' }}>
                                            <div className="data-col" id="couponType">
                                                <div className="data-label">Coupon Type*</div>
                                                <select
                                                    className="data-value ip-field x12"
                                                    value={couponType}
                                                    onChange={(e) => setCouponType(e.target.value)}
                                                    name='couponType'
                                                    required>
                                                    <option value=''>Select</option>
                                                    <option value='wallet'>Wallet Type</option>
                                                    <option value='discount'>Dixcount Type</option>
                                                </select>
                                            </div>
                                        </li>
                                        {
                                            couponType === 'wallet' ?
                                                <li className="data-item" style={{ width: '50%', border: 'none' }}>
                                                    <div className="data-col" id="walletPoint">
                                                        <div className="data-label">Wallet Point Addon*</div>
                                                        <input
                                                            type="number"
                                                            disabled={couponType === 'wallet' ? false : true}
                                                            className="data-value ip-field x12"
                                                            value={walletPoint}
                                                            onChange={(e) => setwalletPoint(e.target.value)}
                                                            name='walletPoint'
                                                            placeholder="0"
                                                            required />
                                                    </div>
                                                </li>
                                            : null
                                        }
                                    </ul>
                                    <ul className="data-list is-compact" style={{ display: 'flex' }}>
                                        <li className="data-item" style={{ width: '50%' }}>
                                            <div className="data-col" id="useLimitPerCoustomer">
                                                <div className="data-label">Usage Limit Per Customer*</div>
                                                <input
                                                    type="number"
                                                    className="data-value ip-field x12"
                                                    value={useLimitPerCoustomer}
                                                    onChange={(e) => setLimitPerCoustomer(e.target.value)}
                                                    name='usageLimitPerCustomer'
                                                    placeholder="Usage Limit Per Customer"
                                                    required />
                                            </div>
                                        </li>
                                        <li className="data-item" style={{ width: '50%' }}>
                                            <div className="data-col" id="status">
                                                <div className="data-label">Status*</div>
                                                <select
                                                    className="data-value ip-field x12"
                                                    value={status}
                                                    onChange={(e) => setStatus(e.target.value)}
                                                    name='status'
                                                    required style={{ height: '30px' }}>
                                                    <option value=''>Select</option>
                                                    <option value='true'>Active</option>
                                                    <option value='false'>Deactive</option>
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="data-list is-compact" style={{ display: 'flex' }}>
                                        <li className="data-item" style={{ width: '50%', border: 'none' }}>
                                            <div className="data-col" id="startDate">
                                                <div className="data-label">Start Date*</div>
                                                <input
                                                    type="date"
                                                    className="data-value ip-field x12"
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                    min={minDate}
                                                    name='startDate'
                                                    placeholder=""
                                                    required />
                                            </div>
                                        </li>
                                        <li className="data-item" style={{ width: '50%' }}>
                                            <div className="data-col" id="endDate">
                                                <div className="data-label">End Date*</div>
                                                <input
                                                    type="date"
                                                    className="data-value ip-field x12"
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                    min={minDate}
                                                    name='endDate'
                                                    placeholder=""
                                                    required />
                                            </div>
                                        </li>
                                    </ul>
                                    {
                                        couponType === 'discount' ?
                                            <ul className="data-list is-compact" style={{ display: 'flex' }}>
                                                <li className="data-item" style={{ width: '50%', border: 'none' }}>
                                                    <div className="data-col" id="discountType">
                                                        <div className="data-label">Discount Type*</div>
                                                        <select
                                                            className="data-value ip-field x12"
                                                            value={discountType}
                                                            onChange={(e) => setDiscountType(e.target.value)}
                                                            name='discountType'
                                                            required>
                                                            <option value=''>Select</option>
                                                            <option value='percent'>Percentage</option>
                                                            <option value='fixed'>Fixed</option>
                                                        </select>
                                                    </div>
                                                </li>
                                                <li className="data-item" style={{ width: '50%' }}>
                                                    <div className="data-col" id="discount">
                                                        <div className="data-label">Discount*</div>
                                                        <input
                                                            type="number"
                                                            className="data-value ip-field x12"
                                                            value={discount}
                                                            onChange={(e) => setDiscount(e.target.value)}
                                                            name='discount'
                                                            placeholder="Discount"
                                                            required />
                                                    </div>
                                                </li>
                                            </ul>
                                        : null
                                    }
                                    {
                                        couponType === 'wallet' ?
                                            <ul className="data-list is-compact" style={{ display: 'flex' }}>
                                                {
                                                    listUserDetails?.length ?
                                                        <li className="data-item" style={{ width: '50%' }}>
                                                            <div className="data-col dropSet">
                                                                <div className="data-label">Selected Users</div>
                                                                <MultiSelect3
                                                                    className='multiOptionSelect'
                                                                    placeholder='Select'
                                                                    defaultValue={userSelectValue?.length ? userSelectValue?.filter((n) => n) : []}
                                                                    onChange={(e) => { setSelectedUser(e.split(',')) }}
                                                                    options={listUserDetails}
                                                                    style={{ width: '100%' }}
                                                                />
                                                            </div>
                                                        </li>
                                                    : null
                                                }
                                            </ul>
                                        : null
                                    }
                                    <hr />
                                    {
                                        couponType === 'discount' ?
                                            <ul className="data-list is-compact" style={{ display: 'flex' }}>
                                                <li className="data-item" style={{ width: '50%', border: 'none' }}>
                                                    <div className="data-col"  id="minSpend">
                                                        <div className="data-label">Minimum Spend*</div>
                                                        <input
                                                            type="number"
                                                            className="data-value ip-field x12"
                                                            value={minSpend}
                                                            onChange={(e) => setMinSpend(e.target.value)}
                                                            name='minimumSpend'
                                                            placeholder="Minimum Spend"
                                                            required />
                                                    </div>
                                                </li>
                                                <li className="data-item" style={{ width: '50%' }}>
                                                    <div className="data-col" id="maxSpend">
                                                        <div className="data-label">Maximum Spend*</div>
                                                        <input
                                                            type="number"
                                                            className="data-value ip-field x12"
                                                            value={maxSpend}
                                                            onChange={(e) => setMaxSpend(e.target.value)}
                                                            name='maxSpend'
                                                            placeholder="Maximum Spend"
                                                            required />
                                                    </div>
                                                </li>
                                            </ul>
                                        : null
                                    }
                                    {
                                        couponType === 'discount' ?
                                            <ul className="data-list is-compact" style={{ display: 'flex' }}>
                                                {
                                                    listUserDetails?.length ?
                                                        <li className="data-item" style={{ width: '50%' }}>
                                                            <div className="data-col dropSet">
                                                                <div className="data-label">Selected Users</div>
                                                                <MultiSelect3
                                                                    className='multiOptionSelect'
                                                                    placeholder='Select'
                                                                    defaultValue={userSelectValue?.length ? userSelectValue?.filter((n) => n) : []}
                                                                    onChange={(e) => { setSelectedUser(e.split(',')) }}
                                                                    options={listUserDetails}
                                                                    style={{ width: '100%' }}
                                                                />
                                                            </div>
                                                        </li>
                                                    : null
                                                }
                                                {
                                                    listTemplatesOption?.length ?
                                                        <li className="data-item" style={{ width: '50%' }}>
                                                            <div className="data-col">
                                                                <div className="data-label">Selected Templates</div>
                                                                <MultiSelect3
                                                                    className='multiOptionSelect'
                                                                    defaultValue={selectedTemplate?.length ? selectedTemplate?.filter((n) => n) : []}
                                                                    onChange={(e) => { setSelectedTemplate(e.split(',')) }}
                                                                    options={listTemplatesOption}
                                                                />
                                                            </div>
                                                        </li>
                                                    : null
                                                }
                                            </ul>
                                        : null
                                    }
                                    {
                                        couponType === 'discount' ?
                                            <ul className="data-list is-compact" style={{ display: 'flex' }}>
                                                {
                                                    listCategoryOption?.length ?
                                                        <li className="data-item" style={{ width: '50%' }}>
                                                            <div className="data-col">
                                                                <div className="data-label">Selected Categories</div>
                                                                <MultiSelect3
                                                                    className='multiOptionSelect'
                                                                    defaultValue={selectedCategory?.length ? selectedCategory?.filter((n) => n) : []}
                                                                    onChange={(e) => { setSelectedCategory(e.split(',')) }}
                                                                    options={listCategoryOption}
                                                                />
                                                            </div>
                                                        </li>
                                                    : null
                                                }
                                                {
                                                    listOptionProduct?.length ?
                                                        <li className="data-item" style={{ width: '50%' }}>
                                                            <div className="data-col">
                                                                <div className="data-label">Selected Products</div>
                                                                <MultiSelect3
                                                                    className='multiOptionSelect'
                                                                    defaultValue={selectedProduct?.length ? selectedProduct?.filter((n) => n) : []}
                                                                    onChange={(e) => { setSelectedProduct(e.split(',')) }}
                                                                    options={listOptionProduct}
                                                                />
                                                            </div>
                                                        </li>
                                                    : null
                                                }
                                            </ul>
                                        : null
                                    }
                                    {
                                        couponType === 'discount' ?
                                            <ul className="data-list is-compact" style={{ display: 'flex' }}>
                                                {
                                                    listOptionBundle?.length ?
                                                        <li className="data-item" style={{ width: '50%' }}>
                                                            <div className="data-col">
                                                                <div className="data-label">Selected Bundle</div>
                                                                <MultiSelect3
                                                                    className='multiOptionSelect'
                                                                    defaultValue={selectedBundle?.length ? selectedBundle?.filter((n) => n) : []}
                                                                    onChange={(e) => { setSelectedBundle(e.split(',')) }}
                                                                    options={listOptionBundle}
                                                                />
                                                            </div>
                                                        </li>
                                                    : null
                                                }
                                            </ul>
                                        : null
                                    }
                                </div>
                                <div className="col-lg-12 col-12">
                                    <div className="my-3 text-center">
                                        <div className="d-flex justify-content-center">
                                            <button
                                                className="btn btn-md btn-primary"
                                                onClick={() => { editData ? handleEditProduct(editId) : handleAddProdcut() }}
                                            >
                                                {editData ? "Edit Coupon" : "Generate Coupon"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default CouponList;