import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { deletedTemplateApi } from "../Action/DeleteAction";
import { editTemplateApi } from "../Action/PutAction";
import {
  getTemplateList,
  getCategoryList,
  getProductList,
  getSortTemplateApi,
} from "../Action/GetAction";
import { addTemplateApi, searchTemplateApi } from "../Action/PostAction";
import {
  matrixify1,
  dateFormatConverter,
  multiSelectTemp,
  matrixify2,
} from "../Logic/Logic";
import {
  BASE_IMAGE_URL,
  deleteMethod,
  getMethod,
  postMethod,
  putMethod,
} from "../Utils/ApiServices";
import { useToasts } from "react-toast-notifications";
import { toastComp } from "../Sections/Toast";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Modal } from "react-responsive-modal";
import { MultiSelect } from "react-multi-select-component";
import { confirmAlert } from "react-confirm-alert";
import MultiCheckboxSelect from "react-multi-checkbox-select";
import { format } from "fecha";
import Parser from "html-react-parser";
import "react-multi-checkbox-select/lib/multi-checkbox-select.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-overlay-loader/styles.css";
import "react-responsive-modal/styles.css";
import MyTable from "./datatable.js";

const GroupCard = (props) => {
  const { addToast } = useToasts();

  const [editData, setEditData] = useState(false);
  const [editId, setEditId] = useState(false);
  const [allTemplateList, setAllTemplateList] = useState([]);
  const [groupCards, setGroupCards] = useState([]);
  const [allData, setallData] = useState([]);

  const [paginationIndex, setPaginationIndex] = useState(1);
  const [label, setLabel] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const multipleParse = async (array) => {
    const data = array?.map((items) => ({
      value: items?._id,
      label: Parser(
        `<img src='${
          BASE_IMAGE_URL + items?.front
        }' style='width:50px;height:70px;padding:5px;margin:0px 5px;'/>` +
          (items?.name).toUpperCase()
      ),
    }));
    return data;
  };
  const getListData = async () => {
    await getTemplateList("template/templateslist?page=0&limit=1000").then(
      async (response) => {
        if (response.success) {
          setAllTemplateList(await multipleParse(response?.template));
        }
      }
    );
  };
  const getGroupCards = async () => {
    await getMethod("group-card").then((response) => {
      if (response.success) {
        setGroupCards(response.data);
        setallData(response.data);
        setLoader(false);
      } else {
        setGroupCards([]);
        setLoader(false);
      }
    });
  };
  const onCloseModal = (props) => {
    setOpen(false);
    setEditData(false);
    setEditId(false);
    setLabel("");
    setTitle("");
    setDescription("");
    setSelectedTemplates([]);
  };
  const editCategory = async (data) => {
    setOpen(true);
    setEditData(true);
    setEditId(data?._id);
    setLabel(data?.label ? data?.label : "");
    setTitle(data?.heading ? data?.heading : "");
    setDescription(data?.description ? data?.description : "");
    setSelectedTemplates(data?.templateShow ? data?.templateShow : []);
  };
  const deleteCategory = async (id, index) => {
    setLoader(true);
    await deleteMethod("group-card/delete?id=" + id).then((response) => {
      if (response.success) {
        getGroupCards();
        toastComp(addToast, "success", response.message);
      } else {
        setLoader(false);
        toastComp(addToast, "error", response.message);
      }
    });
  };

  const popup = (id, index) => {
    confirmAlert({
      title: "Confirm Action",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCategory(id, index),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  const handleSubmitNow = async (edit) => {
    setLoader(true);
    if (
      label != "" &&
      title != "" &&
      description != "" &&
      selectedTemplates?.length
    ) {
      const data = {
        label: label,
        heading: title,
        description: description,
        templateShow: selectedTemplates,
      };
      if (edit) {
        await putMethod("group-card/edit?id=" + editId, data).then(
          (response) => {
            if (response.success) {
              setLoader(false);
              toastComp(addToast, "success", response.message);
              getGroupCards();
              onCloseModal();
            } else {
              setLoader(false);
              toastComp(addToast, "error", response.message);
            }
          }
        );
      } else {
        await postMethod("group-card/add", data).then((response) => {
          if (response.success) {
            setLoader(false);
            toastComp(addToast, "success", response.message);
            getGroupCards();
            onCloseModal();
          } else {
            setLoader(false);
            toastComp(addToast, "error", response.message);
          }
        });
      }
    } else {
      setLoader(false);
      toastComp(addToast, "error", "Please fill thr required fields!");
    }
  };
  useEffect(async () => {
    await getListData(0, false, "");
    await getGroupCards();
  }, []);
  const columns = [
    {
      name: "Label",
      selector: (row) => row.label,
      cell: (row) => row.label,
      sortable: true,
      width: "150px",
    },
    {
      name: "Heading",
      selector: (data) => data?.heading,
      sortable: true,
      width: "240px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      width: "520px",
    },
    {
      name: "Created At",
      selector: (row) => format(new Date(row?.createdAt), "Do MMM YYYY"),
      sortable: true,
      width: "140px",
    },
    {
      name: "More",
      cell: (data, index) => (
        <>
          <div className="nk-tb-col nk-tb-col-tools">
            <ul className="nk-tb-actions gx-1">
              <li>
                <div className="drodown">
                  <a
                    href="#"
                    className="dropdown-toggle btn btn-icon btn-trigger"
                    data-toggle="dropdown"
                  >
                    <em className="icon ni ni-more-h"></em>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <a onClick={() => editCategory(data)}>
                          <em className="icon ni ni-edit"></em>
                          <span>Edit</span>
                        </a>
                      </li>
                      <li>
                        <a onClick={() => popup(data._id, index)}>
                          <em className="icon ni ni-trash"></em>
                          <span>Delete</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Group Cards</h3>
                    <div className="nk-block-des text-soft">
                      <p>
                        You have total{" "}
                        {groupCards?.length === 1
                          ? groupCards?.length + " group card"
                          : groupCards?.length + " group cards"}
                        .
                      </p>
                    </div>
                  </div>
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <a
                      href="#"
                      className="btn btn-icon btn-trigger toggle-expand mr-n1"
                      data-target="pageMenu"
                    >
                      <em className="icon ni ni-menu-alt-r"></em>
                    </a>
                    <div
                      className="toggle-expand-content"
                      data-content="pageMenu"
                    >
                      <ul className="nk-block-tools g-3">
                        <li>
                          <a
                            className="btn btn-white btn-outline-light"
                            onClick={(e) => {
                              setOpen(true);
                            }}
                          >
                            <em className="icon ni ni-plus"></em>
                            <span>Add Group Card</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="nk-block">
                <div className="card card-bordered card-stretch">
                  <div className="card-inner-group">
                    <div className="card-inner p-0">
                      <div className="nk-tb-list nk-tb-ulist">
                        <div className="nk-tb-item nk-tb-head">
                          <div
                            className="nk-tb-col nk-tb-col-check"
                            style={{ width: "0px", padding: "10px" }}
                          ></div>
                          <div className="nk-tb-col">
                            <span className="sub-text">Label</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">Heading</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">Description</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">Created At</span>
                          </div>
                          <div className="nk-tb-col nk-tb-col-tools text-right">
                            <span className="sub-text">More</span>
                          </div>
                        </div>
                        {loader ? (
                          <LoadingOverlay className="loaderDiv">
                            <Loader loading={loader} />
                          </LoadingOverlay>
                        ) : null}
                        {groupCards?.length > 0 ? (
                          groupCards?.map((data, index) =>
                            index < paginationIndex * 5 ? (
                              <div className="nk-tb-item" key={index}>
                                <div
                                  className="nk-tb-col nk-tb-col-check"
                                  style={{ width: "0px", padding: "10px" }}
                                ></div>
                                <div className="nk-tb-col">
                                  <div className="user-info">
                                    <span className="tb-lead">
                                      {data?.label}
                                      <span className="dot dot-success d-md-none ml-1"></span>
                                    </span>
                                  </div>
                                </div>
                                <div className="nk-tb-col tb-col-md">
                                  <span className="tb-lead">
                                    {data?.heading}
                                  </span>
                                </div>
                                <div className="nk-tb-col tb-col-md">
                                  <span className="tb-lead">
                                    {data?.description}
                                  </span>
                                </div>
                                <div className="nk-tb-col tb-col-md">
                                  <span className="tb-lead">
                                    {format(
                                      new Date(data?.createdAt),
                                      "Do MMM YYYY"
                                    )}
                                  </span>
                                </div>
                                <div className="nk-tb-col nk-tb-col-tools">
                                  <ul className="nk-tb-actions gx-1">
                                    <li>
                                      <div className="drodown">
                                        <a
                                          href="#"
                                          className="dropdown-toggle btn btn-icon btn-trigger"
                                          data-toggle="dropdown"
                                        >
                                          <em className="icon ni ni-more-h"></em>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                          <ul className="link-list-opt no-bdr">
                                            <li>
                                              <a
                                                onClick={() =>
                                                  editCategory(data)
                                                }
                                              >
                                                <em className="icon ni ni-edit"></em>
                                                <span>Edit</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  popup(data._id, index)
                                                }
                                              >
                                                <em className="icon ni ni-trash"></em>
                                                <span>Delete</span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ) : null
                          )
                        ) : (
                          <div
                            className="row justify-content-center"
                            style={{
                              width: "100%",
                              margin: "0px",
                              position: "absolute",
                              background: "#fff",
                            }}
                          >
                            <div
                              className="col-md-12 col-12"
                              style={{
                                width: "0px",
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              <span className="lead-text">Nothing Found!</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {Math.round(groupCards?.length / 5) > 1 ? (
                      <div className="card-inner">
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={Math.round(groupCards?.length / 5)}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={async (e) => {
                            setPaginationIndex(e.selected + 1);
                          }}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div> */}
              <MyTable columns={columns} tabledata={allData} />
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={(e) => onCloseModal()}
        closeOnOverlayClick={false}
      >
        <div
          className="container-fluid"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="row justify-content-center">
            <div className="col-md-12 col-12">
              <h3 style={{ textAlign: "left" }}>
                {editData ? "Edit Group Card" : "Add Group Card"}
              </h3>
            </div>
            <div
              className="col-md-12 col-12"
              style={{ marginTop: "15px", border: "solid #cecece 1px" }}
            >
              <div className="row justify-content-center">
                <div className="col-lg-12 col-12">
                  <ul className="data-list is-compact">
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Group Card Label</div>
                        <input
                          type="text"
                          className="data-value ip-field"
                          value={label}
                          name="templateName"
                          placeholder="Enter Label"
                          onChange={(e) => {
                            setLabel(e.target.value);
                          }}
                          required
                        />
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Group Card Title</div>
                        <input
                          type="text"
                          className="data-value ip-field"
                          value={title}
                          name="heading"
                          placeholder="Enter Heading"
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                          required
                        />
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Description</div>
                        <textarea
                          type="text"
                          className="data-value ip-field"
                          value={description}
                          name="descriptio"
                          placeholder="Write description"
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                          required
                        ></textarea>
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Add Template Image</div>
                        <MultiCheckboxSelect
                          name={"multi_checkboxes_select"}
                          onChange={(
                            name,
                            isChecked,
                            selectedValue,
                            selectedValues
                          ) => {
                            setSelectedTemplates(selectedValues);
                          }}
                          values={selectedTemplates}
                          defaultText={"Select Templates"}
                          options={
                            allTemplateList?.length ? allTemplateList : []
                          }
                        />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="my-3 text-center">
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-md btn-primary"
                        onClick={() => handleSubmitNow(editData ? true : false)}
                      >
                        {editData ? "Edit Template" : "Add Template"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default GroupCard;
