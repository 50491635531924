import { postMethod, putMethod, putMethod2 } from "../Utils/ApiServices";

const editCategoryApi = async (data, params) => {
  const returnFlag = await putMethod("category/category", data, params);
  return returnFlag;
};

const editProductApi = async (data, params) => {
  const returnFlag = await putMethod("product-type/producttype", data, params);
  return returnFlag;
};

const editTemplateApi = async (data, params) => {
  const returnFlag = await putMethod("template/edit", data, params);
  return returnFlag;
};

const editDemoDetails = async (data, params) => {
  const returnFlag = await putMethod("admin/update/democard", data, params);
  return returnFlag;
};

const addmemberrole = async (data, params) => {
  const returnFlag = await putMethod(
    "/user/members/" + data.id + "?ownerId=" + data.owner,
    { role: data.role }
  );
  return returnFlag;
};

const editPricingPlan = async (data, params) => {
  const returnFlag = await putMethod("pricing/bundle", data, params);
  return returnFlag;
};
const editPlans = async (data, id) => {
  const returnFlag = await putMethod2("plans/" + id, data);
  return returnFlag;
};

const editCoupon = async (data, params) => {
  const returnFlag = await putMethod("coupon/editcoupon", data, params);
  return returnFlag;
};

const adminUpdatePassword = async (data, params) => {
  const returnFlag = await putMethod("user/change-password", data, params);
  return returnFlag;
};

const updateUserDetails = async (data, params) => {
  const returnFlag = await putMethod("admin/update/user", data, params);
  return returnFlag;
};

const updateUserOrderDetails = async (data, params) => {
  const returnFlag = await putMethod("admin/update/order", data, params);
  return returnFlag;
};

const updatecardsCover = async (data, params) => {
  const returnFlag = await putMethod("/user/updateTemplateId", data);
  return returnFlag;
};
const editShowCaseDetails = async (data, params) => {
  const returnFlag = await putMethod("showCase/" + params?.cardId, data, {});
  return returnFlag;
};
const uploadPhoto = async (params, data) => {
  const returnFlag = await postMethod(
    "component/uploadImage?canvasId=" + params,
    data
  );
  return returnFlag;
};
const saveColor = async (data) => {
  const returnFlag = await postMethod("admin/saveColor", data);
  return returnFlag;
};
const saveFont = async (data) => {
  const returnFlag = await postMethod("admin/saveFont", data);
  return returnFlag;
};
const putEditCardComponent = async (data, params) => {
  const returnFlag = await putMethod(
    "admin/update/component?componentId=" + params,
    data
  );
  return returnFlag;
};
export {
  saveColor,
  saveFont,
  editCategoryApi,
  uploadPhoto,
  editProductApi,
  putEditCardComponent,
  editPlans,
  editTemplateApi,
  editDemoDetails,
  addmemberrole,
  editPricingPlan,
  editCoupon,
  adminUpdatePassword,
  updateUserDetails,
  updateUserOrderDetails,
  editShowCaseDetails,
  updatecardsCover,
};
