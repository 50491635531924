import React from 'react'
import { Link } from "react-router-dom";

function Errorpage() {
    return (
        <div style={{ textAlign: "center", width: "100%", marginTop: "40vh",fontSize:"25px" }}>
            404 Page Not Found ! <br />
            <Link to="/" style={{fontSize:"16px"}}>Back to login</Link>
        </div>
    )
}

export default Errorpage
