import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { deleteUserAccount } from "../Action/DeleteAction";
import { editProductApi } from "../Action/PutAction";
import { getUserlist, getUserlistSearch } from "../Action/GetAction";
import { addProductApi } from "../Action/PostAction";
import { dateFormatConverter, matrixify1 } from "../Logic/Logic";
import { useToasts } from "react-toast-notifications";
import { toastComp } from "../Sections/Toast";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Modal } from "react-responsive-modal";
import { Link, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-overlay-loader/styles.css";
import "react-responsive-modal/styles.css";
import { BASE_IMAGE_URL } from "../Utils/ApiServices";
import { ExportToExcel } from "../tools/ExportToExcel";
import { format } from "fecha";
import { DateRangePicker } from "react-date-range";
import { addDays, subDays } from "date-fns";
import MyTable from "./datatable.js";

const UserList = (props) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [toggleSearchBar, setToggleSearchBar] = useState(true);
  const [productList, setProductList] = useState([]);
  const [allData, setallData] = useState([]);
  const [countProduct, setCountProduct] = useState(0);
  const [paginationIndex, setPageIndex] = useState(0);
  const [currentpage, SetCurrentpage] = useState(1);
  const [search, setSearch] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchPage, setSearchPage] = useState();
  const [searchsub, setSearchsub] = useState("");
  const [searchst, setSearchst] = useState("");
  const [filterToggle, setFilterToggle] = useState(false);
  const [pageChange, setPageNow] = useState(1);
  const [stateFilters, setDateFilters] = useState([
    {
      startDate: subDays(new Date(), 4),
      endDate: addDays(new Date(), 2),
      key: "selection",
      //             startDate: 2022-02-12
      // endDate: 2022-02-19
    },
  ]);
  const [selectdate, setselectdate] = useState({});
  const filtersDate = async (data) => {
    var passData = false;
    if (data) {
      setDateFilters(data);
      passData = {
        startDate: JSON.stringify(new Date(data[0]?.startDate)).slice(1, 11),
        endDate: JSON.stringify(new Date(data[0]?.endDate)).slice(1, 11),
      };
    }
    setselectdate(passData);
    handleSerach(1, 10, search, searchsub, searchst, passData);
  };

  const getListData = async (page, limit) => {
    await getUserlist(page, limit, false).then(async (response) => {
      {
        if (response.success) {
          setLoader(false);
          setProductList([response.data]);
          setCountProduct(response.total);
          setPageIndex(Math.ceil(response.total / limit));
        }
      }
    });
  };

  const handlePageClick = async (e) => {
    const selectedPage = parseInt(e.selected + 1);
    SetCurrentpage(selectedPage);
    setLoader(true);
    if (search && searchsub && searchst) {
      await handleSerach(
        selectedPage,
        10,
        search,
        searchsub,
        searchst,
        selectdate
      );
    } else {
      await getListData(selectedPage, 10);
    }
  };
  const handleSubmitSearch = async (e) => {
    e.preventDefault();
    const { search } = e.target.elements;
    setSearchPage(0);
    handleSerach(
      currentpage,
      100000,
      search.value,
      searchsub,
      searchst,
      selectdate
    );
  };
  const handleSerach = async (page, limit, value, status, isSocial, data) => {
    setLoader(true);
    try {
      const response = await getUserlistSearch(
        page,
        limit,
        value.toLowerCase(),
        status,
        isSocial,
        data
      );
      if (response && response.success) {
        setLoader(false);
        setallData(response?.data);
        setProductList([response.data]);
        setCountProduct(response.total);
        setPageIndex(Math.ceil(response.total / limit));
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const deleteAccountPermanent = async (id, index) => {
    setLoader(true);
    await deleteUserAccount(id).then((response) => {
      if (response.success || response.status) {
        setLoader(false);
        productList[0]?.splice(index, 1);
        toastComp(addToast, "success", response.message);
      } else {
        setLoader(false);
        toastComp(addToast, "error", "User id incorrect!");
      }
    });
  };
  const popup = (id, index) => {
    confirmAlert({
      title: "Confirm Action",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteAccountPermanent(id, index),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  const dataAllUser = async (page, lim) => {
    setExcelData([]);
    await getUserlist(page, lim, true).then(async (response) => {
      {
        if (response.success) {
          setallData(response?.data);
          let newData = response.data?.map((items, index) => ({
            S_No: index + 1,
            userId: items?._id ? items?._id : "",
            FirstName: items?.firstName ? items?.firstName : "",
            LastName: items?.lastName ? items?.lastName : "",
            Email: items?.email ? items?.email : "",
            Profile: items?.profilePicture
              ? BASE_IMAGE_URL + items?.profilePicture
              : "",
            Provider: items?.provider ? items?.provider : "",
            Verified: items?.verified ? items?.verified : false,
            Active: items?.active ? items?.active : false,
            Dob: items?.dob ? items?.dob : "",
            Wallet: items?.wallet ? items?.wallet : 0,
            Role: items?.role ? items?.role : "",
            NewsletterSubscribe: items?.newsletterSubscribe
              ? items?.newsletterSubscribe
              : false,
            TermsAndCondition: items?.termsAndCondition
              ? items?.termsAndCondition
              : false,
            CouponUsed: items?.couponUsed?.length
              ? (items?.couponUsed).map((n) => n?.name).join(", ")
              : "",
            ActivePlan: items?.userActiveBundleId
              ? items?.userActiveBundleId?.bundleId
              : "",
            CardCreated: items?.createdCards ? items?.createdCards : 0,
            CardReceived: items?.receivedCards ? items?.receivedCards : 0,
            CardSigned: items?.signedCards ? items?.signedCards : 0,
          }));

          setExcelData(newData);
        }
      }
    });
  };
  var divisor = (countProduct / 10) >> 0;
  var remainder = countProduct % 10;
  useEffect(async () => {
    await getListData(1, 10);
    await dataAllUser(1, 100000);
  }, []);
  const columns = [
    {
      name: "Name",
      //   selector: (row) => row.email,
      cell: (data) => (
        <>
          <div className="nk-tb-col nk-tb-col-check">
            <div
              className="custom-control custom-control-sm custom-checkbox notext"
              style={{ paddingLeft: "0px", height: "45px" }}
            >
              <div className="user-avatar bg-success-dim">
                {data?.profilePicture ? (
                  <img src={BASE_IMAGE_URL + data?.profilePicture} />
                ) : (
                  <span style={{ textTransform: "uppercase" }}>
                    {data?.firstName && data?.lastName
                      ? data?.firstName[0] + data?.lastName[0]
                      : "FL"}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="nk-tb-col">
            <div className="user-info">
              <span className="tb-lead" style={{ textTransform: "capitalize" }}>
                <Link to={"user-details?id=" + data?._id}>
                  {data?.firstName +
                    " " +
                    (data?.lastName != undefined ? data?.lastName : "")}
                </Link>
              </span>
            </div>
          </div>
        </>
      ),
      sortable: true,
      width: "220px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => row.email,
      sortable: true,
      width: "250px",
    },
    {
      name: "Status",
      selector: (data) => (data.active ? "Active" : "Deactive"),
      sortable: true,
      width: "120px",
      sortFunction: (rowA, rowB) => {
        // Convert `active` to numbers for sorting (true → 1, false → 0)
        return rowA.active === rowB.active ? 0 : rowA.active ? -1 : 1;
      },
    },
    {
      name: "Verification",
      selector: (data) => (data.verified ? "Yes" : "No"),
      sortable: true,
      width: "140px",
    },
    {
      name: "Is Social",
      selector: (data) => (
        <span className="tb-lead">
          {data?.provider
            ? data?.provider === "google" || data?.provider === "facebook"
              ? "Yes"
              : "No"
            : "No"}
          <small> ({(data?.provider).toUpperCase()})</small>
        </span>
      ),
      sortable: true,
      width: "140px",
      sortFunction: (rowA, rowB) => {
        const providerA = rowA.provider || ""; // Handle cases where provider might be undefined or null
        const providerB = rowB.provider || "";
        return providerA.localeCompare(providerB); // Alphabetical sorting
      },
    },
    {
      name: "Created",
      selector: (data) =>
        data.createdAt
          ? format(new Date(data?.createdAt), "Do MMM YYYY")
          : "---",
      sortable: true,
      width: "130px",
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.createdAt);
        const dateB = new Date(rowB.createdAt);
        return dateA - dateB;
      },
    },
    {
      name: "Last Active",
      selector: (data) =>
        data.updatedAt
          ? format(new Date(data?.updatedAt), "Do MMM YYYY")
          : "---",
      sortable: true,
      width: "130px",
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.updatedAt);
        const dateB = new Date(rowB.updatedAt);
        return dateA - dateB;
      },
    },
    {
      name: "More",
      width: "70px",
      cell: (data, index) => (
        <>
          <div className="nk-tb-col nk-tb-col-tools">
            <ul className="nk-tb-actions gx-1">
              <li>
                <div className="drodown">
                  <a
                    href="#"
                    className="dropdown-toggle btn btn-icon btn-trigger"
                    data-toggle="dropdown"
                  >
                    <em className="icon ni ni-more-h"></em>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <Link to={"/user-details?id=" + data._id}>
                          <em className="icon ni ni-focus"></em>
                          <span>View Details</span>
                        </Link>
                      </li>
                      <li>
                        <a onClick={(e) => popup(data._id, index)}>
                          <em className="icon ni ni-trash"></em>
                          <span>Delete</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">User List</h3>
                    <div className="nk-block-des text-soft">
                      <p>
                        You have total{" "}
                        {countProduct === 1
                          ? countProduct + " user"
                          : countProduct + " users"}
                        .
                      </p>
                    </div>
                  </div>

                  <div className="nk-block-head-content">
                    <div
                      className={filterToggle ? "row ctaActive" : "row cta"}
                      style={{
                        height: "fit-content",
                        position: "fixed",
                        right: "1%",
                        zIndex: 9999,
                        top: "10%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "start",
                        flexWrap: "wrap",
                        paddingBottom: "15px",
                      }}
                    >
                      <button
                        className="togglebff"
                        onClick={(e) =>
                          setFilterToggle(filterToggle ? false : true)
                        }
                        style={{
                          width: "50px",
                          height: "50px",
                          backgroundColor: "#526484",
                          color: "#fff",
                          border: "solid #526484 1px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          outline: "none",
                          textAlign: "center",
                          alignItems: "center",
                          fontSize: "16px",
                        }}
                      >
                        <em className="icon ni ni-filter"></em>
                      </button>
                      <div
                        className="row hideshow"
                        style={{
                          width: "100%",
                          margin: "0px",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "#fff",
                          padding: "15px",
                        }}
                      >
                        <DateRangePicker
                          onChange={(item) => {
                            setPageNow(1);
                            filtersDate([item.selection]);
                            setFilterToggle(filterToggle ? false : true);
                          }}
                          ranges={stateFilters}
                          direction="horizontal"
                          showDateDisplay={true}
                          showPreview={true}
                          editableDateInputs={true}
                          dragSelectionEnabled={true}
                          showSelectionPreview={true}
                          showMonthAndYearPickers={true}
                        />
                        <button
                          onClick={(e) => resetButton()}
                          style={{
                            width: "100%",
                            backgroundColor: "#526484",
                            color: "#fff",
                            border: "solid #526484 1px",
                            outline: "none",
                            textAlign: "center",
                            alignItems: "center",
                            fontSize: "14px",
                            padding: "5px",
                            borderRadius: "25px",
                            marginTop: "15px",
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="toggle-wrap nk-block-tools-toggle">
                    <a
                      href="#"
                      className="btn btn-icon btn-trigger toggle-expand mr-n1"
                      data-target="pageMenu"
                    >
                      <em className="icon ni ni-menu-alt-r"></em>
                    </a>
                    <div
                      className="toggle-expand-content"
                      data-content="pageMenu"
                    >
                      <ul className="nk-block-tools g-3">
                        <li>
                          {" "}
                          <button>Remove Filter</button>
                        </li>
                        <li>
                          {excelData?.length ? (
                            <ExportToExcel
                              apiData={excelData}
                              fileName={"greetpool-user-list-page" + Date.now()}
                            />
                          ) : null}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="card card-bordered card-stretch">
                  <div className="card-inner-group">
                    <div className="card-inner position-relative card-tools-toggle">
                      <div
                        className={
                          toggleSearchBar
                            ? "card-search search-wrap active"
                            : "card-search search-wrap active"
                        }
                        data-search="search"
                      >
                        <div className="card-body">
                          <form
                            className="search-content"
                            onSubmit={(e) => {
                              handleSubmitSearch(e);
                            }}
                            style={{ display: "flex", justifyContent: "start" }}
                          >
                            <input
                              type="text"
                              onChange={(e) => setSearch(e.target.value)}
                              name="search"
                              id="search"
                              className="form-control border-transparent form-focus-none"
                              placeholder="Search by user name/email..."
                              style={{
                                paddingLeft: "0px",
                                textTransform: "none",
                                width: "calc(100% - 400px)",
                                display: "none",
                              }}
                            />
                            <select
                              className="btn btn-white btn-outline-light"
                              style={{
                                width: "150px",
                                height: "100%",
                                outline: "none",
                                border: "solid rgb(82, 100, 132) 1px",
                                backgroundColor: "rgb(82, 100, 132)",
                                color: "#fff",
                                margin: "0px",
                                marginTop: "0px",
                                marginLeft: "10px",
                              }}
                              onChange={(e) => setSearchsub(e.target.value)}
                            >
                              <option value="">Filter By Status</option>
                              <option value={true}>Active</option>
                              <option value={false}>Inactive</option>
                            </select>

                            <select
                              className="btn btn-white btn-outline-light"
                              style={{
                                width: "180px",
                                height: "100%",
                                outline: "none",
                                border: "solid rgb(82, 100, 132) 1px",
                                backgroundColor: "rgb(82, 100, 132)",
                                color: "#fff",
                                margin: "0px",
                                marginTop: "0px",
                                marginLeft: "10px",
                              }}
                              onChange={(e) => setSearchst(e.target.value)}
                            >
                              <option value="">Filter By isSocial</option>
                              <option value="No">No </option>
                              <option value="Yes">Yes</option>
                            </select>

                            <button className="search-submit btn btn-icon">
                              <em className="icon ni ni-search"></em>
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                    {/* <div className="card-inner p-0">
                      <div className="nk-tb-list nk-tb-ulist">
                        <div className="nk-tb-item nk-tb-head">
                          <div
                            className="nk-tb-col nk-tb-col-check"
                            style={{ width: "0px", padding: "10px" }}
                          ></div>
                          <div className="nk-tb-col">
                            <span className="sub-text">Name</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">Email Id</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">Status</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">Verification</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">isSocial</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">Created</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">Last Active</span>
                          </div>
                          <div className="nk-tb-col nk-tb-col-tools text-right">
                            <span className="sub-text">More</span>
                          </div>
                        </div>
                        {loader ? (
                          <LoadingOverlay className="loaderDiv">
                            <Loader loading={loader} />
                          </LoadingOverlay>
                        ) : null}
                        {productList[0]?.length > 0 ? (
                          productList[0]?.map((data, index) => (
                            <div className="nk-tb-item" key={index}>
                              <div className="nk-tb-col nk-tb-col-check">
                                <div
                                  className="custom-control custom-control-sm custom-checkbox notext"
                                  style={{ paddingLeft: "0px", height: "45px" }}
                                >
                                  <div className="user-avatar bg-success-dim">
                                    {data?.profilePicture ? (
                                      <img
                                        src={
                                          BASE_IMAGE_URL + data?.profilePicture
                                        }
                                      />
                                    ) : (
                                      <span
                                        style={{ textTransform: "uppercase" }}
                                      >
                                        {data?.firstName && data?.lastName
                                          ? data?.firstName[0] +
                                            data?.lastName[0]
                                          : "FL"}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="nk-tb-col">
                                <div className="user-info">
                                  <span
                                    className="tb-lead"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    <Link to={"user-details?id=" + data?._id}>
                                      {data?.firstName +
                                        " " +
                                        (data?.lastName != undefined
                                          ? data?.lastName
                                          : "")}
                                    </Link>
                                  </span>
                                </div>
                              </div>
                              <div className="nk-tb-col tb-col-md">
                                <span className="tb-lead">{data.email}</span>
                              </div>
                              <div className="nk-tb-col tb-col-md">
                                <span className="tb-lead">
                                  {data.active ? "Active" : "Deactive"}
                                </span>
                              </div>
                              <div className="nk-tb-col tb-col-md">
                                <span className="tb-lead">
                                  {data.verified ? "Yes" : "No"}
                                </span>
                              </div>
                              <div className="nk-tb-col tb-col-md">
                                <span className="tb-lead">
                                  {data?.provider
                                    ? data?.provider === "google" ||
                                      data?.provider === "facebook"
                                      ? "Yes"
                                      : "No"
                                    : "No"}
                                  <small>
                                    {" "}
                                    ({(data?.provider).toUpperCase()})
                                  </small>
                                </span>
                              </div>
                              <div className="nk-tb-col tb-col-md">
                                <span className="tb-lead">
                                  {data.createdAt
                                    ? format(
                                        new Date(data?.createdAt),
                                        "Do MMM YYYY"
                                      )
                                    : "---"}
                                </span>
                              </div>
                              <div className="nk-tb-col tb-col-md">
                                <span className="tb-lead">
                                  {data.updatedAt
                                    ? format(
                                        new Date(data?.updatedAt),
                                        "Do MMM YYYY"
                                      )
                                    : "---"}
                                </span>
                              </div>
                              <div className="nk-tb-col nk-tb-col-tools">
                                <ul className="nk-tb-actions gx-1">
                                  <li>
                                    <div className="drodown">
                                      <a
                                        href="#"
                                        className="dropdown-toggle btn btn-icon btn-trigger"
                                        data-toggle="dropdown"
                                      >
                                        <em className="icon ni ni-more-h"></em>
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <ul className="link-list-opt no-bdr">
                                          <li>
                                            <Link
                                              to={
                                                "/user-details?id=" + data._id
                                              }
                                            >
                                              <em className="icon ni ni-focus"></em>
                                              <span>View Details</span>
                                            </Link>
                                          </li>
                                          <li>
                                            <a
                                              onClick={(e) =>
                                                popup(data._id, index)
                                              }
                                            >
                                              <em className="icon ni ni-trash"></em>
                                              <span>Delete</span>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          ))
                        ) : (
                          <LoadingOverlay className="loaderDiv">
                            <Loader loading={loader} />
                            <h1
                              style={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "16px",
                                lineHeight: "61px",
                                color: "#eb5757",
                                fontWeight: "600",
                              }}
                            >
                              *Not Found
                            </h1>
                          </LoadingOverlay>
                        )}
                      </div>
                    </div>
                    {paginationIndex ? (
                      <div className="card-inner">
                        <ReactPaginate
                          forcePage={searchPage}
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={remainder >= 1 ? divisor + 1 : divisor}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={(e) => handlePageClick(e)}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    ) : null} */}
                    {loader ? (
                      <LoadingOverlay className="loaderDiv">
                        <Loader loading={loader} />
                        <h1
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "16px",
                            lineHeight: "61px",
                            color: "#eb5757",
                            fontWeight: "600",
                          }}
                        >
                          *Not Found
                        </h1>
                      </LoadingOverlay>
                    ) : (
                      <MyTable columns={columns} tabledata={allData} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserList;
