import React, { useState, useRef, useEffect } from "react";
import { saveColor } from "../Action/PutAction";
import { getAllColors } from "../Action/GetAction";
import { deleteColor as deleteCurrentColor } from "../Action/DeleteAction";
import { toastComp } from "../Sections/Toast";
import { useToasts } from "react-toast-notifications";
export default function ColorPicker() {
  const [colors, setColors] = useState([]);
  const [currentColor, setCurrentColor] = useState("#000000");
  const [colorName, setColorName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const colorPickerRef = useRef(null);
  const { addToast } = useToasts();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    getAllColors("admin/colorList").then((res) => setColors(res?.data));
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const addColor = async () => {
    if (colorName.trim() !== "") {
      if (colors.some((c) => c.value === currentColor)) {
        showToast("This color has already been added to the list.");
      } else {
        // setColors([...colors, { name: colorName, value: currentColor }]);
        setColorName("");
        await saveColorPicker();
        await getAllColors("admin/colorList").then((res) => {
          setColors(res?.data);
        });
      }
    }
  };

  const deleteColor = async (id) => {
    // setColors(colors.filter((_, index) => index !== indexToDelete));
    await deleteCurrentColor(id);
    toastComp(addToast, "success", "Color deleted successfully");
    await getAllColors("admin/colorList").then((res) => setColors(res?.data));
  };

  const showToast = (message) => {
    setToastMessage(message);
    setTimeout(() => setToastMessage(""), 3000);
  };
  const saveColorPicker = async () => {
    await saveColor({ colorName: colorName, colorCode: currentColor });
    toastComp(addToast, "success", "Color added successfully");
  };
  return (
    <div className="color-picker">
      <h2>Color Picker</h2>
      <div className="color-picker-controls">
        <div className="color-picker-button" ref={colorPickerRef}>
          <button
            onClick={() => setIsOpen(!isOpen)}
            style={{ backgroundColor: currentColor }}
          >
            <div
              className="color-preview"
              style={{ backgroundColor: currentColor }}
            ></div>
            {currentColor}
          </button>
          {isOpen && (
            <div className="color-picker-popup">
              <input
                type="color"
                value={currentColor}
                onChange={(e) => setCurrentColor(e.target.value)}
              />
            </div>
          )}
        </div>
        <input
          type="text"
          value={colorName}
          onChange={(e) => setColorName(e.target.value)}
          placeholder="Color name"
        />
        <button
          onClick={() => {
            addColor();
          }}
        >
          Add Color
        </button>
      </div>
      <ul className="font-list">
        {colors?.map((color, index) => (
          <li key={index}>
            <div className="font-item">
              <div className="font-item-content">
                <div
                  className="color-preview"
                  style={{ backgroundColor: color.colorCode }}
                ></div>
                <span>
                  {color?.colorName} - {color?.colorCode}
                </span>
              </div>
              <button onClick={() => deleteColor(color?._id)}>Delete</button>
            </div>
          </li>
        ))}
      </ul>
      {toastMessage && <div className="toast">{toastMessage}</div>}
    </div>
  );
}
