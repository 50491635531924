import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const allowedPaths = [
    '/',
    '/terms',
    '/privacy',
    '/dashboard',
    '/category-list',
    '/product-list',
    '/template-list',
    '/freebie-packages',
    '/demo-card',
    '/showcase-list',
    '/pricing-list',
    '/coupon-list',
    '/user-list',
    '/user-team',
    '/invite-user',
    '/user-details',
    '/team-details',
    '/plans',
    '/view-order',
    '/receipts-preview',
    '/support-list',
    '/admin-account-setting',
    '/testimonials',
    '/orders',
    '/group-cards',
    '/subscribers',
    '/bulktab-template',
    '/bundle-list',
    '/error'
];

const useCheckAndRedirect = () => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        if (!allowedPaths.includes(currentPath)) {
            history.push('/error'); // Redirect to `/` if the current path is not allowed
        }
    }, [location.pathname, history]);
};

export default useCheckAndRedirect;
