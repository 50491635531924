import React, { useEffect, useState } from "react";
import {
  getAllOrder,
  getAllOrderSort,
  getRecipientsDetails,
} from "../Action/GetAction";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { dateFormatConverter } from "../Logic/Logic";
import "react-overlay-loader/styles.css";
import { useHistory, useLocation } from "react-router";
import {
  getMethod,
  postMethod,
  putMethod,
  WEB_URL,
} from "../Utils/ApiServices";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { deleteOrderDetails } from "../Action/DeleteAction";
import { toastComp } from "../Sections/Toast";
import { useToasts } from "react-toast-notifications";
import { DefinedRange } from "react-date-range";
import { DateRangePicker } from "react-date-range";
import { addDays, subDays } from "date-fns";
import { ExportToExcel } from "../tools/ExportToExcel";
import { format } from "fecha";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { getCookies } from "../Utils/Cookies";
import { confirmAlert } from "react-confirm-alert";
import MyTable from "./datatable.js";

const OrderAll = (props) => {
  const [token, setToken] = useState(
    getCookies("_gp_admin_token") ? getCookies("_gp_admin_token") : ""
  );
  const [pathRoutes, setPathRoutes] = useState(
    props?.location?.pathname === "/bundle-orders"
      ? "bundles"
      : props?.location?.pathname === "/card-orders"
      ? "cards"
      : "orders"
  );
  const limitData = 1000000;
  const { addToast } = useToasts();
  const [toggleId, setToggleId] = useState("cards");
  const [allOrderList, setOrderList] = useState([]);
  const [allData, setallData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pageChange, setPageNow] = useState(1);
  const [totalList, setTotalList] = useState(0);
  const [statusSort, setStatusSort] = useState("");
  const [paginationIndex, setPageIndex] = useState(1);
  const [searchPage, setSearchPage] = useState();
  const [filterToggle, setFilterToggle] = useState(false);
  const [stateFilters, setDateFilters] = useState([
    {
      startDate: subDays(new Date(), 4),
      endDate: addDays(new Date(), 2),
      key: "selection",
      //             startDate: 2022-02-12
      // endDate: 2022-02-19
    },
  ]);

  const sendCardToRecipient = async (order) => {
    if (order) {
      let orderId = order._id;
      let data = {
        orderId: orderId,
      };
      await postMethod("/cardDeliveredByAdmin", data).then((res) => {
        if (res.success) {
          toastComp(addToast, "success", res.message);
        } else {
          toastComp(addToast, "error", res.message);
        }
      });
    }
  };

  const getOrderBundlesList = async (page, limit, value) => {
    var finalData = { page: page, limit: limit };
    if (value?.startDate && value?.endDate) {
      var finalData = Object.assign(finalData, {
        startDate: value?.startDate,
        endDate: value?.endDate,
      });
    }
    setLoader(true);
    await getMethod("admin/allbundles", finalData).then((response) => {
      if (response.success) {
        // console.log(response, "response");
        setTotalList(response?.total);
        setPageIndex(
          response?.total < limitData
            ? 1
            : Math.round(response?.total / limitData) % 2 === 0
            ? Math.round(response?.total / limitData)
            : Math.round(response?.total / limitData)
        );
        setOrderList(response?.data);
        setallData(response?.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const getOrderCardsList = async (page, limit, value, status) => {
    var finalData = { page: page, limit: limit };
    if (value?.startDate && value?.endDate) {
      finalData = Object.assign(finalData, {
        startDate: value?.startDate,
        endDate: value?.endDate,
      });
    }
    // if (status) {
    //     finalData = Object.assign(finalData, { status: status });
    // }
    if (status === "false") {
      finalData = Object.assign(finalData, {
        cardOpened: status,
        status: "complete",
      });
    } else if (status) {
      finalData = Object.assign(finalData, { status: status });
    }

    setOrderList([]);
    setLoader(true);
    await getMethod("admin/order", finalData).then((response) => {
      if (response.success) {
        setTotalList(response?.total);
        setPageIndex(
          response?.total < limitData
            ? 1
            : Math.round(response?.total / limitData) % 2 === 0
            ? Math.round(response?.total / limitData)
            : Math.round(response?.total / limitData)
        );
        // console.log(response, "response2");
        setOrderList(response?.data);
        setallData(response?.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const filtersDate = async (path, page, limit, data, statusSort) => {
    var passData = false;
    if (data) {
      setDateFilters(data);
      passData = {
        startDate: JSON.stringify(new Date(data[0]?.startDate)).slice(1, 11),
        endDate: JSON.stringify(new Date(data[0]?.endDate)).slice(1, 11),
      };
    }
    if (path === "bundles") {
      //console.log(passData)
      // setSearchPage(0);
      getOrderBundlesList(page, limitData, passData);
    }
    if (path === "cards") {
      //console.log(passData)
      // setSearchPage(0);
      getOrderCardsList(page, limit, passData, statusSort);
    }
  };

  const hstatusSort = async (value) => {
    if (pathRoutes === "cards") {
      setSearchPage(0);
      filtersDate(toggleId, 1, limitData, stateFilters, value);
    }
    setStatusSort(value);
  };

  const handlePageClick = async (e) => {
    const selectedPage = parseInt(e.selected + 1);
    setLoader(true);
    setPageNow(selectedPage);
    if (pathRoutes === "bundles") {
      filtersDate(toggleId, selectedPage, limitData, stateFilters, "");
    }
    if (pathRoutes === "cards") {
      filtersDate(toggleId, selectedPage, limitData, stateFilters, statusSort);
    }
  };

  const deleteUserOrder = async (id, index) => {
    setLoader(true);
    await deleteOrderDetails(id).then((response) => {
      if (response.status) {
        setLoader(false);
        toastComp(addToast, "success", "Successfully deleted Order!");
        filtersDate(toggleId, pageChange, limitData, stateFilters, statusSort);
      } else {
        setLoader(false);
        toastComp(addToast, "error", "Failed to delete user order!");
      }
    });
  };

  const popup = (id, index) => {
    confirmAlert({
      title: "Confirm Action",
      message: "Are you sure you want to delete this order?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteUserOrder(id, index),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const cancelPopup = (id, data) => {
    confirmAlert({
      title: "Confirm Action",
      message: "Are you sure you want to cancel this order?",
      buttons: [
        {
          label: "Yes",
          onClick: () => cancelOrders(id, data),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const toggleButtons = async (id) => {
    setOrderList([]);
    if (id === "bundles") {
      setPathRoutes("bundles");
      setToggleId("bundles");
      // setSearchPage(0);
      filtersDate(id, 1, limitData, stateFilters, "");
    }
    if (id === "cards") {
      setPathRoutes("cards");
      setToggleId("cards");
      // setSearchPage(0);
      filtersDate(id, 1, limitData, stateFilters, statusSort);
      setStatusSort("");
    }
  };

  const cancelOrders = async (id, data) => {
    const newData = { status: data };
    await putMethod("admin/order/" + id, newData).then((response) => {
      if (response?.success) {
        setLoader(false);
        getOrderCardsList(pageChange, limitData, stateFilters, statusSort);
        toastComp(addToast, "success", response?.message);
      } else {
        setLoader(false);
        toastComp(addToast, "error", response?.message);
      }
    });
  };
  const resetButton = async () => {
    setStatusSort("");
    const data = {
      startDate: subDays(new Date(), 4),
      endDate: addDays(new Date(), 2),
      key: "selection",
    };
    setDateFilters([data]);
    filtersDate(toggleId, 1, limitData, [data], "");
    setFilterToggle(filterToggle ? false : true);
  };
  var divisor = (totalList / 10) >> 0;
  var remainder = totalList % 10;

  useEffect(() => {
    setPathRoutes(toggleId);
    filtersDate(toggleId, pageChange, limitData, stateFilters, statusSort);
    setToken(getCookies("_gp_admin_token"));
  }, [props]);
  const columns = [
    {
      name: "Order",
      cell: (data) =>
        pathRoutes === "cards" ? (
          <div>
            <span
              className="tb-lead text-center"
              style={{ textTransform: "capitalize" }}
            >
              <Link to={"/view-order?order=" + data?.canvasId} target="_blank">
                {data?._id ? "#" + data?._id.slice(-5) : ""}
              </Link>
            </span>
          </div>
        ) : pathRoutes === "bundles" ? (
          <div>
            <span
              className="tb-lead text-center"
              style={{ textTransform: "capitalize" }}
            >
              {data?.bundleId?.name ? data?.bundleId?.name : "Bundle Deleted"}
            </span>
          </div>
        ) : (
          ""
        ),
      sortable: true,
      width: "150px",
      sortFunction: (rowA, rowB) => {
        if (pathRoutes === "cards") {
          const orderA = rowA._id ? rowA._id.slice(-5) : "";
          const orderB = rowB._id ? rowB._id.slice(-5) : "";
          return orderA.localeCompare(orderB);
        } else if (pathRoutes === "bundles") {
          const bundleA = rowA.bundleId?.name || "Bundle Deleted";
          const bundleB = rowB.bundleId?.name || "Bundle Deleted";
          return bundleA.localeCompare(bundleB);
        }
        return 0;
      },
    },
    {
      name: "Order Created By",
      cell: (data) =>
        pathRoutes === "cards" ? (
          <div>
            {data?.user ? (
              <span
                className="tb-lead text-center"
                style={{ textTransform: "capitalize" }}
              >
                <Link to={"/user-details?id=" + data?.user._id}>
                  {data?.user
                    ? data?.user?.firstName + " " + data?.user?.lastName
                    : "Guest User"}
                </Link>
              </span>
            ) : (
              <span
                className="tb-lead text-center"
                style={{ textTransform: "capitalize" }}
              >
                {data?.user
                  ? data?.user?.firstName + " " + data?.user?.lastName
                  : "Guest User"}
              </span>
            )}
          </div>
        ) : pathRoutes === "bundles" ? (
          <div>
            {data?.userId ? (
              <span
                className="tb-lead text-center"
                style={{ textTransform: "capitalize" }}
              >
                <Link to={"/user-details?id=" + data?.userId._id}>
                  {data?.userId
                    ? data?.userId?.firstName + " " + data?.userId?.lastName
                    : "Guest User"}
                </Link>
              </span>
            ) : (
              <span
                className="tb-lead text-center"
                style={{ textTransform: "capitalize" }}
              >
                {data?.userId
                  ? data?.userId?.firstName + " " + data?.userId?.lastName
                  : "Guest User"}
              </span>
            )}
          </div>
        ) : (
          ""
        ),
      sortable: true,
      width: "180px",
      sortFunction: (rowA, rowB) => {
        if (pathRoutes === "cards") {
          const nameA = rowA.user
            ? rowA.user.firstName + " " + rowA.user.lastName
            : "Guest User";
          const nameB = rowB.user
            ? rowB.user.firstName + " " + rowB.user.lastName
            : "Guest User";
          return nameA.localeCompare(nameB);
        } else if (pathRoutes === "bundles") {
          const nameA = rowA.userId
            ? rowA.userId.firstName + " " + rowA.userId.lastName
            : "Guest User";
          const nameB = rowB.userId
            ? rowB.userId.firstName + " " + rowB.userId.lastName
            : "Guest User";
          return nameA.localeCompare(nameB);
        }
        return 0;
      },
    },
    {
      name: "Recipient Name",
      selector: (data) =>
        pathRoutes === "cards" ? (
          <div>
            {data?.recipientId ? (
              <span
                className="tb-lead text-center"
                style={{ textTransform: "capitalize" }}
              >
                <Link to={"/user-details?id=" + data?.recipientId}>
                  {data?.recipient ? data?.recipient.name : "..."}
                </Link>
              </span>
            ) : (
              <span
                className="tb-lead text-center"
                style={{ textTransform: "capitalize" }}
              >
                {data?.recipient ? data?.recipient?.name : "..."}
              </span>
            )}
          </div>
        ) : (
          ""
        ),
      sortable: true,
      width: "150px",
      sortFunction: (rowA, rowB) => {
        const nameA = rowA.recipient ? rowA.recipient.name : "...";
        const nameB = rowB.recipient ? rowB.recipient.name : "...";
        return nameA.localeCompare(nameB);
      },
    },
    {
      name: "Amount",
      selector: (data) => (
        <div>
          <span
            className="tb-lead text-center"
            style={{ textTransform: "capitalize" }}
          >
            {pathRoutes === "cards"
              ? data?.payment
                ? data?.payment?.paymentMethod === "wallet"
                  ? ""
                  : "CAD "
                : ""
              : ""}
            {pathRoutes === "bundles"
              ? data?.paymentId?.amount
              : pathRoutes === "cards"
              ? data?.payment?.paymentMethod === "wallet"
                ? data?.payment?.amount + " debited through wallet"
                : data?.payment?.amount
                ? data?.payment?.amount
                : data?.productType?.price > 0
                ? data?.couponId?._id
                  ? data?.couponId?.discount_type === "percent"
                    ? "CAD " +
                      (data?.productType?.price -
                        data?.productType?.price *
                          (data?.couponId?.discount / 100))
                    : data?.couponId?.discount_type === "fixed"
                    ? "CAD " +
                      (data?.productType?.price - data?.couponId?.discount)
                    : "Pending Amount CAD " + data?.productType?.price
                  : "Pending Amount CAD " + data?.productType?.price
                : data?.productType?.price === 0
                ? "CAD " + data?.productType?.price
                : "Pending"
              : data?.amount}
          </span>
        </div>
      ),
      sortable: true,
      width: "200px",
      sortFunction: (rowA, rowB) => {
        if (pathRoutes === "cards") {
          const amountA = rowA.payment ? rowA.payment.amount : 0;
          const amountB = rowB.payment ? rowB.payment.amount : 0;
          return amountA - amountB;
        } else if (pathRoutes === "bundles") {
          const amountA = rowA.paymentId ? rowA.paymentId.amount : 0;
          const amountB = rowB.paymentId ? rowB.paymentId.amount : 0;
          return amountA - amountB;
        }
        return 0;
      },
    },
    {
      name: "Status",
      selector: (data) => (
        <div>
          <span
            className="tb-lead text-center"
            style={{ textTransform: "capitalize" }}
          >
            {pathRoutes === "bundles"
              ? data?.paymentId?.status === "succeeded"
                ? "Paid"
                : data?.paymentId?.status
              : pathRoutes === "cards"
              ? data?.status === "start"
                ? "Template Selected"
                : data?.status === "recipientassigned"
                ? "Payment Pending"
                : data?.status === "complete"
                ? "Delivered"
                : data?.status === "scheduled"
                ? "Card Scheduled"
                : data?.status === "paid"
                ? "Paid"
                : data?.status === "pending"
                ? "Schedule Pending"
                : data?.status[0].toUpperCase() + data?.status.substring(1)
              : data?.status}
          </span>
        </div>
      ),
      sortable: true,
      width: "150px",
      sortFunction: (rowA, rowB) => {
        const statusA = rowA.status ? rowA.status.toLowerCase() : "";
        const statusB = rowB.status ? rowB.status.toLowerCase() : "";
        return statusA.localeCompare(statusB);
      },
    },
    {
      name: "Is Open",
      selector: (data) =>
        pathRoutes === "cards" ? (
          <div>
            <span
              className="tb-lead text-center"
              style={{ textTransform: "capitalize" }}
            >
              {data?.cardOpened ? "opened" : "Not Opened"}
            </span>
          </div>
        ) : (
          ""
        ),
      sortable: true,
      width: "120px",
      sortFunction: (rowA, rowB) => {
        const openA = rowA.cardOpened ? 1 : 0;
        const openB = rowB.cardOpened ? 1 : 0;
        return openA - openB;
      },
    },
    {
      name: "Date",
      selector: (data) => (
        <div>
          <span className="tb-lead text-center">
            {format(new Date(data?.createdAt), "Do MMM YYYY, HH:mm A")}
          </span>
        </div>
      ),
      sortable: true,
      width: "130px",
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.createdAt);
        const dateB = new Date(rowB.createdAt);
        return dateA - dateB;
      },
      width: "180px",
    },
    {
      name: "Delivered Date",
      selector: (data) =>
        pathRoutes === "cards" ? (
          <div>
            <span className="tb-lead text-center">
              {data?.deliveryDate
                ? format(new Date(data?.deliveryDate), "Do MMM YYYY, ") +
                  data?.deliveryTime
                : "---"}
            </span>
          </div>
        ) : (
          ""
        ),
      sortable: true,
      width: "100px",
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.deliveryDate);
        const dateB = new Date(rowB.deliveryDate);
        return dateA - dateB;
      },
      width: "180px",
    },
    {
      name: "More",
      width: "80px",
      cell: (data) => (
        <div>
          <ul className="nk-tb-actions gx-1">
            <li>
              <div className="drodown">
                <a
                  href="#"
                  className="dropdown-toggle btn btn-icon btn-trigger"
                  data-toggle="dropdown"
                >
                  <em className="icon ni ni-more-h"></em>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <ul className="link-list-opt no-bdr">
                    {pathRoutes === "bundles" ? (
                      <></>
                    ) : pathRoutes === "cards" ? (
                      <>
                        <li>
                          <Link to={"/view-order?order=" + data?.canvasId}>
                            <em className="icon ni ni-eye"></em>
                            <span>View Order</span>
                          </Link>
                        </li>
                        {data?.reminderMailSent &&
                        data?.cardOpened === false ? (
                          <li
                            className="sendMailLi"
                            onClick={() => sendCardToRecipient(data)}
                          >
                            <em className="icon ni ni-mail"></em>
                            <span>Resend Email</span>
                          </li>
                        ) : (
                          ""
                        )}
                        {data?.status === "complete" ||
                        data?.status === "cancel" ? null : (
                          <li>
                            <a
                              onClick={(e) => {
                                if (
                                  data?.status != "cancel" ||
                                  data?.status != "complete"
                                ) {
                                  cancelPopup(data?._id, "cancel");
                                }
                              }}
                            >
                              <em className="icon ni ni-stop"></em>
                              <span>Cancel Order</span>
                            </a>
                          </li>
                        )}
                        {data?.payment?.recipietLink ? (
                          <li>
                            <Link
                              to={
                                "/receipts-preview?invoiceId=" +
                                data?.payment._id
                              }
                            >
                              <em className="icon ni ni-edit"></em>
                              <span>Preview Receipts</span>
                            </Link>
                          </li>
                        ) : null}
                        {data?.user ? (
                          <li>
                            <a
                              href={`https://app.posthog.com/person/${data?.user?._id}#activeTab=sessionRecordings`}
                              target="blank"
                            >
                              <em className="icon ni ni-eye"></em>
                              <span>View Recordings</span>
                            </a>
                          </li>
                        ) : (
                          <li>
                            <a
                              href={`https://app.posthog.com/person/${data?.guestId}#activeTab=sessionRecordings`}
                              target="blank"
                            >
                              <em className="icon ni ni-eye"></em>
                              <span>View Recordings</span>
                            </a>
                          </li>
                        )}
                        {data?.canvasId ? (
                          <li>
                            <a
                              onClick={(e) => {
                                popup(data?.canvasId, index);
                              }}
                            >
                              <em className="icon ni ni-trash"></em>
                              <span>Delete</span>
                            </a>
                          </li>
                        ) : null}
                      </>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      All Order List
                    </h3>
                    <div className="nk-block-des text-soft">
                      <p>
                        You have total{" "}
                        {totalList === 1
                          ? totalList + " orders"
                          : totalList + " orders"}
                        .
                      </p>
                    </div>
                  </div>
                  <div className="nk-block-head-content">
                    <div
                      className={filterToggle ? "row ctaActive" : "row cta"}
                      style={{
                        height: "fit-content",
                        position: "fixed",
                        right: "1%",
                        zIndex: 9999,
                        top: "10%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "start",
                        flexWrap: "wrap",
                        paddingBottom: "15px",
                      }}
                    >
                      <button
                        className="togglebff"
                        onClick={(e) =>
                          setFilterToggle(filterToggle ? false : true)
                        }
                        style={{
                          width: "50px",
                          height: "50px",
                          backgroundColor: "#526484",
                          color: "#fff",
                          border: "solid #526484 1px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          outline: "none",
                          textAlign: "center",
                          alignItems: "center",
                          fontSize: "16px",
                        }}
                      >
                        <em className="icon ni ni-filter"></em>
                      </button>
                      <div
                        className="row hideshow"
                        style={{
                          width: "100%",
                          margin: "0px",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "#fff",
                          padding: "15px",
                        }}
                      >
                        <DateRangePicker
                          onChange={(item) => {
                            setPageNow(1);
                            filtersDate(
                              toggleId,
                              0,
                              limitData,
                              [item.selection],
                              statusSort
                            );
                            setFilterToggle(filterToggle ? false : true);
                          }}
                          ranges={stateFilters}
                          direction="horizontal"
                          showDateDisplay={true}
                          showPreview={true}
                          editableDateInputs={true}
                          dragSelectionEnabled={true}
                          showSelectionPreview={true}
                          showMonthAndYearPickers={true}
                        />
                        <button
                          onClick={(e) => resetButton()}
                          style={{
                            width: "100%",
                            backgroundColor: "#526484",
                            color: "#fff",
                            border: "solid #526484 1px",
                            outline: "none",
                            textAlign: "center",
                            alignItems: "center",
                            fontSize: "14px",
                            padding: "5px",
                            borderRadius: "25px",
                            marginTop: "15px",
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="card card-bordered card-stretch">
                  <div className="card-inner-group">
                    <div className="card-inner position-relative card-tools-toggle">
                      <div
                        className={"card-search search-wrap active"}
                        data-search="search"
                      >
                        <div
                          className="card-body"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="search-content"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              padding: "0px",
                            }}
                          >
                            {pathRoutes === "cards" ? (
                              <select
                                className="btn btn-white btn-outline-light"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  outline: "none",
                                  border: "solid rgb(82, 100, 132) 1px",
                                  backgroundColor: "rgb(82, 100, 132)",
                                  color: "#fff",
                                  margin: "0px",
                                  marginTop: "0px",
                                  marginLeft: "0px",
                                }}
                                value={statusSort}
                                onChange={(e) => {
                                  setPageNow(1);
                                  hstatusSort(e.target.value);
                                }}
                              >
                                <option value="">Filter By Status</option>
                                <option value="start">
                                  Templated Selected
                                </option>
                                <option value="recipientassigned">
                                  Payment Pending
                                </option>
                                <option value="pending">
                                  Schedule Pending
                                </option>
                                <option value="paid">Paid</option>
                                <option value="scheduled">
                                  Card Scheduled
                                </option>
                                <option value="complete">Delivered</option>
                                {/* <option value='true'>Opened</option> */}
                                <option value="false">Not Opened</option>
                                <option value="cancel">Canceled</option>
                              </select>
                            ) : null}
                          </div>
                          <div
                            className="search-content"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <button
                              onClick={(e) => {
                                setPageNow(1);
                                toggleButtons("cards");
                              }}
                              style={{
                                border: "solid #526484 1px",
                                background:
                                  toggleId === "cards" ? "#526484" : "#fff",
                                color:
                                  toggleId === "cards" ? "#fff" : "#526484",
                                margin: "5px",
                                borderRadius: "25px",
                                padding: "5px",
                                width: "100px",
                              }}
                            >
                              Cards
                            </button>
                            <button
                              onClick={(e) => {
                                setPageNow(1);
                                toggleButtons("bundles");
                              }}
                              style={{
                                border: "solid #526484 1px",
                                background:
                                  toggleId === "bundles" ? "#526484" : "#fff",
                                color:
                                  toggleId === "bundles" ? "#fff" : "#526484",
                                margin: "5px",
                                borderRadius: "25px",
                                padding: "5px",
                                width: "100px",
                              }}
                            >
                              Bundles
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="card-inner p-0">
                      <div className="nk-tb-list nk-tb-ulist">
                        <div className="nk-tb-item nk-tb-head">
                          <div
                            className="nk-tb-col nk-tb-col-check"
                            style={{ width: "0px", padding: "10px" }}
                          ></div>
                          <div className="nk-tb-col">
                            <span className="sub-text text-center">Order</span>
                          </div>
                          <div className="nk-tb-col tb-col-mb">
                            <span className="sub-text text-center">
                              Order Created By
                            </span>
                          </div>
                          {pathRoutes === "cards" ? (
                            <div className="nk-tb-col tb-col-md">
                              <span className="sub-text text-center">
                                Recipient Name
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text text-center">Amount</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text text-center">Status</span>
                          </div>
                          {pathRoutes === "cards" ? (
                            <div className="nk-tb-col tb-col-md">
                              <span className="sub-text text-center">
                                Is Open
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text text-center">Date</span>
                          </div>
                          {pathRoutes === "cards" ? (
                            <div className="nk-tb-col tb-col-md">
                              <span className="sub-text text-center">
                                Delivered Date
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="nk-tb-col nk-tb-col-tools text-right">
                            <span className="sub-text">More</span>
                          </div>
                        </div>
                        {loader ? (
                          <LoadingOverlay className="loaderDiv">
                            <Loader loading={loader} />
                          </LoadingOverlay>
                        ) : null}
                        {allOrderList?.length > 0 ? (
                          allOrderList?.map((data, index) => (
                            <div className="nk-tb-item" key={index}>
                              <div
                                className="nk-tb-col nk-tb-col-check"
                                style={{ width: "0px", padding: "10px" }}
                              ></div>

                              {pathRoutes === "cards" ? (
                                <div className="nk-tb-col tb-col-md">
                                  <span
                                    className="tb-lead text-center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    <Link
                                      to={"/view-order?order=" + data?.canvasId}
                                      target="_blank"
                                    >
                                      {data?._id
                                        ? "#" + data?._id.slice(-5)
                                        : ""}
                                    </Link>
                                  </span>
                                </div>
                              ) : pathRoutes === "bundles" ? (
                                <div className="nk-tb-col tb-col-md">
                                  <span
                                    className="tb-lead text-center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {data?.bundleId?.name
                                      ? data?.bundleId?.name
                                      : "Bundle Deleted"}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}

                              {pathRoutes === "cards" ? (
                                <div className="nk-tb-col tb-col-mb">
                                  {data?.user ? (
                                    <span
                                      className="tb-lead text-center"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <Link
                                        to={
                                          "/user-details?id=" + data?.user._id
                                        }
                                      >
                                        {data?.user
                                          ? data?.user?.firstName +
                                            " " +
                                            data?.user?.lastName
                                          : "Guest User"}
                                      </Link>
                                    </span>
                                  ) : (
                                    <span
                                      className="tb-lead text-center"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {data?.user
                                        ? data?.user?.firstName +
                                          " " +
                                          data?.user?.lastName
                                        : "Guest User"}
                                    </span>
                                  )}
                                </div>
                              ) : pathRoutes === "bundles" ? (
                                <div className="nk-tb-col tb-col-mb">
                                  {data?.userId ? (
                                    <span
                                      className="tb-lead text-center"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <Link
                                        to={
                                          "/user-details?id=" + data?.userId._id
                                        }
                                      >
                                        {data?.userId
                                          ? data?.userId?.firstName +
                                            " " +
                                            data?.userId?.lastName
                                          : "Guest User"}
                                      </Link>
                                    </span>
                                  ) : (
                                    <span
                                      className="tb-lead text-center"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {data?.userId
                                        ? data?.userId?.firstName +
                                          " " +
                                          data?.userId?.lastName
                                        : "Guest User"}
                                    </span>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              {pathRoutes === "cards" ? (
                                <div className="nk-tb-col tb-col-mb">
                                  {data?.recipientId ? (
                                    <span
                                      className="tb-lead text-center"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <Link
                                        to={
                                          "/user-details?id=" +
                                          data?.recipientId
                                        }
                                      >
                                        {data?.recipient
                                          ? data?.recipient.name
                                          : "..."}
                                      </Link>
                                    </span>
                                  ) : (
                                    <span
                                      className="tb-lead text-center"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {data?.recipient
                                        ? data?.recipient?.name
                                        : "..."}
                                    </span>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="nk-tb-col tb-col-md">
                                <span
                                  className="tb-lead text-center"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {pathRoutes === "cards"
                                    ? data?.payment
                                      ? data?.payment?.paymentMethod ===
                                        "wallet"
                                        ? ""
                                        : "CAD "
                                      : ""
                                    : ""}
                                  {pathRoutes === "bundles"
                                    ? data?.paymentId?.amount
                                    : pathRoutes === "cards"
                                    ? data?.payment?.paymentMethod === "wallet"
                                      ? data?.payment?.amount +
                                        " debited through wallet"
                                      : data?.payment?.amount
                                      ? data?.payment?.amount
                                      : data?.productType?.price > 0
                                      ? data?.couponId?._id
                                        ? data?.couponId?.discount_type ===
                                          "percent"
                                          ? "CAD " +
                                            (data?.productType?.price -
                                              data?.productType?.price *
                                                (data?.couponId?.discount /
                                                  100))
                                          : data?.couponId?.discount_type ===
                                            "fixed"
                                          ? "CAD " +
                                            (data?.productType?.price -
                                              data?.couponId?.discount)
                                          : "Pending Amount CAD " +
                                            data?.productType?.price
                                        : "Pending Amount CAD " +
                                          data?.productType?.price
                                      : data?.productType?.price === 0
                                      ? "CAD " + data?.productType?.price
                                      : "Pending"
                                    : data?.amount}
                                </span>
                              </div>

                              <div className="nk-tb-col tb-col-md">
                                <span
                                  className="tb-lead text-center"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {pathRoutes === "bundles"
                                    ? data?.paymentId?.status === "succeeded"
                                      ? "Paid"
                                      : data?.paymentId?.status
                                    : pathRoutes === "cards"
                                    ? data?.status === "start"
                                      ? "Template Selected"
                                      : data?.status === "recipientassigned"
                                      ? "Payment Pending"
                                      : data?.status === "complete"
                                      ? "Delivered"
                                      : data?.status === "scheduled"
                                      ? "Card Scheduled"
                                      : data?.status === "paid"
                                      ? "Paid"
                                      : data?.status === "pending"
                                      ? "Schedule Pending"
                                      : data?.status[0].toUpperCase() +
                                        data?.status.substring(1)
                                    : data?.status}
                                </span>
                              </div>

                              {pathRoutes === "cards" ? (
                                <div className="nk-tb-col tb-col-md">
                                  <span
                                    className="tb-lead text-center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {data?.cardOpened ? "opened" : "Not Opened"}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="nk-tb-col tb-col-md">
                                <span className="tb-lead text-center">
                                  {format(
                                    new Date(data?.createdAt),
                                    "Do MMM YYYY, HH:mm A"
                                  )}
                                </span>
                              </div>
                              {pathRoutes === "cards" ? (
                                <div className="nk-tb-col tb-col-md">
                                  <span className="tb-lead text-center">
                                    {data?.deliveryDate
                                      ? format(
                                          new Date(data?.deliveryDate),
                                          "Do MMM YYYY, "
                                        ) + data?.deliveryTime
                                      : "---"}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="nk-tb-col nk-tb-col-tools">
                                <ul className="nk-tb-actions gx-1">
                                  <li>
                                    <div className="drodown">
                                      <a
                                        href="#"
                                        className="dropdown-toggle btn btn-icon btn-trigger"
                                        data-toggle="dropdown"
                                      >
                                        <em className="icon ni ni-more-h"></em>
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <ul className="link-list-opt no-bdr">
                                          {pathRoutes === "bundles" ? (
                                            <></>
                                          ) : pathRoutes === "cards" ? (
                                            <>
                                              <li>
                                                <Link
                                                  to={
                                                    "/view-order?order=" +
                                                    data?.canvasId
                                                  }
                                                >
                                                  <em className="icon ni ni-eye"></em>
                                                  <span>View Order</span>
                                                </Link>
                                              </li>
                                              {data?.reminderMailSent &&
                                              data?.cardOpened === false ? (
                                                <li
                                                  className="sendMailLi"
                                                  onClick={() =>
                                                    sendCardToRecipient(data)
                                                  }
                                                >
                                                  <em className="icon ni ni-mail"></em>
                                                  <span>Resend Email</span>
                                                </li>
                                              ) : (
                                                ""
                                              )}
                                              {data?.status === "complete" ||
                                              data?.status ===
                                                "cancel" ? null : (
                                                <li>
                                                  <a
                                                    onClick={(e) => {
                                                      if (
                                                        data?.status !=
                                                          "cancel" ||
                                                        data?.status !=
                                                          "complete"
                                                      ) {
                                                        cancelPopup(
                                                          data?._id,
                                                          "cancel"
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <em className="icon ni ni-stop"></em>
                                                    <span>Cancel Order</span>
                                                  </a>
                                                </li>
                                              )}
                                              {data?.payment?.recipietLink ? (
                                                <li>
                                                  <Link
                                                    to={
                                                      "/receipts-preview?invoiceId=" +
                                                      data?.payment._id
                                                    }
                                                  >
                                                    <em className="icon ni ni-edit"></em>
                                                    <span>
                                                      Preview Receipts
                                                    </span>
                                                  </Link>
                                                </li>
                                              ) : null}
                                              {data?.user ? (
                                                <li>
                                                  <a
                                                    href={`https://app.posthog.com/person/${data?.user?._id}#activeTab=sessionRecordings`}
                                                    target="blank"
                                                  >
                                                    <em className="icon ni ni-eye"></em>
                                                    <span>View Recordings</span>
                                                  </a>
                                                </li>
                                              ) : (
                                                <li>
                                                  <a
                                                    href={`https://app.posthog.com/person/${data?.guestId}#activeTab=sessionRecordings`}
                                                    target="blank"
                                                  >
                                                    <em className="icon ni ni-eye"></em>
                                                    <span>View Recordings</span>
                                                  </a>
                                                </li>
                                              )}
                                              {data?.canvasId ? (
                                                <li>
                                                  <a
                                                    onClick={(e) => {
                                                      popup(
                                                        data?.canvasId,
                                                        index
                                                      );
                                                    }}
                                                  >
                                                    <em className="icon ni ni-trash"></em>
                                                    <span>Delete</span>
                                                  </a>
                                                </li>
                                              ) : null}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              position: "absolute",
                              left: "0px",
                              backgroundColor: "#fff",
                            }}
                          >
                            <h1
                              style={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "16px",
                                lineHeight: "61px",
                                color: "#eb5757",
                                fontWeight: "600",
                              }}
                            >
                              *Not Found
                            </h1>
                          </div>
                        )}
                      </div>
                    </div> */}
                    {loader ? (
                      <LoadingOverlay className="loaderDiv">
                        <Loader loading={loader} />
                        <h1
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "16px",
                            lineHeight: "61px",
                            color: "#eb5757",
                            fontWeight: "600",
                          }}
                        >
                          *Not Found
                        </h1>
                      </LoadingOverlay>
                    ) : (
                      <MyTable
                        columns={
                          pathRoutes == "cards"
                            ? columns
                            : columns.filter(
                                (data) =>
                                  data.name !== "Recipient Name" &&
                                  data.name !== "Delivered Date" &&
                                  data.name !== "Is Open"
                              )
                        }
                        tabledata={allData}
                      />
                    )}
                    {/* {paginationIndex ? (
                      <div className="card-inner">
                        <ReactPaginate
                          forcePage={searchPage}
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={remainder >= 1 ? divisor + 1 : divisor}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={limitData}
                          onPageChange={(e) => handlePageClick(e)}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    ) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderAll;
