import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  getUserDetails,
  getUserOrderDetails,
  getUserpurchasehistory,
  getToggleUserCouponAllow,
  getRecipientsId,
  getuserselectplan,
  getteammembers,
  cancel_subscriptionad,
} from "../Action/GetAction";
import { addmemberrole, updateUserDetails } from "../Action/PutAction";
import { deleteOrderDetails, deletemember } from "../Action/DeleteAction";
import { BASE_IMAGE_URL, WEB_URL } from "../Utils/ApiServices";
import { useToasts } from "react-toast-notifications";
import { toastComp } from "../Sections/Toast";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import ReactPaginate from "react-paginate";
import { dateFormatConverter, timeConvert } from "../Logic/Logic";
import { Modal } from "react-responsive-modal";
import "react-overlay-loader/styles.css";
import "react-responsive-modal/styles.css";
import { addCardForShowCase } from "../Action/PostAction";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getCookies } from "../Utils/Cookies";
import TeamDetails from "./TeamDetail";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const UserDetails = (props) => {
  let query = useQuery();
  const history = useHistory();
  const { addToast } = useToasts();
  const [token, setToken] = useState("");
  const [editSaveToogle, setEditSaveToggle] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDOB] = useState("");
  const [emailId, setEmailId] = useState("");
  const [active, setActive] = useState("");
  const [verify, setVerify] = useState("");
  const [password, setPassword] = useState("");
  const [wallets, setWalletsPoint] = useState(0);
  const [createdCount, setCreateCount] = useState(0);
  const [receivedCount, setReceivedCount] = useState(0);
  const [signedCount, setSignedCount] = useState(0);
  const [totalSpent, setTotalSpent] = useState(0);
  const [totalPlan, setTotalPlan] = useState(0);
  const [loader, setLoader] = useState(true);
  const [orderType, setOrderType] = useState("created");
  const [orderLoader, setOrderLoader] = useState(true);
  const [userOrderList, setOrderListDetails] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [cardTotal, setCardTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [showCaseId, setAddShowCaseId] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [Plans, setPlans] = useState({});
  const [description, setDescription] = useState("");
  const [walletPointSet, setWalletPointEdit] = useState(0);
  const [memberlist, setmemberlist] = useState([]);
  const [memberlist2, setmemberlist2] = useState([]);
  const [userPurchasehistory, setUserPurchasehistory] = useState([]);
  const activeEditField = () => {
    if (
      document.querySelector("input[name=firstName]") != null &&
      document.querySelector("input[name=lastName]") != null &&
      document.querySelector("input[name=dob]") != null &&
      document.querySelector("select[name=accountActive]") != null
    ) {
      document.querySelector("input[name=firstName]").disabled = false;
      document.querySelector("input[name=lastName]").disabled = false;
      document.querySelector("input[name=dob]").disabled = false;
      document.querySelector("select[name=accountActive]").disabled = false;
      document.querySelector("select[name=verify]").disabled = false;
      document.querySelector("input[name=walletPoint]").disabled = false;
      setEditSaveToggle(true);
    }
  };
  const cancelSaving = () => {
    if (
      document.querySelector("input[name=firstName]") != null &&
      document.querySelector("input[name=lastName]") != null &&
      document.querySelector("input[name=dob]") != null &&
      document.querySelector("select[name=accountActive]") != null
    ) {
      document.querySelector("input[name=firstName]").disabled = true;
      document.querySelector("input[name=lastName]").disabled = true;
      document.querySelector("input[name=dob]").disabled = true;
      document.querySelector("select[name=accountActive]").disabled = true;
      document.querySelector("select[name=verify]").disabled = true;
      document.querySelector("input[name=walletPoint]").disabled = true;
      setEditSaveToggle(false);
    }
  };
  async function getSingleUserDetails(id) {
    await getUserDetails(id).then((response) => {
      if (response.success) {
        setProfilePic(
          response?.data?.profilePicture ? response.data.profilePicture : ""
        );
        setFirstName(
          response?.data?.firstName ? response?.data?.firstName : ""
        );
        setLastName(response?.data?.firstName ? response?.data?.lastName : "");
        setDOB(response?.data?.dob ? response?.data?.dob : "");
        setEmailId(response?.data?.email ? response?.data?.email : "");
        setActive(response?.data?.active ? response?.data?.active : false);
        setVerify(response?.data?.verified ? response?.data?.verified : false);
        setWalletsPoint(response?.data?.wallet ? response?.data?.wallet : 0);
        setCreateCount(
          response?.data?.createdCards ? response?.data?.createdCards : 0
        );
        setReceivedCount(
          response?.data?.receivedCards ? response?.data?.receivedCards : 0
        );
        setSignedCount(
          response?.data?.signedCards ? response?.data?.signedCards : 0
        );
        setTotalSpent(
          response?.data?.totalspent ? response?.data?.totalspent : 0
        );
        setTotalPlan(
          response?.data?.userBundle ? response?.data?.userBundle : 0
        );
        setWalletPointEdit(response?.data?.wallet ? response?.data?.wallet : 0);
        setLoader(false);
        cancelSaving();
      } else {
        history.push("/user-list");
      }
    });
  }
  const userOrderRelatedDetails = async (id, page, limit, type) => {
    setOrderLoader(true);
    await getUserOrderDetails(id, page, limit, type).then((response) => {
      if (response.success) {
        //if created type filter active
        // console.log("response",response);
        if (
          response.data.createdCards ||
          response.data.receivedCards ||
          response.data.signedCards ||
          response.data.coupon
        ) {
          setOrderLoader(false);
          const arrayd =
            response.data.createdCards ||
            response.data.receivedCards ||
            response.data.signedCards ||
            response.data.coupon;
          // console.log("arrayd",arrayd);
          setOrderListDetails([arrayd]);
          setCardTotal(response?.data?.total);
          setPageIndex(Math.ceil(response.data.total / limit));
        }
      } else {
        setOrderLoader(true);
      }
    });
  };

  const getUserpurchasedetails = async (id) => {
    await getUserpurchasehistory(id).then((response) => {
      if (response.status) {
        setUserPurchasehistory(response.data);
      } else {
      }
    });
  };
  const [userpurchasehistoryall, setUserPurchasehistoryall] = useState([]);
  useEffect(() => {
    if (
      userPurchasehistory?.length > 0 &&
      userPurchasehistory[0].type == undefined
    ) {
      const updatedPurchaseHistory = [
        { type: "purchaseHistory", data: userPurchasehistory },
        { type: "userOrders", data: userOrderList },
      ];
      // console.log(updatedPurchaseHistory[0].type,'updatedpurchashistory')
      setUserPurchasehistoryall(updatedPurchaseHistory);
    }
  }, [userPurchasehistory]);

  const deleteUserOrder = async (id, index) => {
    setOrderLoader(true);
    await deleteOrderDetails(id).then((response) => {
      if (response.status) {
        setOrderLoader(false);
        userOrderList[0]?.splice(index, 1);
        toastComp(addToast, "success", "Successfully deleted Order!");
      } else {
        setOrderLoader(false);
        toastComp(addToast, "error", "Failed to delete user order!");
      }
    });
  };

  const popup = (id, index) => {
    confirmAlert({
      title: "Confirm Action",
      message: "Are you sure you want to delete this card?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteUserOrder(id, index),
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };
  async function updateProfclePic(formData) {
    setLoader(true);
    const params = {
      userId: query.get("id"),
    };
    await updateUserDetails(formData, params).then(async (response) => {
      if (response.success) {
        await getSingleUserDetails(query.get("id"));
        toastComp(
          addToast,
          "success",
          "Successfully updated to user account details!"
        );
      } else {
        toastComp(addToast, "error", "Failed to update user account details!");
      }
    });
  }
  const cancel_subscription = async () => {
    setOrderLoader(true);
    await cancel_subscriptionad(Plans?.paymentId).then((response) => {
      if (response.message == "subscrpition cancel sucesfully") {
        toastComp(addToast, "success", response?.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setOrderLoader(true);
        toastComp(addToast, "error", "Failed to Subscrpition Cancel!");
      }
    });
  };
  async function toggleUserCouponStatus(id, couponId, status) {
    await getToggleUserCouponAllow(id, couponId, status).then(
      async (response) => {
        if (response.success) {
          toastComp(addToast, "success", response.message);
          await userOrderRelatedDetails(query.get("id"), 1, 5, orderType);
        } else {
          toastComp(addToast, "error", response.message);
        }
      }
    );
  }
  async function saveUserDetails() {
    if (firstName != "" && lastName != "") {
      var formData = new FormData();
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("dob", dob);
      formData.append("active", active);
      formData.append("verified", verify);
      formData.append("wallet", walletPointSet);
      if (password != "") {
        formData.append("password", password);
      }
      await updateProfclePic(formData);
    } else {
      toastComp(addToast, "error", "Please fill all the neccessary detaisl!");
    }
  }
  const onCloseModal = () => {
    setOpen(false);
    setAddShowCaseId("");
    setTitle("");
    setSubTitle("");
    setDescription("");
  };
  const openDialogBox = (id) => {
    setOpen(true);
    setAddShowCaseId(id);
  };
  const AddCardForShowCaseAPI = async () => {
    if (title != "" && subTitle != "" && showCaseId != "") {
      const data = {
        title: title,
        minTitle: subTitle,
        description: description,
        canvasId: showCaseId,
      };
      await addCardForShowCase(data).then((response) => {
        if (response.success) {
          toastComp(
            addToast,
            "success",
            "Successfully Card Added For Showcase!"
          );
          onCloseModal();
        } else {
          toastComp(addToast, "error", response.message);
        }
      });
    } else {
      toastComp(addToast, "error", "Please fill the required input field!");
    }
  };
  const handlePageClick = async (e) => {
    const selectedPage = parseInt(e.selected + 1);
    setOrderLoader(true);
    await userOrderRelatedDetails(query.get("id"), selectedPage, 5, orderType);
  };
  useEffect(async () => {
    if (query.get("id")) {
      await getSingleUserDetails(query.get("id"));
      await userOrderRelatedDetails(query.get("id"), 1, 5, orderType);
      await getUserpurchasedetails(query.get("id"));
    } else {
      history.push("/user-list");
    }
    setToken(getCookies("_gp_admin_token"));
    if (query.get("id") != undefined) {
      getuserselectplan(query.get("id")).then((response) => {
        setPlans(response.data);
        if (response?.success != false) {
          getteammembers({
            id: response?.data?._id,
            ownerId: response?.data?.user,
          }).then(
            (response2) => {
              setOrderLoader(false);
              setmemberlist(response2);
              setmemberlist2(response2);
            },
            (error) => {
              setLoaderToggle(false);
            }
          );
        }
      });
    }
  }, []);
  const changememberrole = (rsdata) => {
    addmemberrole(rsdata).then((response) => {
      // setlevel(response)
      setmemberlist([]);
      if (query.get("id") != undefined) {
        getuserselectplan(query.get("id")).then((response) => {
          if (response?.success != false) {
            getteammembers({
              id: response?.data?._id,
              ownerId: response?.data?.user,
            }).then(
              (response2) => {
                setOrderLoader(false);
                setmemberlist(response2);
                setmemberlist2(response2);
              },
              (error) => {
                setLoaderToggle(false);
              }
            );
          }
        });
      }
    });
  };
  const memberdelete = (rsdata, id) => {
    deletemember({
      data: rsdata,
      id: id,
    }).then(
      (response) => {
        if (response.status) {
          addToast("Deleted!", {
            appearance: "success",
            autoDismiss: true,
          });
          // setlevel(response)
          setmemberlist([]);
          if (query.get("id") != undefined) {
            getuserselectplan(query.get("id")).then((response) => {
              if (response?.success != false) {
                getteammembers({
                  id: response?.data?._id,
                  ownerId: response?.data?.user,
                }).then(
                  (response2) => {
                    setOrderLoader(false);
                    setmemberlist(response2);
                    setmemberlist2(response2);
                  },
                  (error) => {
                    setLoaderToggle(false);
                  }
                );
              }
            });
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const onShowAlert = (rsdata) => {
    confirmAlert({
      title: "Confirm Action",
      message: "Are you sure you want to change this Level?",
      buttons: [
        {
          label: "Yes",
          onClick: () => changememberrole(rsdata),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const onShowAlert2 = (rsdata, editId) => {
    confirmAlert({
      title: "Confirm Action",
      message: "Are you sure you want to delete this member?",
      buttons: [
        {
          label: "Yes",
          onClick: () => memberdelete(rsdata, editId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const [search, setSearch] = useState("");
  const [searchsub, setSearchsub] = useState("");
  const [toggleSearchBar, setToggleSearchBar] = useState(true);
  const search_filter = (e) => {
    e.preventDefault();
    setLoader(true);
    setmemberlist({});
    let filterBySearch = memberlist2?.members;
    if (search != "") {
      filterBySearch = filterBySearch.filter((item) => {
        if (
          (item?.firstName + " " + item?.lastName + " " + item?.email)
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          return item;
        }
      });
    }
    if (searchsub != "") {
      filterBySearch = filterBySearch.filter((item) => {
        if (item.verified == JSON.parse(searchsub)) {
          return item;
        }
      });
    }
    // if (searchst != "") {
    //   filterBySearch = filterBySearch.filter((item) => {
    //     if ((item?.billingType).toLowerCase()
    //       .includes(searchst.toLowerCase())) { return item; }
    //   })
    // }
    // console.log(filterBySearch);
    // setCountProduct(filterBySearch.length);
    setTimeout(() => {
      setmemberlist({ members: filterBySearch });
      setLoader(false);
    }, 1000);
  };

  const [viewMode, setViewMode] = useState("1");
  return (
    <>
      <div className="nk-content">
        <div className="container-fluid pt-3 px-5">
          <div className="nk-content-body">
            <div className="nk-block-head nk-block-head-sm">
              <div className="nk-block-between g-3">
                <div className="nk-block-head-content">
                  <h3 className="nk-block-title page-title countd">
                    User Details
                  </h3>
                </div>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    href="#"
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                  >
                    <em className="icon ni ni-menu-alt-r"></em>
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      <li>
                        <a
                          className="btn btn-white btn-outline-light"
                          onClick={async (e) => {
                            var formData = new FormData();
                            formData.append("role", "admin");
                            await updateProfclePic(formData);
                          }}
                        >
                          <em className="icon ni ni-plus"></em>
                          <span>Make Admin</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="nk-block">
              <div className="row gy-5 reducePadding1">
                {loader ? (
                  <div className="col-lg-12 col-12" style={{ height: "300px" }}>
                    <LoadingOverlay className="loaderDiv">
                      <Loader loading={loader} />
                    </LoadingOverlay>
                  </div>
                ) : (
                  <>
                    <div className="col-lg-6 col-12">
                      <div className="card card-bordered">
                        <ul className="data-list is-compact">
                          <li className="data-item">
                            <div
                              className="data-col"
                              style={{
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                className="profileDiv"
                                style={{
                                  border: "solid whitesmoke 1px",
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "100%",
                                }}
                              >
                                {profilePic ? (
                                  <div
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      borderRadius: "100%",
                                      backgroundColor: "whitesmoke",
                                    }}
                                  >
                                    <img
                                      src={BASE_IMAGE_URL + profilePic}
                                      style={{
                                        borderRadius: "100%",
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      borderRadius: "100%",
                                      backgroundColor: "whitesmoke",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "56px",
                                        fontWeight: "600",
                                        backgroundColor: "transparent",
                                        fontFamily: "Nunito, sans-serif",
                                      }}
                                    >
                                      {firstName[0]
                                        ? firstName[0]
                                        : "G" + lastName[0]
                                          ? lastName[0]
                                          : "P"}
                                    </span>
                                  </div>
                                )}
                                <div
                                  className="dash-set-camera"
                                  style={{
                                    transform: "translate(70px,-30px)",
                                    backgroundColor: "white",
                                    border: "solid 1px #81dbcd",
                                    width: "25px",
                                    height: "25px",
                                    borderRadius: "100%",
                                  }}
                                >
                                  <label
                                    htmlFor="profilepic1"
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      borderRadius: "100%",
                                    }}
                                  >
                                    <em
                                      className="icon ni ni-pen"
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "16px",
                                        lineHeight: "25px",
                                        color: "#81dbcd",
                                      }}
                                      title="edit profile picture"
                                    ></em>
                                    <input
                                      id="profilepic1"
                                      style={{
                                        visibility: "hidden",
                                        backgroundColor: "transparent",
                                        width: "25px",
                                        height: "25px",
                                        cursor: "pointer",
                                      }}
                                      type="file"
                                      name="profilePic"
                                      onChange={(event) => {
                                        var file = event.target.files[0];
                                        var formData = new FormData();
                                        formData.append(
                                          "profilepic",
                                          file,
                                          file.name
                                        );
                                        updateProfclePic(formData);
                                      }}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col edit">
                              <div className="data-label">First Name</div>
                              <input
                                type="text"
                                className="data-value ip-field"
                                value={firstName}
                                name="firstName"
                                disabled
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="First Name"
                              />
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col edit">
                              <div className="data-label">Last Name</div>
                              <input
                                type="text"
                                className="data-value ip-field"
                                value={lastName}
                                name="lastName"
                                placeholder="Last Name"
                                disabled
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col edit">
                              <div className="data-label">D.O.B</div>
                              <input
                                type="date"
                                className="data-value ip-field"
                                value={dob}
                                name="dob"
                                placeholder=""
                                disabled
                                onChange={(e) => setDOB(e.target.value)}
                              />
                            </div>
                          </li>
                          {editSaveToogle ? (
                            <li className="data-item">
                              <div className="data-col edit">
                                <div className="data-label">Password</div>
                                <input
                                  type="password"
                                  className="data-value ip-field"
                                  autoComplete="off"
                                  value={password}
                                  name="email"
                                  placeholder="Enter Password"
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                              </div>
                            </li>
                          ) : null}
                          <li className="data-item">
                            <div className="data-col edit">
                              <div className="data-label">Email Id</div>
                              <input
                                type="email"
                                className="data-value ip-field"
                                value={emailId}
                                name="email"
                                placeholder="Email Id"
                                disabled
                                onChange={(e) => setEmailId(e.target.value)}
                              />
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Account Active</div>
                              <select
                                disabled
                                className="data-value ip-field"
                                name="accountActive"
                                value={active}
                                onChange={(e) => setActive(e.target.value)}
                              >
                                <option value="">Select</option>
                                <option value="true">Active</option>
                                <option value="false">Deactive</option>
                              </select>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">Account Verify</div>
                              {/* <label className="data-value ip-field" style={{ cursor: 'no-drop' }}>{verify ? 'Verified' : 'Not Verify'}</label> */}
                              <select
                                name="verify"
                                disabled
                                className="data-value ip-field"
                                value={verify}
                                onChange={(e) => setVerify(e.target.value)}
                              >
                                <option value="">Select</option>
                                <option value="true">Verified</option>
                                <option value="false">Not Verify</option>
                              </select>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col edit">
                              <div className="data-label">Wallet Credits</div>
                              <input
                                type="number"
                                className="data-value ip-field"
                                value={walletPointSet}
                                name="walletPoint"
                                placeholder="Add Point"
                                disabled
                                onChange={(e) =>
                                  setWalletPointEdit(e.target.value)
                                }
                              />
                            </div>
                          </li>
                          <li className="data-item" style={{ display: "flex" }}>
                            <div
                              className="data-col"
                              style={{
                                height: "50px",
                                justifyContent: "flex-end",
                              }}
                            >
                              {Plans?.planDetails?.planName ? (
                                <button
                                  className="btn btn-md btn-primary"
                                  style={{ marginRight: "10px" }}
                                  onClick={cancel_subscription}
                                >
                                  Cancel Subscription
                                </button>
                              ) : (
                                ""
                              )}

                              <button
                                className="btn btn-md btn-primary"
                                style={{ marginRight: "10px" }}
                                onClick={
                                  editSaveToogle
                                    ? () => saveUserDetails()
                                    : () => activeEditField()
                                }
                              >
                                {editSaveToogle ? "Save" : "Edit"}
                              </button>
                              {editSaveToogle ? (
                                <button
                                  className="btn btn-md btn-primary"
                                  style={{ marginRight: "10px" }}
                                  onClick={() => cancelSaving()}
                                >
                                  Cancel
                                </button>
                              ) : null}
                            </div>
                          </li>
                          <br />
                          <br />
                          <br />
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div
                        className="row justify-content-center"
                        style={{ marginBottom: "15px" }}
                      >
                        <div
                          className="col-lg-6 col-12"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="card card-bordered">
                            <div className="card-inner">
                              <div className="card-title-group align-start mb-2">
                                <div className="card-title">
                                  <h6 className="title">Total Spent</h6>
                                </div>
                              </div>
                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                <div className="nk-sale-data">
                                  <span className="amount">
                                    {totalSpent
                                      ? "CAD " + totalSpent.toFixed(2)
                                      : "CAD 0"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-6 col-12"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="card card-bordered">
                            <div className="card-inner">
                              <div className="card-title-group align-start mb-2">
                                <div className="card-title">
                                  <h6 className="title">Plan Name</h6>
                                </div>
                              </div>
                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                <div className="nk-sale-data">
                                  <span className="amount">
                                    {Plans?.planDetails?.planName
                                      ? Plans?.planDetails?.planName
                                      : "---"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-6 col-12"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="card card-bordered">
                            <div className="card-inner">
                              <div className="card-title-group align-start mb-2">
                                <div className="card-title">
                                  <h6 className="title">Plan Price</h6>
                                </div>
                              </div>
                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                <div className="nk-sale-data">
                                  <span className="amount">
                                    {Plans?.planPricing
                                      ? Plans?.planPricing
                                      : 0}
                                    $
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-6 col-12"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="card card-bordered">
                            <div className="card-inner">
                              <div className="card-title-group align-start mb-2">
                                <div className="card-title">
                                  <h6 className="title">Type of Plan</h6>
                                </div>
                              </div>
                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                <div className="nk-sale-data">
                                  <span className="amount">
                                    {Plans?.billingType
                                      ? Plans?.billingType
                                      : "---"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-6 col-12"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="card card-bordered">
                            <div className="card-inner">
                              <div className="card-title-group align-start mb-2">
                                <div className="card-title">
                                  <h6 className="title">Payment Date</h6>
                                </div>
                              </div>
                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                <div className="nk-sale-data">
                                  <span className="amount">
                                    {Plans?.paymentDetails?.paymentDate
                                      ? dateFormatConverter(
                                        Plans?.paymentDetails?.paymentDate
                                      )
                                      : "---"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-lg-6 col-12"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="card card-bordered">
                            <div className="card-inner">
                              <div className="card-title-group align-start mb-2">
                                <div className="card-title">
                                  <h6 className="title">Due Date</h6>
                                </div>
                              </div>
                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                <div className="nk-sale-data">
                                  <span className="amount">
                                    {Plans?.paymentDetails?.dueDate
                                      ? dateFormatConverter(
                                        Plans?.paymentDetails?.dueDate
                                      )
                                      : "---"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-lg-6 col-12"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="card card-bordered">
                            <div className="card-inner">
                              <div className="card-title-group align-start mb-2">
                                <div className="card-title">
                                  <h6 className="title">Total Wallet</h6>
                                </div>
                              </div>
                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                <div className="nk-sale-data">
                                  <span className="amount">
                                    {Plans?.planDetails?.cards?.unlimited
                                      ? wallets + "+" + "Unlimited"
                                      : wallets}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-6 col-12"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="card card-bordered">
                            <div className="card-inner">
                              <div className="card-title-group align-start mb-2">
                                <div className="card-title">
                                  <h6 className="title">Total Created</h6>
                                </div>
                              </div>
                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                <div className="nk-sale-data">
                                  <span className="amount">{createdCount}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-6 col-12"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="card card-bordered">
                            <div className="card-inner">
                              <div className="card-title-group align-start mb-2">
                                <div className="card-title">
                                  <h6 className="title">Total Received</h6>
                                </div>
                              </div>
                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                <div className="nk-sale-data">
                                  <span className="amount">
                                    {receivedCount}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-6 col-12"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="card card-bordered">
                            <div className="card-inner">
                              <div className="card-title-group align-start mb-2">
                                <div className="card-title">
                                  <h6 className="title">Total Signed</h6>
                                </div>
                              </div>
                              <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                <div className="nk-sale-data">
                                  <span className="amount">{signedCount}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className="row justify-content-center"
                style={{
                  marginTop: "15px",
                  marginBottom: "15px",
                  height: "100%",
                }}
              >
                <div className="col-lg-12 col-12 text-left">
                  <div className="nk-block-between">
                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title page-title countd">
                        Order Details
                      </h3>
                      <div className="nk-block-des text-soft">
                        <p>
                          You have total{" "}
                          {cardTotal === 1
                            ? cardTotal + " card"
                            : cardTotal + " cards"}
                        </p>
                      </div>
                    </div>
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <ul className="nk-block-tools g-3">
                        <li>
                          <select
                            className="btn btn-white btn-outline-light"
                            value={orderType}
                            onChange={async (e) => {
                              setOrderType(e.target.value);
                              await userOrderRelatedDetails(
                                query.get("id"),
                                1,
                                5,
                                e.target.value
                              );
                            }}
                          >
                            {/* <option value='bundle'>Pricing Plan</option> */}
                            <option value="created">Created</option>
                            <option value="received">Received</option>
                            <option value="signed">Signed</option>
                            <option value="coupon">User Coupon</option>
                          </select>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-12 col-12"
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    height: "100%",
                  }}
                >
                  <div
                    className="row gy-10"
                    style={{
                      marginTop: "10px",
                      backgroundColor: "#fff",
                      height: "100%",
                    }}
                  >
                    <div className="nk-tb-list nk-tb-ulist">
                      <div className="nk-tb-item nk-tb-head">
                        <div className="nk-tb-col nk-tb-col-check">
                          <span className="sub-text">S.No</span>
                        </div>
                        <div className="nk-tb-col">
                          <span className="sub-text">
                            {orderType === "coupon"
                              ? "Coupon Code"
                              : "Sender Name"}
                          </span>
                        </div>
                        <div className="nk-tb-col tb-col-md">
                          <span className="sub-text">
                            {orderType === "coupon"
                              ? "Coupon Used By Customer"
                              : "Recipient Name"}
                          </span>
                        </div>
                        <div className="nk-tb-col tb-col-md">
                          <span className="sub-text">
                            {orderType === "received"
                              ? "Received Date"
                              : orderType === "coupon"
                                ? "Valid From"
                                : "Deivery Date"}
                          </span>
                        </div>
                        <div className="nk-tb-col tb-col-md">
                          <span className="sub-text">
                            {orderType === "received"
                              ? "Received Time"
                              : orderType === "coupon"
                                ? "Valid Till"
                                : "Delivery Time"}
                          </span>
                        </div>
                        <div className="nk-tb-col tb-col-md">
                          <span className="sub-text">{"Created At"}</span>
                        </div>
                        <div className="nk-tb-col tb-col-mb">
                          <span className="sub-text">Status</span>
                        </div>
                        <div className="nk-tb-col nk-tb-col-tools text-right">
                          <span className="sub-text">More</span>
                        </div>
                      </div>
                      {orderLoader ? (
                        <LoadingOverlay
                          className="loaderDiv"
                          style={{ left: "0px" }}
                        >
                          <Loader loading={orderLoader} />
                        </LoadingOverlay>
                      ) : null}
                      {userOrderList[0]?.length > 0 ? (
                        userOrderList[0]?.map((orderList, index) => (
                          <div className="nk-tb-item" key={index}>
                            {/* {console.log(orderList,'orderlist')} */}
                            <div className="nk-tb-col nk-tb-col-check">
                              <div className="custom-control custom-control-sm custom-checkbox notext">
                                <span className="tb-lead">
                                  {parseInt(index + 1)}
                                </span>
                              </div>
                            </div>
                            <div className="nk-tb-col">
                              <div className="user-info">
                                <span className="tb-lead">
                                  {orderList?.sendersName
                                    ? orderList.sendersName
                                    : orderList?.code
                                      ? orderList?.code
                                      : "---"}
                                </span>
                              </div>
                            </div>
                            <div className="nk-tb-col tb-col-md">
                              <span className="tb-lead">
                                {orderList?.recipient
                                  ? orderList?.recipient?.name
                                  : orderList?.usedByCustomer === 0 ||
                                    orderList?.usedByCustomer > 0
                                    ? orderList?.usedByCustomer
                                    : "---"}
                              </span>
                            </div>
                            <div className="nk-tb-col tb-col-md">
                              <span className="tb-lead">
                                {orderList?.deliveryDate
                                  ? dateFormatConverter(orderList?.deliveryDate)
                                  : orderList?.valid_from
                                    ? dateFormatConverter(orderList?.valid_from)
                                    : "---"}
                              </span>
                            </div>
                            <div className="nk-tb-col tb-col-md">
                              <span className="tb-lead">
                                {orderList?.deliveryTime
                                  ? timeConvert(orderList?.deliveryTime)
                                  : orderList?.valid_till
                                    ? dateFormatConverter(orderList?.valid_till)
                                    : "---"}
                              </span>
                            </div>
                            <div className="nk-tb-col tb-col-md">
                              <span className="tb-lead">
                                {orderList?.createdAt
                                  ? dateFormatConverter(orderList?.createdAt)
                                  : "---"}
                              </span>
                            </div>
                            <div className="nk-tb-col tb-col-mb">
                              <span
                                className="tb-lead"
                                style={{ textTransform: "capitalize" }}
                              >
                                {orderType === "signed"
                                  ? "Signed"
                                  : orderList?.status
                                    ? orderList?.status === "start"
                                      ? "Templated Selected"
                                      : orderList?.status == "recipientassigned"
                                        ? "Payment Pending"
                                        : orderList?.status == "pending"
                                          ? "Schedule Pending"
                                          : orderList?.status == "paid"
                                            ? "Paid"
                                            : orderList?.status == "scheduled"
                                              ? "Card Scheduled"
                                              : orderList?.status == "complete"
                                                ? "Delivered"
                                                : orderList?.status
                                    : orderList?.status}
                              </span>
                            </div>
                            <div className="nk-tb-col nk-tb-col-tools">
                              <ul className="nk-tb-actions gx-1">
                                <li>
                                  <div className="drodown">
                                    <a
                                      href="#"
                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                      data-toggle="dropdown"
                                    >
                                      <em className="icon ni ni-more-h"></em>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <ul className="link-list-opt no-bdr">
                                        {orderType === "coupon" ? (
                                          <>
                                            <li>
                                              <a
                                                onClick={async (e) => {
                                                  await toggleUserCouponStatus(
                                                    query.get("id"),
                                                    orderList?._id,
                                                    orderList?.blockUser
                                                      ?.length &&
                                                      orderList?.blockUser?.includes(
                                                        query?.get("id")
                                                      )
                                                      ? false
                                                      : true
                                                  );
                                                }}
                                              >
                                                <em
                                                  className="icon ni ni-stop"
                                                  style={{
                                                    color:
                                                      orderList?.blockUser
                                                        ?.length &&
                                                        orderList?.blockUser?.includes(
                                                          query?.get("id")
                                                        )
                                                        ? "green"
                                                        : "red",
                                                    fill:
                                                      orderList?.blockUser
                                                        ?.length &&
                                                        orderList?.blockUser?.includes(
                                                          query?.get("id")
                                                        )
                                                        ? "green"
                                                        : "red",
                                                  }}
                                                ></em>
                                                <span>
                                                  {orderList?.blockUser
                                                    ?.length &&
                                                    orderList?.blockUser?.includes(
                                                      query?.get("id")
                                                    )
                                                    ? "Enable Coupon"
                                                    : "Disable Coupon"}
                                                </span>
                                              </a>
                                            </li>
                                          </>
                                        ) : orderType === "created" ? (
                                          <>
                                            <li>
                                              <Link
                                                to={
                                                  "/view-order?order=" +
                                                  orderList.canvasId
                                                }
                                              >
                                                <em className="icon ni ni-focus"></em>
                                                <span>View Details</span>
                                              </Link>
                                            </li>
                                            {/* <li><a href={process.env.REACT_APP_WEB_URL+'admin-edit?token='+token+'&card=' + orderList?.canvasId} target={'_blank'}><em className="icon ni ni-edit"></em><span>Edit Card</span></a></li> */}
                                            {orderList?.status === "paid" ? (
                                              <>
                                                <li>
                                                  <a
                                                    onClick={(e) =>
                                                      orderList.canvasId
                                                    }
                                                  >
                                                    <em className="icon ni ni-edit"></em>
                                                    <span>Cancel Order</span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    onClick={(e) => {
                                                      openDialogBox(
                                                        orderList?.canvasId
                                                      );
                                                    }}
                                                  >
                                                    <em className="icon ni ni-list"></em>
                                                    <span>
                                                      Add Card For Showcase
                                                    </span>
                                                  </a>
                                                </li>
                                              </>
                                            ) : null}

                                            {orderList?.status ===
                                              "complete" ? (
                                              <>
                                                <li>
                                                  <a
                                                    onClick={(e) => {
                                                      openDialogBox(
                                                        orderList?.canvasId
                                                      );
                                                    }}
                                                  >
                                                    <em className="icon ni ni-list"></em>
                                                    <span>
                                                      Add Card For Showcase
                                                    </span>
                                                  </a>
                                                </li>
                                              </>
                                            ) : null}

                                            <li>
                                              <a
                                                onClick={(e) =>
                                                  popup(
                                                    orderList.canvasId,
                                                    index
                                                  )
                                                }
                                              >
                                                <em className="icon ni ni-trash"></em>
                                                <span>Delete</span>
                                              </a>
                                            </li>
                                          </>
                                        ) : orderType === "received" ? (
                                          <>
                                            <li>
                                              <a
                                                onClick={(e) =>
                                                  navigator.clipboard.writeText(
                                                    WEB_URL +
                                                    "recipient?card=" +
                                                    orderList.canvasId
                                                  )
                                                }
                                              >
                                                <em className="icon ni ni-link"></em>
                                                <span>Copy Link</span>
                                              </a>
                                            </li>
                                          </>
                                        ) : (
                                          <>
                                            <li>
                                              <a
                                                onClick={(e) =>
                                                  navigator.clipboard.writeText(
                                                    orderList?.collaboratorStatus ===
                                                      "signed"
                                                      ? WEB_URL +
                                                      "collaborator?card=" +
                                                      orderList.canvasId
                                                      : WEB_URL +
                                                      "recipient?card=" +
                                                      orderList.canvasId
                                                  )
                                                }
                                              >
                                                <em className="icon ni ni-link"></em>
                                                <span>Copy Link</span>
                                              </a>
                                            </li>
                                          </>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            position: "absolute",
                            left: "0px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <h1
                            style={{
                              width: "100%",
                              textAlign: "center",
                              fontSize: "16px",
                              lineHeight: "61px",
                              color: "#eb5757",
                              fontWeight: "600",
                            }}
                          >
                            *Not Found
                          </h1>
                        </div>
                      )}
                    </div>
                    {pageIndex ? (
                      <div className="card-inner" style={{ width: "100%" }}>
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageIndex}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={1}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              <div
                className="row justify-content-center"
                style={{
                  marginTop: "15px",
                  marginBottom: "15px",
                  height: "100%",
                }}
              >
                <div className="col-lg-12 col-12 text-left">
                  <div className="nk-block-between">
                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title page-title countd">
                        Team Members
                      </h3>
                      <div className="nk-block-des text-soft">
                        <p>
                          You have total{" "}
                          {memberlist?.members?.length > 0
                            ? memberlist?.members?.length + " team member"
                            : 0 + " team member"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-12 col-12"
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    height: "100%",
                  }}
                >
                  <div className="card-inner position-relative card-tools-toggle row">
                    <div
                      className={
                        toggleSearchBar
                          ? "card-search search-wrap active"
                          : "card-search search-wrap active"
                      }
                      data-search="search"
                    >
                      <div className="card-body">
                        <form
                          className="search-content"
                          onSubmit={(e) => {
                            search_filter(e);
                          }}
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <input
                            type="text"
                            onChange={(e) => setSearch(e.target.value)}
                            name="search"
                            id="search"
                            className="form-control border-transparent form-focus-none"
                            placeholder="Search by user name/email..."
                            style={{
                              paddingLeft: "0px",
                              textTransform: "none",
                              width: "calc(100% - 200px)",
                            }}
                          />
                          <select
                            className="btn btn-white btn-outline-light"
                            style={{
                              width: "150px",
                              height: "100%",
                              outline: "none",
                              border: "solid rgb(82, 100, 132) 1px",
                              backgroundColor: "rgb(82, 100, 132)",
                              color: "#fff",
                              margin: "0px",
                              marginTop: "0px",
                              marginLeft: "10px",
                            }}
                            onChange={(e) => setSearchsub(e.target.value)}
                          >
                            <option value="">Filter By Status</option>
                            <option value={true}>Accepted</option>
                            <option value={false}>Pending</option>
                          </select>
                          <button className="search-submit btn btn-icon">
                            <em className="icon ni ni-search"></em>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div
                    className="row gy-10"
                    style={{
                      marginTop: "10px",
                      backgroundColor: "#fff",
                      height: "100%",
                    }}
                  >
                    <div className="nk-tb-list nk-tb-ulist">
                      <div className="nk-tb-item nk-tb-head">
                        <div className="nk-tb-col nk-tb-col-check">
                          <span className="sub-text">S.No</span>
                        </div>
                        <div className="nk-tb-col">
                          <span className="sub-text">Name</span>
                        </div>
                        <div className="nk-tb-col tb-col-md">
                          <span className="sub-text">Email</span>
                        </div>
                        <div className="nk-tb-col tb-col-md">
                          <span className="sub-text">Status</span>
                        </div>
                        <div className="nk-tb-col tb-col-md">
                          <span className="sub-text">Level</span>
                        </div>
                        <div className="nk-tb-col tb-col-md">
                          <span className="sub-text">{"Joined"}</span>
                        </div>
                        <div className="nk-tb-col nk-tb-col-tools text-right">
                          <span className="sub-text">More</span>
                        </div>
                      </div>
                      {orderLoader ? (
                        <LoadingOverlay
                          className="loaderDiv"
                          style={{ left: "0px" }}
                        >
                          <Loader loading={orderLoader} />
                        </LoadingOverlay>
                      ) : null}
                      {memberlist?.members?.length > 0 ? (
                        memberlist?.members?.map((data, index) => (
                          <div className="nk-tb-item" key={index}>
                            <div className="nk-tb-col nk-tb-col-check">
                              <div className="custom-control custom-control-sm custom-checkbox notext">
                                <span className="tb-lead">
                                  {parseInt(index + 1)}
                                </span>
                              </div>
                            </div>
                            <div className="nk-tb-col">
                              <div className="user-info">
                                <span className="tb-lead">
                                  {data.firstName ? data.firstName : "---"}
                                </span>
                              </div>
                            </div>
                            <div className="nk-tb-col tb-col-md">
                              <span className="tb-lead">
                                {data.email ? data.email : "---"}
                              </span>
                            </div>
                            <div className="nk-tb-col tb-col-md">
                              <span className="tb-lead">
                                {data.verified == true ? "Accepted" : "Pending"}
                              </span>
                            </div>
                            <div className="nk-tb-col tb-col-md">
                              <span className="tb-lead">
                                {data.teamRole ? data.teamRole : "member"}
                              </span>
                            </div>
                            <div className="nk-tb-col tb-col-md">
                              <span className="tb-lead">
                                {data.updatedAt
                                  ? dateFormatConverter(data.updatedAt)
                                  : "---"}
                              </span>
                            </div>
                            <div className="nk-tb-col nk-tb-col-tools">
                              <ul className="nk-tb-actions gx-1">
                                <li>
                                  <div className="drodown">
                                    <a
                                      href="#"
                                      className="dropdown-toggle btn btn-icon btn-trigger"
                                      data-toggle="dropdown"
                                    >
                                      <em className="icon ni ni-more-h"></em>
                                    </a>
                                    {orderType === "created" &&
                                      data.teamRole != "owner" ? (
                                      <div
                                        className="dropdown-menu dropdown-menu-right"
                                        style={{ minWidth: "260px" }}
                                      >
                                        <ul className="link-list-opt no-bdr teamdatalist">
                                          {orderType === "created" ? (
                                            <>
                                              {data.teamRole != "owner" &&
                                                data.teamRole != "Admin" &&
                                                data.teamRole != "admin" &&
                                                data.verified == true ? (
                                                <li
                                                  onClick={(e) => {
                                                    onShowAlert({
                                                      id: data._id,
                                                      role: "Admin",
                                                      owner: query.get("id"),
                                                    });
                                                  }}
                                                >
                                                  <a>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      height="15"
                                                      width="15"
                                                      viewBox="0 0 512 512"
                                                    >
                                                      <path d="M213.3,384c0-87,65.2-158.7,149.3-169.2c0-0.9,0-1.5,0-1.5c5.5-8,21.3-21.3,21.3-42.7s-21.3-42.7-21.3-53.3  C362.7,32,319.2,0,256,0c-60.5,0-106.7,32-106.7,117.3c0,10.7-21.3,32-21.3,53.3s15.2,35.4,21.3,42.7c0,0,0,21.3,10.7,53.3  c0,10.7,21.3,21.3,32,32c0,10.7,0,21.3-10.7,42.7L64,362.7C21.3,373.3,0,448,0,512h271.4C235.9,480.7,213.3,435,213.3,384z M384,256  c-70.7,0-128,57.3-128,128s57.3,128,128,128s128-57.3,128-128S454.7,256,384,256z M469.3,405.3h-64v64h-42.7v-64h-64v-42.7h64v-64  h42.7v64h64V405.3z" />
                                                    </svg>
                                                    Upgrade to admin
                                                  </a>
                                                </li>
                                              ) : (
                                                ""
                                              )}

                                              {data.teamRole != "owner" &&
                                                data.verified == true &&
                                                data.teamRole != "member" &&
                                                data.teamRole != "Member" ? (
                                                <li
                                                  onClick={(e) => {
                                                    onShowAlert({
                                                      id: data._id,
                                                      role: "Member",
                                                      owner: query.get("id"),
                                                    });
                                                  }}
                                                >
                                                  <a>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="16"
                                                      height="16"
                                                      viewBox="0 0 24 24"
                                                    >
                                                      <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12C22 7.28595 22 4.92893 20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28595 2 4.92893 2 3.46447 3.46447C2 4.92893 2 7.28595 2 12C2 16.714 2 19.0711 3.46447 20.5355ZM7.56507 13.0693C7.29271 12.7573 6.81893 12.7251 6.50685 12.9974C6.19477 13.2698 6.16257 13.7436 6.43493 14.0557L9.43493 17.4932C9.6405 17.7287 9.97062 17.812 10.2633 17.7022C10.5561 17.5925 10.75 17.3126 10.75 17V7C10.75 6.58579 10.4142 6.25 10 6.25C9.58579 6.25 9.25 6.58579 9.25 7V15L7.56507 13.0693ZM13.7367 6.29775C13.4439 6.40753 13.25 6.68737 13.25 7L13.25 17C13.25 17.4142 13.5858 17.75 14 17.75C14.4142 17.75 14.75 17.4142 14.75 17L14.75 9L16.4349 10.9306C16.7073 11.2427 17.1811 11.2749 17.4931 11.0026C17.8052 10.7302 17.8374 10.2564 17.5651 9.94435L14.5651 6.50685C14.3595 6.2713 14.0294 6.18798 13.7367 6.29775Z"
                                                      />
                                                    </svg>
                                                    Make to Member
                                                  </a>
                                                </li>
                                              ) : (
                                                ""
                                              )}

                                              {/* {data.verified != true ?
                                                  <li
                                                    onClick={e => {
                                                      setTimeout(() => {
                                                        onShowAlert3(
                                                          {
                                                            "email": data.email,
                                                            "firstName": data.firstName,
                                                            "lastname": data.lastName,
                                                            "message": "",
                                                            "owner": query.get('id')
                                                          }
                                                        )
                                                      }, 1000);
                                                    }}>
                                                    <a>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 32 32" version="1.1">
                                                        <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd" type="MSPage">
                                                          <g id="Icon-Set-Filled" type="MSLayerGroup" transform="translate(-570.000000, -257.000000)" >
                                                            <path d="M580.407,278.75 C581.743,281.205 586,289 586,289 C586,289 601.75,258.5 602,258 L602.02,257.91 L580.407,278.75 L580.407,278.75 Z M570,272 C570,272 577.298,276.381 579.345,277.597 L601,257 C598.536,258.194 570,272 570,272 L570,272 Z" id="send-email" type="MSShapeGroup">
                                                            </path>
                                                          </g>
                                                        </g>
                                                      </svg>
                                                      Resend invite
                                                    </a>
                                                  </li>
                                                  : ""} */}
                                              <li
                                                onClick={(e) => {
                                                  onShowAlert2(
                                                    data._id,
                                                    query.get("id")
                                                  );
                                                }}
                                              >
                                                <a>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 24 24"
                                                  >
                                                    <path
                                                      fill-rule="evenodd"
                                                      clip-rule="evenodd"
                                                      d="M2.06935 5.25839C2 5.62595 2 6.06722 2 6.94975V14C2 17.7712 2 19.6569 3.17157 20.8284C4.34315 22 6.22876 22 10 22H14C17.7712 22 19.6569 22 20.8284 20.8284C22 19.6569 22 17.7712 22 14V11.7979C22 9.16554 22 7.84935 21.2305 6.99383C21.1598 6.91514 21.0849 6.84024 21.0062 6.76946C20.1506 6 18.8345 6 16.2021 6H15.8284C14.6747 6 14.0979 6 13.5604 5.84678C13.2651 5.7626 12.9804 5.64471 12.7121 5.49543C12.2237 5.22367 11.8158 4.81578 11 4L10.4497 3.44975C10.1763 3.17633 10.0396 3.03961 9.89594 2.92051C9.27652 2.40704 8.51665 2.09229 7.71557 2.01738C7.52976 2 7.33642 2 6.94975 2C6.06722 2 5.62595 2 5.25839 2.06935C3.64031 2.37464 2.37464 3.64031 2.06935 5.25839ZM9.25 13C9.25 12.5858 9.58579 12.25 10 12.25H14C14.4142 12.25 14.75 12.5858 14.75 13C14.75 13.4142 14.4142 13.75 14 13.75H10C9.58579 13.75 9.25 13.4142 9.25 13Z"
                                                    />
                                                  </svg>
                                                  Remove from organization
                                                </a>
                                              </li>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </ul>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            position: "absolute",
                            left: "0px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <h1
                            style={{
                              width: "100%",
                              textAlign: "center",
                              fontSize: "16px",
                              lineHeight: "61px",
                              color: "#eb5757",
                              fontWeight: "600",
                            }}
                          >
                            *Not Found
                          </h1>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              <div
                className="row justify-content-center"
                style={{
                  marginTop: "15px",
                  marginBottom: "15px",
                  height: "100%",
                }}
              >
                <div className="col-lg-12 col-12 text-left">
                  <div className="nk-block-between">
                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title page-title countd">
                        User Purchase History
                      </h3>
                      <div className="nk-block-des text-soft">
                        {/* <p>
                          You have total{' '}
                          {memberlist?.members?.length > 0 ? memberlist?.members?.length + ' team member' : 0 + ' team member'
                          }
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-12 col-12"
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    height: "100%",
                  }}
                >
                  {/* <div className="card-inner position-relative card-tools-toggle row">
                    <div className={toggleSearchBar ? 'card-search search-wrap active' : 'card-search search-wrap active'} data-search="search">
                      <div className="card-body">

                        <form className="search-content" onSubmit={(e) => { search_filter(e) }} style={{ display: "flex", justifyContent: "start" }}>
                          <input type="text" onChange={(e) => setSearch(e.target.value)} name='search' id='search' className="form-control border-transparent form-focus-none" placeholder="Search by user name/email..." style={{ paddingLeft: '0px', textTransform: 'none', width: "calc(100% - 200px)" }} />
                          <select className="btn btn-white btn-outline-light" style={{
                            width: '150px', height: '100%', outline: 'none',
                            border: 'solid rgb(82, 100, 132) 1px', backgroundColor: 'rgb(82, 100, 132)',
                            color: '#fff', margin: '0px', marginTop: '0px', marginLeft: '10px'
                          }} onChange={(e) => setSearchsub(e.target.value)}>
                            <option value=''>Filter By Status</option>
                            <option value={true}>Accepted</option>
                            <option value={false}>Pending</option>
                          </select>
                          <button className="search-submit btn btn-icon"><em className="icon ni ni-search"></em></button>
                        </form>
                      </div>
                    </div>
                  </div> */}

                  <div
                    className="row gy-10"
                    style={{
                      marginTop: "10px",
                      backgroundColor: "#fff",
                      height: "100%",
                    }}
                  >
                    <div
                      className="search-content"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <button
                        onClick={(e) => setViewMode("0")}
                        style={{
                          border: "solid #526484 1px",
                          background: viewMode === "0" ? "#526484" : "#fff",
                          color: viewMode === "0" ? "#fff" : "#526484",
                          margin: "5px",
                          borderRadius: "25px",
                          padding: "5px",
                          width: "100px",
                        }}
                      >
                        Cards
                      </button>
                      <button
                        onClick={(e) => setViewMode("1")}
                        style={{
                          border: "solid #526484 1px",
                          background: viewMode === "1" ? "#526484" : "#fff",
                          color: viewMode === "1" ? "#fff" : "#526484",
                          margin: "5px",
                          borderRadius: "25px",
                          padding: "5px",
                          width: "100px",
                        }}
                      >
                        Bundles
                      </button>
                    </div>
                    <div className="nk-tb-list nk-tb-ulist">
                      <div className="nk-tb-item nk-tb-head">
                        <div className="nk-tb-col nk-tb-col-check">
                          <span className="sub-text">S.No</span>
                        </div>
                        <div className="nk-tb-col">
                          <span className="sub-text">User Purchase Type</span>
                        </div>
                        <div className="nk-tb-col tb-col-md">
                          <span className="sub-text">Name of Product</span>
                        </div>
                        <div className="nk-tb-col tb-col-md">
                          <span className="sub-text">Amount Paid</span>
                        </div>

                        <div className="nk-tb-col tb-col-md">
                          <span className="sub-text">{"Purchased Date"}</span>
                        </div>
                        {/* <div className="nk-tb-col tb-col-mb">
                          <span className="sub-text">Status</span>
                        </div> */}
                        {/* <div className="nk-tb-col nk-tb-col-tools text-right">
                          <span className="sub-text">More</span>
                        </div> */}
                      </div>
                      {orderLoader ? (
                        <LoadingOverlay
                          className="loaderDiv"
                          style={{ left: "0px" }}
                        >
                          <Loader loading={orderLoader} />
                        </LoadingOverlay>
                      ) : null}
                      {userpurchasehistoryall?.length > 0 ? (
                        userpurchasehistoryall[0].type === "purchaseHistory" &&
                        viewMode === "1" ? (
                          userpurchasehistoryall[0].data
                            .sort(
                              (a, b) =>
                                new Date(b.createdAt) - new Date(a.createdAt)
                            )
                            .map((orderList, index) => (
                              <div className="nk-tb-item" key={index}>
                                <div className="nk-tb-col nk-tb-col-check">
                                  <div className="custom-control custom-control-sm custom-checkbox notext">
                                    <span className="tb-lead">
                                      {parseInt(index + 1)}
                                    </span>
                                  </div>
                                </div>
                                <div className="nk-tb-col">
                                  <div className="user-info">
                                    <span className="tb-lead">
                                      {orderList?.bundleId != "" &&
                                        orderList?.bundleId != undefined
                                        ? "Bundle"
                                        : "Plan"}
                                    </span>
                                  </div>
                                </div>

                                <div className="nk-tb-col tb-col-md">
                                  <span className="tb-lead">
                                    {orderList?.bundleId != "" &&
                                    orderList?.bundleId != undefined
                                      ? orderList?.bundleId?.name
                                      : orderList?.userPlanName}
                                  </span>
                                </div>
                                <div className="nk-tb-col tb-col-md">
                                  <span className="tb-lead">
                                    {orderList?.billingType
                                      ? orderList?.planPricing
                                      : orderList?.paymentId?.amount
                                    }
                                    {console.log(orderList)}
                                  </span>
                                </div>
                                <div className="nk-tb-col tb-col-md">
                                  <span className="tb-lead">
                                    {orderList?.createdAt
                                      ? dateFormatConverter(
                                        orderList?.createdAt
                                      )
                                      : "---"}
                                  </span>
                                </div>
                              </div>
                            ))
                        ) : (
                          userpurchasehistoryall[1]?.data[0]
                            .filter(
                              (orderList) => orderList.payment?.amount == "4.99"
                            )
                            .sort(
                              (a, b) =>
                                new Date(b.createdAt) - new Date(a.createdAt)
                            )
                            .map((orderList, index) => (
                              <div className="nk-tb-item" key={index}>
                                <div className="nk-tb-col nk-tb-col-check">
                                  <div className="custom-control custom-control-sm custom-checkbox notext">
                                    <span className="tb-lead">
                                      {parseInt(index + 1)}
                                    </span>
                                  </div>
                                </div>
                                <div className="nk-tb-col">
                                  <div className="user-info">
                                    <span className="tb-lead">Card</span>
                                  </div>
                                </div>
                                <div className="nk-tb-col tb-col-md">
                                  <span className="tb-lead">Card</span>
                                </div>
                                <div className="nk-tb-col tb-col-md">
                                  <span className="tb-lead">
                                    {orderList.payment.amount}
                                  </span>
                                </div>
                                <div className="nk-tb-col tb-col-md">
                                  <span className="tb-lead">
                                    {orderList?.createdAt
                                      ? dateFormatConverter(
                                        orderList?.createdAt
                                      )
                                      : "---"}
                                  </span>
                                </div>
                              </div>
                            ))
                        )
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            position: "absolute",
                            left: "0px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <h1
                            style={{
                              width: "100%",
                              textAlign: "center",
                              fontSize: "16px",
                              lineHeight: "61px",
                              color: "#eb5757",
                              fontWeight: "600",
                            }}
                          >
                            *Not Found
                          </h1>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TeamDetails />

      <Modal open={open} onClose={onCloseModal} closeOnOverlayClick={false}>
        <div className="container-fluid" style={{ width: "100%" }}>
          <div className="row justify-content-center">
            <div className="col-md-12 col-12">
              <h3 style={{ textAlign: "left" }}>Add Card For Showcase</h3>
            </div>
            <div className="col-md-12 col-12" style={{ marginTop: "15px" }}>
              <div className="row justify-content-center">
                <div className="col-lg-12 col-12">
                  <div className="card card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Heading*</div>
                          <input
                            type="text"
                            className="data-value ip-field"
                            value={title}
                            name="title"
                            placeholder="Heading"
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                          />
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Sub-Heading*</div>
                          <input
                            type="text"
                            className="data-value ip-field"
                            value={subTitle}
                            name="title"
                            placeholder="Sub Heading"
                            onChange={(e) => {
                              setSubTitle(e.target.value);
                            }}
                          />
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Description</div>
                          <textarea
                            type="text"
                            className="data-value ip-field"
                            value={description}
                            name="title"
                            placeholder="Add Description..."
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          ></textarea>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="my-3 text-center">
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-md btn-primary"
                        onClick={(e) => {
                          AddCardForShowCaseAPI(e);
                        }}
                      >
                        Add Card For Showcase
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default UserDetails;
