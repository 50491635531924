import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { deletedShowCaseApi } from "../Action/DeleteAction";
import { editShowCaseDetails } from "../Action/PutAction";
import { getShowCaseList } from "../Action/GetAction";
import { dateFormatConverter, matrixify1 } from "../Logic/Logic";
import { useToasts } from "react-toast-notifications";
import { toastComp } from "../Sections/Toast";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Modal } from "react-responsive-modal";
import { confirmAlert } from "react-confirm-alert";
import { format } from "fecha";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-overlay-loader/styles.css";
import "react-responsive-modal/styles.css";
import MyTable from "./datatable.js";

const ShowCasePage = (props) => {
  const { addToast } = useToasts();
  const [productList, setProductList] = useState([]);
  const [allData, setallData] = useState([]);

  const [countProduct, setCountProduct] = useState(0);
  const [paginationIndex, setPageIndex] = useState(0);
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [editId, setEditId] = useState(false);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");

  const countTotalProduct = async (array) => {
    var count = 0;
    for (let li = 0; li < array.length; li++) {
      count = count + array[li].length;
    }
    await setCountProduct(count);
  };
  const getListData = async () => {
    await getShowCaseList("showCase").then(async (response) => {
      if (response.success) {
        setLoader(false);
        if (response?.data?.length > 0) {
          onCloseModal();
          setallData(response?.data);
          const arrayList = await matrixify1(response.data, 10);
          await setProductList([...productList, arrayList]);
          await countTotalProduct(arrayList);
        }
      }
    });
  };
  const onCloseModal = (props) => {
    setOpen(false);
    setEditId(false);
    setTitle("");
    setSubTitle("");
    setDescription("");
  };

  const handlePageClick = async (e) => {
    const selectedPage = e.selected;
    setPageIndex(selectedPage);
  };
  const handleEditProduct = async (id) => {
    setLoader(true);
    if (title != "" && subTitle != "") {
      const data = {
        title: title,
        minTitle: subTitle,
        description: description,
        canvasId: "610097b0bb8105e0db5590ea",
      };
      const params = {
        cardId: id,
      };
      await editShowCaseDetails(data, params).then((response) => {
        if (response.success) {
          toastComp(addToast, "success", response?.message);
          onCloseModal();
          getListData();
        } else {
          toastComp(addToast, "error", response?.message);
        }
      });
    } else {
      toastComp(addToast, "error", "Please fill the required input field!");
    }
  };
  const editCategory = (data) => {
    setOpen(true);
    setEditId(data?._id);
    setTitle(data?.title);
    setSubTitle(data?.minTitle);
    setDescription(data?.desciption);
  };
  const deleteCategory = async (id, index) => {
    setLoader(true);
    await deletedShowCaseApi(id).then((response) => {
      if (response.success || response.status) {
        setLoader(false);
        productList[0]?.[paginationIndex].splice(index, 1);
        toastComp(addToast, "success", "Showcase Card deleted Successfully!");
      } else {
        setLoader(false);
        toastComp(addToast, "error", "Showcase Card id incorrect!");
      }
    });
  };
  const popup = (id, index) => {
    confirmAlert({
      title: "Confirm Action",
      message: "Are you sure you want to delete this card?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCategory(id, index),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  useEffect(async () => {
    await getListData();
  }, []);
  const columns = [
    {
      name: "Heading",
      selector: (row) => row.title,
      cell: (row) => row.title,
      sortable: true,
      width: "250px",
    },
    {
      name: "Sub-Heading",
      selector: (data) => data?.minTitle,
      sortable: true,
      width: "340px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      width: "220px",
    },
    {
      name: "Created At",
      selector: (row) => format(new Date(row?.createdAt), "Do MMM YYYY"),
      sortable: true,
      width: "240px",
    },
    {
      name: "More",
      cell: (data, index) => (
        <>
          <div className="nk-tb-col nk-tb-col-tools">
            <ul className="nk-tb-actions gx-1">
              <li>
                <div className="drodown">
                  <a
                    href="#"
                    className="dropdown-toggle btn btn-icon btn-trigger"
                    data-toggle="dropdown"
                  >
                    <em className="icon ni ni-more-h"></em>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <a onClick={() => editCategory(data)}>
                          <em className="icon ni ni-edit"></em>
                          <span>Edit Showcase Details</span>
                        </a>
                      </li>
                      <li>
                        <a onClick={() => popup(data._id, index)}>
                          <em className="icon ni ni-trash"></em>
                          <span>Delete</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      Showcase Card List
                    </h3>
                    <div className="nk-block-des text-soft">
                      <p>
                        You have total{" "}
                        {countProduct === 1
                          ? countProduct + " card"
                          : countProduct + " cards"}
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="nk-block">
                <div className="card card-bordered card-stretch">
                  <div className="card-inner-group">
                    <div className="card-inner p-0">
                      <div className="nk-tb-list nk-tb-ulist">
                        <div className="nk-tb-item nk-tb-head">
                          <div
                            className="nk-tb-col nk-tb-col-check"
                            style={{ width: "0px", padding: "10px" }}
                          ></div>
                          <div className="nk-tb-col">
                            <span className="sub-text">Heading</span>
                          </div>
                          <div className="nk-tb-col tb-col-mb">
                            <span className="sub-text">Sub-Heading</span>
                          </div>
                          <div className="nk-tb-col tb-col-mb">
                            <span className="sub-text">Description</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">Created At</span>
                          </div>
                          <div className="nk-tb-col nk-tb-col-tools text-right">
                            <span className="sub-text">More</span>
                          </div>
                        </div>
                        {loader ? (
                          <LoadingOverlay className="loaderDiv">
                            <Loader loading={loader} />
                          </LoadingOverlay>
                        ) : null}
                        {productList[0]?.[paginationIndex].length > 0 ? (
                          productList[0]?.[paginationIndex].map(
                            (data, index) => (
                              <div
                                className="nk-tb-item"
                                id={data._id}
                                key={index}
                              >
                                <div
                                  className="nk-tb-col nk-tb-col-check"
                                  style={{ width: "0px", padding: "10px" }}
                                ></div>
                                <div className="nk-tb-col">
                                  <div className="user-info">
                                    <span className="tb-lead">
                                      {data?.title}
                                      <span className="dot dot-success d-md-none ml-1"></span>
                                    </span>
                                  </div>
                                </div>
                                <div className="nk-tb-col tb-col-mb">
                                  <span className="tb-lead">
                                    {data?.minTitle}
                                  </span>
                                </div>
                                <div className="nk-tb-col tb-col-mb">
                                  <span className="tb-lead">
                                    {data?.description
                                      ? data?.description
                                      : "---"}
                                  </span>
                                </div>
                                <div className="nk-tb-col tb-col-md">
                                  <span className="tb-lead">
                                    {format(
                                      new Date(data?.createdAt),
                                      "Do MMM YYYY"
                                    )}
                                  </span>
                                </div>
                                <div className="nk-tb-col nk-tb-col-tools">
                                  <ul className="nk-tb-actions gx-1">
                                    <li>
                                      <div className="drodown">
                                        <a
                                          href="#"
                                          className="dropdown-toggle btn btn-icon btn-trigger"
                                          data-toggle="dropdown"
                                        >
                                          <em className="icon ni ni-more-h"></em>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                          <ul className="link-list-opt no-bdr">
                                            <li>
                                              <a
                                                onClick={() =>
                                                  editCategory(data)
                                                }
                                              >
                                                <em className="icon ni ni-edit"></em>
                                                <span>
                                                  Edit Showcase Details
                                                </span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  popup(data._id, index)
                                                }
                                              >
                                                <em className="icon ni ni-trash"></em>
                                                <span>Delete</span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              position: "absolute",
                              left: "0px",
                              backgroundColor: "#fff",
                            }}
                          >
                            <h1
                              style={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "16px",
                                lineHeight: "61px",
                                color: "#eb5757",
                                fontWeight: "600",
                              }}
                            >
                              *Not Found
                            </h1>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="card-inner">
                      <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={productList[0]?.length}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={10}
                        onPageChange={(e) => handlePageClick(e)}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              <MyTable columns={columns} tabledata={allData} />
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={onCloseModal} closeOnOverlayClick={false}>
        <div className="container-fluid" style={{ width: "100%" }}>
          <div className="row justify-content-center">
            <div className="col-md-12 col-12">
              <h3 style={{ textAlign: "left" }}>Edit Showcase Card Details</h3>
            </div>
            <div className="col-md-12 col-12" style={{ marginTop: "15px" }}>
              <div className="row justify-content-center">
                <div className="col-lg-12 col-12">
                  <ul className="data-list is-compact">
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Heading*</div>
                        <input
                          type="text"
                          className="data-value ip-field"
                          value={title}
                          name="title"
                          placeholder="Heading"
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Sub-Heading*</div>
                        <input
                          type="text"
                          className="data-value ip-field"
                          value={subTitle}
                          name="title"
                          placeholder="Sub Heading"
                          onChange={(e) => {
                            setSubTitle(e.target.value);
                          }}
                        />
                      </div>
                    </li>
                    <li className="data-item">
                      <div className="data-col">
                        <div className="data-label">Description</div>
                        <textarea
                          type="text"
                          className="data-value ip-field"
                          value={description}
                          name="title"
                          placeholder="Add Description..."
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                        ></textarea>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="my-3 text-center">
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-md btn-primary"
                        onClick={() => {
                          handleEditProduct(editId);
                        }}
                      >
                        Edit Showcase Card
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ShowCasePage;
