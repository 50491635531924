import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import {
  getProductList,
  getSubscribersList,
  getSubscribersListSearch,
} from "../Action/GetAction";
import { dateFormatConverter, matrixify1 } from "../Logic/Logic";
import { useToasts } from "react-toast-notifications";
import { toastComp } from "../Sections/Toast";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { confirmAlert } from "react-confirm-alert";
import { format } from "fecha";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-overlay-loader/styles.css";
import { deleteMethod } from "../Utils/ApiServices";
import { ExportToExcel } from "../tools/ExportToExcel";
import MyTable from "./datatable.js";

const Newsletter = (props) => {
  const { addToast } = useToasts();
  const [toggleSearchBar, setToggleSearchBar] = useState(true);
  // const [productList, setProductList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [allData, setallData] = useState([]);
  const [countProduct, setCountProduct] = useState(0);
  const [paginationIndex, setPageIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [searchPage, setSearchPage] = useState();

  // const countTotalProduct = async (array) => {
  //     var count = 0;
  //     for (let li = 0; li < array.length; li++) {
  //         count = count + array[li].length
  //     }
  //    setCountProduct(count);
  // }

  // const getListData = async (isNote, message, value) => {
  //     await getProductList('newsletter/subscribers?search='+value).then(async response => {
  //         if (response.success) {
  //             let newData = (response.data)?.map((items, index)=>({
  //                 S_No: index+1,
  //                 Email: items?.email ? items?.email : '',
  //                 CollectFrom : items?.collectFrom ? items?.collectFrom : "NA",
  //                 Date : items?.updatedAt ? format(new Date((items?.createdAt)), 'Do MMM YYYY') : ""
  //             }))
  //             setLoader(false);
  //             setExcelData(newData)
  //             const arrayList = await matrixify1(response.data, 10);
  //             if(arrayList.length === 0){
  //                 setProductList([]);
  //             }else{
  //                 setProductList([arrayList]);
  //                 countTotalProduct(arrayList);
  //             }

  //         }else{
  //             setLoader(false);
  //             alert("Something wrong",setProductList);
  //         }
  //     })
  // }

  // const handlePageClick = async (e) => {
  //     const selectedPage = e.selected;
  //     setPageIndex(selectedPage);
  // };

  //  const handleSubmitSearch=async(e)=>{
  //     e.preventDefault();
  //     const {search} = e.target.elements;
  //     getListData(false, "", search.value.toLowerCase());
  // }

  const getApiCall = async (page, limit) => {
    setLoader(true);
    await getSubscribersList(page, limit, false).then((response) => {
      if (response.success) {
        setLoader(false);
        setCountProduct(response.total);
        setDataList(response.data);
        setPageIndex(Math.ceil(response.total / limit));
      } else {
        setLoader(false);
      }
    });
  };

  const handlePageClick = async (e) => {
    const selectedPage = parseInt(e.selected + 1);
    setLoader(true);
    if (search) {
      handleSerach(selectedPage, 10, search);
    } else {
      await getApiCall(selectedPage, 10);
    }
  };

  const handleSubmitSearch = async (e) => {
    e.preventDefault();
    const { search } = e.target.elements;
    setSearchPage(0);
    handleSerach(1, 10, search.value);
  };

  const handleSerach = async (page, limit, value) => {
    setLoader(true);
    try {
      const response = await getSubscribersListSearch(
        page,
        limit,
        value.toLowerCase()
      );
      if (response && response.success) {
        setLoader(false);
        setDataList(response.data);
        setCountProduct(response.total);
        setPageIndex(Math.ceil(response.total / limit));
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const dataAllUser = async (page, lim) => {
    setExcelData([]);
    await getSubscribersList(page, lim, true).then(async (response) => {
      {
        if (response.success) {
          setallData(response?.data);
          let newData = response.data?.map((items, index) => ({
            S_No: index + 1,
            Email: items?.email ? items?.email : "---",
            Subscribe_From: items?.collectFrom ? items?.collectFrom : "---",
            Date: items?.createdAt
              ? format(new Date(items?.createdAt), "Do MMM YYYY")
              : "",
          }));

          setExcelData(newData);
        }
      }
    });
  };

  var divisor = (countProduct / 10) >> 0;
  var remainder = countProduct % 10;

  function respondMail(userMail) {
    // console.log(userMail)
    // var link = 'mailto:hello@domain.com?subject=Message from '+userMail+'&body='+userMail;
    // window.location.href = link;
  }
  async function deleteMail(mailId, index) {
    setLoader(true);
    await deleteMethod("newsletter/subscribers/" + mailId + "/delete").then(
      (response) => {
        if (response.success) {
          toastComp(addToast, "success", response.message);
          setProductList([]);
          getListData(false, "", search);
        } else {
          toastComp(addToast, "error", response.message);
          setLoader(false);
        }
      }
    );
    // var link = 'mailto:hello@domain.com?subject=Message from '+userMail+'&body='+userMail;
    // window.location.href = link;
  }

  const popup = (id, index) => {
    confirmAlert({
      title: "Confirm Action",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteMail(id, index),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  // var divisor = (countProduct/10)>> 0;
  // var remainder = countProduct%10;

  useEffect(async () => {
    // await getListData(false, '', search);
    getApiCall(1, 10);
    dataAllUser(1, 10);
  }, []);
  const columns = [
    {
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => row.email,
      sortable: true,
      width: "500px",
    },
    {
      name: "Subscribe From",
      selector: (row) => (row.collectFrom ? row.collectFrom : "---"),
      sortable: true,
      width: "300px",
    },
    {
      name: "Date",
      selector: (row) => format(new Date(row?.createdAt), "Do MMM YYYY"),
      sortable: true,
      width: "220px",
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.createdAt);
        const dateB = new Date(rowB.createdAt);
        return dateA - dateB;
      },
    },
    {
      name: "More",
      width: "70px",
      cell: (row, index) => (
        <>
          <div className="nk-tb-col nk-tb-col-tools">
            <ul className="nk-tb-actions gx-1">
              <li>
                <div className="drodown">
                  <a
                    href="#"
                    className="dropdown-toggle btn btn-icon btn-trigger"
                    data-toggle="dropdown"
                  >
                    <em className="icon ni ni-more-h"></em>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <ul className="link-list-opt no-bdr">
                      <li>
                        <a onClick={() => popup(row._id, index)}>
                          <em className="icon ni ni-trash"></em>
                          <span>Delete</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Subscriber</h3>
                    <div className="nk-block-des text-soft">
                      <p>
                        You have total{" "}
                        {countProduct
                          ? countProduct + " subscriber"
                          : 0 + " subscribers"}
                        .
                      </p>
                    </div>
                  </div>
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <a
                      href="#"
                      className="btn btn-icon btn-trigger toggle-expand mr-n1"
                      data-target="pageMenu"
                    >
                      <em className="icon ni ni-menu-alt-r"></em>
                    </a>
                    <div
                      className="toggle-expand-content"
                      data-content="pageMenu"
                    >
                      <ul className="nk-block-tools g-3">
                        <li>
                          {excelData?.length ? (
                            <ExportToExcel
                              apiData={excelData}
                              fileName={
                                "greetpool-subscribers-list-page" + Date.now()
                              }
                            />
                          ) : null}
                        </li>
                        {/* <li>
                                                    <ExportToExcel apiData={excelData} fileName={"greetpool-subscribers-list-page" + Date.now()} />     
                                                </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="nk-block">
                <div className="card card-bordered card-stretch">
                  <div className="card-inner-group">
                    <div className="card-inner position-relative card-tools-toggle">
                      <div
                        className={
                          toggleSearchBar
                            ? "card-search search-wrap active"
                            : "card-search search-wrap active"
                        }
                        data-search="search"
                      >
                        <div className="card-body">
                          <form
                            className="search-content"
                            onSubmit={(e) => {
                              handleSubmitSearch(e);
                            }}
                          >
                            <input
                              type="text"
                              onChange={(e) => setSearch(e.target.value)}
                              name="search"
                              id="search"
                              className="form-control border-transparent form-focus-none"
                              placeholder="Search by user name/email..."
                              style={{
                                paddingLeft: "0px",
                                textTransform: "none",
                              }}
                            />
                            <button className="search-submit btn btn-icon">
                              <em className="icon ni ni-search"></em>
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner p-0">
                      <div className="nk-tb-list nk-tb-ulist">
                        <div className="nk-tb-item nk-tb-head">
                          <div
                            className="nk-tb-col nk-tb-col-check"
                            style={{ width: "0px", padding: "10px" }}
                          ></div>
                          <div className="nk-tb-col tb-col-mb">
                            <span className="sub-text">Email</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">Subscribe From</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className="sub-text">Date</span>
                          </div>
                          <div className="nk-tb-col nk-tb-col-tools text-right">
                            <span className="sub-text">More</span>
                          </div>
                        </div>
                        {loader ? (
                          <LoadingOverlay className="loaderDiv">
                            <Loader loading={loader} />
                          </LoadingOverlay>
                        ) : null}
                        {dataList?.length > 0 ? (
                          dataList?.map((data, index) => (
                            <div
                              className="nk-tb-item"
                              id={data._id}
                              key={index}
                            >
                              <div
                                className="nk-tb-col nk-tb-col-check"
                                style={{ width: "0px", padding: "10px" }}
                              ></div>
                              <div className="nk-tb-col tb-col-mb">
                                <span className="tb-lead">{data.email}</span>
                              </div>
                              <div className="nk-tb-col tb-col-md">
                                <span
                                  className="tb-lead"
                                  style={{
                                    textAlign: "justify",
                                    width: "250px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {data.collectFrom ? data.collectFrom : "---"}
                                </span>
                              </div>
                              <div className="nk-tb-col tb-col-md">
                                <span className="tb-lead">
                                  {data.createdAt
                                    ? format(
                                        new Date(data?.createdAt),
                                        "Do MMM YYYY, HH:mm A"
                                      )
                                    : "---"}
                                </span>
                              </div>
                              <div className="nk-tb-col nk-tb-col-tools">
                                <ul className="nk-tb-actions gx-1">
                                  <li>
                                    <div className="drodown">
                                      <a
                                        href="#"
                                        className="dropdown-toggle btn btn-icon btn-trigger"
                                        data-toggle="dropdown"
                                      >
                                        <em className="icon ni ni-more-h"></em>
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <ul className="link-list-opt no-bdr">
                                          <li>
                                            <a
                                              onClick={() =>
                                                popup(data._id, index)
                                              }
                                            >
                                              <em className="icon ni ni-trash"></em>
                                              <span>Delete</span>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          ))
                        ) : (
                          <LoadingOverlay className="loaderDiv">
                            <Loader loading={loader} />
                            <h1
                              style={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "16px",
                                lineHeight: "61px",
                                color: "#eb5757",
                                fontWeight: "600",
                              }}
                            >
                              *Not Found
                            </h1>
                          </LoadingOverlay>
                        )}
                      </div>
                    </div>

                    {paginationIndex ? (
                      <div className="card-inner">
                        <ReactPaginate
                          forcePage={searchPage}
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={remainder >= 1 ? divisor + 1 : divisor}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={(e) => handlePageClick(e)}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div> */}
              <MyTable columns={columns} tabledata={allData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Newsletter;
